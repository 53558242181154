import { configureStore } from "@reduxjs/toolkit";

import themeReducer from "./slices/themeSlice";
import userReducer from "./slices/userSlice";
import productSlice from "./slices/productSlice";
import wishlistSlice from "./slices/wishlistSlice";
import cartSlice from "./slices/cartSlice";
import shippingSlice from "./slices/shippingSlice";
import categorySlice from "./slices/categorySlice";
import filterSlice from "./slices/filterSlice";
import reviewSlice from "./slices/reviewSlice";
import locationSlice from "./slices/locationSlice";
import orderSlice from "./slices/orderSlice";
import paymentSlice from "./slices/paymentSlice";

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    user: userReducer,
    category: categorySlice,
    product: productSlice,
    filters: filterSlice,
    review: reviewSlice,
    wishlist: wishlistSlice,
    cart: cartSlice,
    orders: orderSlice,
    shipping: shippingSlice,
    location: locationSlice,
    payment: paymentSlice,
  },
});
