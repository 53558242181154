import { getToken } from "../config/constants";

export const isUserAuthenticated = (user) => getToken() && user;

export const isUserEmailVerified = (user) => {
  return user?.verifications?.find(
    (verification) =>
      verification?.method === "email" && verification?.is_verified
  );
};

export const isUserPhoneVerified = (user) => {
  return user?.verifications?.find(
    (verification) => verification?.method === "sms" // temporary
    // verification?.method === "sms" && verification?.is_verified // correct condition
  );
};
