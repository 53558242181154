// import React, { memo } from "react";

// const SelectInput = (props) => {
//   const {
//     label = "",
//     name = "",
//     required = false,
//     className = "",
//     value = "",
//     onChange = () => {},
//     options = [],
//     ...rest
//   } = props;

//   return (
//     <div className={`select-container ${className}`}>
//       <select
//         {...rest}
//         name={name}
//         value={value}
//         onChange={(e) => {
//           onChange(e);
//         }}
//         className={`styled-select ${value ? "filled" : ""}`}
//       >
//         <option value="" hidden disabled>

//         </option>
//         {options.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//       <label htmlFor={name} className={`select-label-1 ${value && "active"}`}>
//         {label} {required ? <span className="required">*</span> : ""}
//       </label>
//     </div>
//   );
// };

// export default memo(SelectInput);
import React, { memo } from "react";
import { Select, Spin } from "antd";
import Loader from "../Loader";

const { Option } = Select;

const SelectInput = (props) => {
  const {
    label = "",
    name = "",
    required = false,
    className = "",
    value = "",
    disabled = false,
    onChange = () => {},
    loading = false,
    options = [],
    placeholder = "Select an option",
    ...rest
  } = props;

  return (
    <div
      className={`select-container ${
        disabled && "disabled-select"
      } ${className}`}
    >
      <Select
        {...rest}
        id={name}
        value={value || undefined} // Ensure placeholder shows when no value
        onChange={(val) => onChange(val)}
        disabled={disabled}
        popupClassName="common-dropdown"
        notFoundContent={
          loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Spin />
            </div>
          ) : null
        }
        className={`styled-select ${value ? "filled" : ""}`}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      <label htmlFor={name} className={`select-label-1 ${value && "active"}`}>
        {label} {required ? <span className="required">*</span> : ""}
      </label>
    </div>
  );
};

export default memo(SelectInput);
