import React from "react";
// import { SiHiveBlockchain } from "react-icons/si";
import Image from '../../assets/images/3d-select-face.png'
function ServiceCard({ title, discription }) {
  return (
    <div className="service-card">
      <img src={Image} />
      {/* <SiHiveBlockchain className="icon" /> */}
      <h1 className="title">{title}</h1>
      <p className="discription">
        {discription}
      </p>
    </div>
  );
}

export default ServiceCard;
