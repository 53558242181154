import { notification } from "antd";
import { base_url, privateAPI } from "../../config/constants";
import {
  setLoading,
  setProfile,
  setProfileLoading,
  setUser,
} from "../slices/userSlice";
import { store } from "../index";

const handleError = (msg) => {
  notification.error({
    message: msg || "Server Error",
  });
};

export const register = async (payload) => {
  try {
    const response = await privateAPI.post("/auth/sign-up", payload);
    if (response) {
      await getUser();
      notification.success({
        message: "Account created successfully",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const login = async (payload) => {
  try {
    const response = await privateAPI.post("/auth/log-in", payload);
    if (response) {
      await getUser();
      notification.success({
        message: response?.data?.message || "Login successfully",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const sendVerificationCode = async (payload) => {
  try {
    const response = await privateAPI.post(
      "/auth/verification/send-verification-code",
      payload
    );
    if (response) {
      notification.success({
        message:
          response?.data?.message || "verification code sent successfully",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const verifyCode = async (payload) => {
  try {
    const response = await privateAPI.post(
      "/auth/verification/verify-code",
      payload
    );
    if (response) {
      notification.success({
        message: response?.data?.message || "verify code successfully",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const businessVerification = async (payload) => {
  try {
    const response = await privateAPI.post("/user/business/verify", payload);
    if (response) {
      notification.success({
        message:
          response?.data?.detail || "business verification  successfully",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const forgotPassword = async (payload) => {
  try {
    const response = await privateAPI.post("/auth/forgot-password", payload);
    if (response) {
      notification.success({
        message:
          response?.data?.message || "verification code sent successfully",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const resetPassword = async (payload) => {
  try {
    const response = await privateAPI.post("/auth/reset-password", payload);
    if (response) {
      notification.success({
        message: response?.data?.message || "Password reset successfully",
      });
    }
    return response?.data;
  } catch (error) {
    console.log(error);
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const changePassword = async (payload) => {
  try {
    const response = await privateAPI.post("/user/reset-password", payload);
    if (response) {
      notification.success({
        message: "Password reset successfully",
      });
    }
    return response?.data;
  } catch (error) {
    console.log(error);
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const refreshToken = async () => {
  try {
    const response = await privateAPI.post("/auth/refresh-token");
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getActiveSessions = async () => {
  try {
    const response = await privateAPI.get("/auth/active-sessions");
    if (response) {
      localStorage.setItem("token", response?.data?.active_sessions?.access_token);
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/auth/me");
    if (response) {
      store.dispatch(setUser(response?.data));
    }
    return response?.data;
  } catch (error) {
    localStorage.removeItem("token");
    console.log(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const logout = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/auth/log-out");
    if (response) {
      localStorage.removeItem("token");
      store.dispatch(setUser(null));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getUserProfile = async () => {
  try {
    store.dispatch(setProfileLoading(true));
    const response = await privateAPI.get("/user/profile");
    if (response) {
      store.dispatch(setProfile(response?.data));
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  } finally {
    store.dispatch(setProfileLoading(false));
  }
};

export const updateUserProfile = async (payload) => {
  try {
    const response = await privateAPI.put("/user/profile", payload);
    if (response) {
      await getUserProfile();
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const uploadUserProfileImg = async (formData) => {
  try {
    store.dispatch(setProfileLoading(true));
    const response = await privateAPI.post(
      "/user/profile-picture/upload-url",
      formData
    );
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  } finally {
    store.dispatch(setProfileLoading(false));
  }
};

export const getNotificationPreferences = async () => {
  try {
    const response = await privateAPI.get("/user/notification-preferences");
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateNotificationPreferences = async (payload) => {
  try {
    const response = await privateAPI.put(
      "/user/notification-preferences",
      payload
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAccount = async () => {
  try {
    const response = await privateAPI.delete("/user/account");
    if (response) {
      await logout();
      localStorage.removeItem("token");
      store.dispatch(setUser(null));
      notification.success({
        message: response?.data?.message || "Account deleted successfully",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const googleLogin = () => {
  localStorage.setItem("isSigninWithGoogle", true);
  window.open(`${base_url}/auth/google_auth/login`, "_blank");
};
