import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  wishlist: [],
};

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setWishlist: (state, action) => {
      state.wishlist = action.payload;
    },
  },
});

export const { setLoading, setWishlist } = wishlistSlice.actions;

export default wishlistSlice.reducer;
