import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import route from "../../../config/route";
import { useSelector } from "react-redux";

const ThankYou = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <div className="thankYouContainer">
      <h2 className="authHeading">Thank You {user?.name}</h2>
      <p className="authSubText">
        Please wait for our admin to contact you Lorem Ipsum dolor amet sit.
        Lorem Ipsum dolor amet sit. Lorem Ipsum dolor amet sit {" "}
      </p>
      <Button
        type="primary"
        className="pi55"
        onClick={() => navigate(route.exploreHome)}
      >
        Proceed to Dashboard
      </Button>
    </div>
  );
};

export default ThankYou;
