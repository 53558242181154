import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  reviews: [],
  count: 0,
};

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
    setCount: (state,action)=>{
      state.count = action.payload
    }
  },
});

export const { setReviews, setLoading, setCount } = reviewSlice.actions;

export default reviewSlice.reducer;