import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  cartData: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCartData: (state, action) => {
      state.cartData = action.payload;
    },
  },
});

export const { setLoading, setCartData } = cartSlice.actions;

export default cartSlice.reducer;
