import React, { useState } from "react";
import { Button, Form } from "antd";
import Input from "../Input";
import { useNavigate } from "react-router";
import route from "../../config/route";
import { resetPassword } from "../../store/services/user";

const NewPassword = ({ create, reset }) => {
  const [form] = Form.useForm();
  const [reRender, setReRender] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthText, setPasswordStrengthText] = useState("");
  const [loading, setLoading] = useState(false);
  const getVerificationInfo = localStorage.getItem("verificationInfo");
  const verificationInfo =
    getVerificationInfo && JSON.parse(getVerificationInfo);

  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (reset) {
        const payload = {
          code: verificationInfo?.code,
          contact_method: verificationInfo?.contactType,
          contact_value: verificationInfo?.contactValue,
          new_password: values?.newPassword,
          confirm_password: values?.confirmPassword,
        };
        const response = await resetPassword(payload);
        if (response) {
          localStorage.removeItem("verificationInfo");
          navigate(route.signin);
        }
      }
      if (create) {
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    const strength = calculatePasswordStrength(password);
    setPasswordStrength(strength);
    setPasswordStrengthText(getStrengthText(strength));
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 6) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    return strength;
  };

  const getStrengthText = (strength) => {
    switch (strength) {
      case 0:
        return "";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "";
    }
  };

  const title = create
    ? "Create New Password"
    : reset
    ? "Reset your password"
    : "";
  const btnText = create ? "Continue" : reset ? "Reset Password" : "";

  return (
    <div className="createNewPasswordContainer">
      <h2 className="authHeading">{title}</h2>
      <p className="authDescription">Would you like us to send you ...</p>

      <Form
        form={form}
        name="createNewPassword"
        style={{
          width: "100%",
        }}
        onFinish={onFinish}
        autoComplete="off"
        className="createNewPasswordForm"
      >
        <Form.Item
          label={null}
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Input
            label="New Password"
            type="password"
            onChange={(e) => {
              setReRender(e.target.value);
              handlePasswordChange(e);
            }}
          />
        </Form.Item>

        <Form.Item
          label={null}
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Required!",
            },
            {
              validator: (_, value) =>
                value && value !== form.getFieldValue("newPassword")
                  ? Promise.reject(new Error("Passwords do not match!"))
                  : Promise.resolve(),
            },
          ]}
        >
          <Input label="Confirm Password" type="password" />
        </Form.Item>

        {reRender !== "" && (
          <div className="passwordStrengthBox">
            <div className="passwordStrength">
              {[
                { active: passwordStrength >= 1 },
                { active: passwordStrength >= 2 },
                { active: passwordStrength >= 3 },
                { active: passwordStrength >= 4 },
              ].map((v, i) => {
                return (
                  <div key={i} className={`${v?.active ? "active" : ""}`}></div>
                );
              })}
            </div>
            <div className="textBox">
              <p>{passwordStrengthText}</p>
            </div>
          </div>
        )}

        <Form.Item label={null}>
          <Button
            className="pi55"
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {btnText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewPassword;
