import React, { useEffect, useState } from "react";
import {
  Button,
  CardModal,
  Footer,
  Header,
  Loader,
  ModalWrapper,
  PackingMethod,
  ProductCard,
  ProductDescription,
  ProductImages,
  ProductKeyAttributes,
  ReviewsList,
  SimpleHeader,
} from "../../../../components";
import arrowRightIcon from "../../../../assets/images/arrowRightIcon.svg";
import { HomeOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Row,
  Divider,
  Col,
  Rate,
  Dropdown,
  Select,
  Drawer,
  Switch,
  Spin,
} from "antd";
import image from "../../../../assets/images/tempProduct01.png";
import image2 from "../../../../assets/images/tempProduct02.png";
import image3 from "../../../../assets/images/tempProduct03.png";
import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons";
import { AiOutlineSwap } from "react-icons/ai";
import { useNavigate, useParams } from "react-router";
import {
  getProducts,
  getSingleProduct,
} from "../../../../store/services/product";
import { useSelector } from "react-redux";
import { getProductReviews } from "../../../../store/services/review";
import route from "../../../../config/route";
import {
  addToWishlist,
  getWishlist,
  removeToWishlist,
} from "../../../../store/services/wishlist";
import { addToCart } from "../../../../store/services/cart";

const ProductDetails = () => {
  let { id } = useParams();
  const { singleProduct, loading } = useSelector((state) => state.product);
  const { reviews, count } = useSelector((state) => state.review);
  const [mainImage, setMainImage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [currentReviewPage, setCurrentReviewPage] = useState(1);
  const [reviewSortBy, setReviewSortBy] = useState('newest');
  const { Option } = Select;
  const navigate = useNavigate();
  const [favouriteLoading, setFavouriteLoading] = useState(false);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { products } = useSelector((state) => state.product);
  const [isFavorite, setIsFavorite] = useState(false);
  const [cartBtnLoading, setCartBtnLoading] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [activeBtn, setActiveBtn] = useState("keyAttribute");

  const handleSortChange = (value) => {
    setReviewSortBy(value);
  };
  const onChange = (page) => {
    setCurrentReviewPage(page);
  };
  // const thumbnails = [image2, image3, image2, image];
  // const productName = "Snail Product Name";
  // const productSold = "100k+";
  // const categories = "Shell";
  // const weightVariants = [
  //   "250g pack of 3 or 4",
  //   "400g pack of 5",
  //   "800g pack of 10",
  //   "1kg pack of 10-13",
  //   "1kg pack of 10-13",
  // ];
  // const attributes = [
  //   { key: "Lining Material", value: "Mesh" },
  //   { key: "Place of Origin", value: "Henan, China" },
  //   { key: "Model Number", value: "FH-524" },
  //   { key: "Season", value: "Summer, Spring, Autumn" },
  //   { key: "Style", value: "Running shoes, Loafers, Sporty" },
  //   { key: "Toe Style", value: "Round Toe, Closed Toe" },
  //   { key: "Logo position", value: "A Pair" },
  //   { key: "Logo Craft", value: "3D Printing" },
  // ];
  // const attributes2 = [
  //   { key: "Lining Material", value: "Mesh" },
  //   { key: "Place of Origin", value: "Henan, China" },
  //   { key: "Model Number", value: "FH-524" },
  // ];

  const incrementQuantity = () => {
    if (selectedVariant?.stock_quantity >= quantity) {
      setQuantity((prev) => prev + 1);
    }
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const handleLoginRegister = () => {
    navigate(route.signin);
  };

  const handleStartOrder = () => {
    console.log("Start Order clicked!");
    setIsModalOpen(true);
    // Implement start order logic
  };

  const handleSchedule = () => {
    console.log("Schedule clicked!");
    // Implement schedule order logic
  };

  const handleAddToCart = async () => {
    try {
      setCartBtnLoading(true);
      const payload = {
        product_id: singleProduct?.product_id,
        pricing_id: selectedVariant?.pricing_id,
        quantity,
      };
      const response = await addToCart(payload);
      if (response) {
        setQuantity(1);
      }
    } catch (error) {
    } finally {
      setCartBtnLoading(false);
    }
  };

  const handleSwitchChange = (checked) => console.log("Switch state:", checked);

  // Sample Reviews Data
  // const reviews = [
  //   {
  //     user: "P****a",
  //     country: "🇨🇩",
  //     date: "Nov 30, 2024",
  //     rating: 5,
  //     content: ":good",
  //     photos: [image, image2, image3],
  //   },
  //   {
  //     user: "S****e",
  //     country: "🇨🇲",
  //     date: "Nov 23, 2024",
  //     rating: 5,
  //     content: "Thank you very much for your customer service it's very nice",
  //     photos: [],
  //   },
  //   {
  //     user: "S****e",
  //     country: "🇨🇲",
  //     date: "Nov 23, 2024",
  //     rating: 5,
  //     content:
  //       "Thank you very much for your customer service it's very nice Thank you very much for your customer service it's very nice Thank you very much for your customer service it's very nice",
  //     photos: [image, image2, image3],
  //   },
  //   {
  //     user: "S****e",
  //     country: "🇨🇲",
  //     date: "Nov 23, 2024",
  //     rating: 5,
  //     content: "Thank you very much for your customer service it's very nice",
  //     photos: [],
  //   },
  // ];

  const addToFavourite = async () => {
    try {
      setFavouriteLoading(true);
      await addToWishlist(singleProduct?.product_id);
    } catch (error) {
      console.log(error);
    } finally {
      setFavouriteLoading(false);
    }
  };

  const removeToFavourite = async () => {
    try {
      setFavouriteLoading(true);
      await removeToWishlist(singleProduct?.product_id);
    } catch (error) {
      console.log(error);
    } finally {
      setFavouriteLoading(false);
    }
  };

  const handleFavouriteToggle = () => {
    if (isFavorite) {
      removeToFavourite();
    } else {
      addToFavourite();
    }
  };

  useEffect(() => {
    getSingleProduct(id);
  }, []);

  useEffect(() => {
    if (singleProduct?.product_id) {
      const payload = {
        product_id: id,
        page: currentReviewPage,
        page_size: 10,
        sort_by: reviewSortBy,
        with_images: filter === "all" || filter === "withImages" ? true : false,
      };
      const validPayload = Object.fromEntries(
        Object.entries(payload).filter(([_, value]) => value)
      );
      getProductReviews(validPayload);
    }
  }, [singleProduct, currentReviewPage, reviewSortBy, filter]);

  useEffect(() => {
    if (singleProduct?.product_id) {
      getWishlist();
      getProducts({
        category_id: singleProduct?.parent_category?.category_id,
      });
      setSelectedVariant(singleProduct?.pricing[0]);
      setMainImage(
        singleProduct?.images?.find(({ is_primary }) => is_primary)
          ?.image_url || singleProduct?.images[0]?.image_url
      );
    }
  }, [singleProduct]);

  useEffect(() => {
    if (
      wishlist?.length > 0 &&
      wishlist.some(
        (wishlistProduct) =>
          wishlistProduct?.product_id === singleProduct?.product_id
      )
    ) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [wishlist, singleProduct]);

  useEffect(() => {
    if (products?.length > 0) {
      setRelatedProducts(
        products?.filter(
          (product) => product?.product_id !== singleProduct?.product_id
        )
      );
    }
  }, [products]);

  // Filters
  const menu = (
    <div>
      <p style={{ margin: "0", padding: "8px" }}>Most relevant</p>
      <p style={{ margin: "0", padding: "8px" }}>Newest</p>
      <p style={{ margin: "0", padding: "8px" }}>Highest Rating</p>
    </div>
  );
  return (
    <div className="product-detail-container">
      <Header />
      {loading ? (
        <Loader />
      ) : singleProduct?.product_id ? (
        <>
          <div className="product-detail-main">
            {/* <Breadcrumb
              separator={<RightOutlined className="breadcrumb-seperator" />}
              items={[
                {
                  title: <HomeOutlined size={18} className="cursor-pointer" />,
                  onClick: () => navigate(route.exploreHome),
                },
                {
                  title: (
                    <>
                      <span className="cursor-pointer">
                        {singleProduct?.category?.name}
                      </span>
                    </>
                  ),
                },
                {
                  title: singleProduct?.name,
                },
              ]}
            /> */}
            <Breadcrumb className="back-breadcrumb">
              <Breadcrumb.Item
                className="breadcrumb-item"
                onClick={() => navigate(route.exploreHome)}
              >
                <img src={arrowRightIcon} /> <span className="text">Back</span>
              </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ paddingTop: "60px" }}>
              <Row gutter={[20, 20]}>
                {/* ************************** left ******************************** */}
                <Col xs={24} md={12}>
                  <ProductImages
                    mainImage={mainImage}
                    thumbnails={
                      singleProduct?.images?.map(
                        (imageData) => imageData?.image_url || ""
                      ) || []
                    }
                    handleFavouriteToggle={handleFavouriteToggle}
                    setMainImage={setMainImage}
                  />

                  {/* <div className="key_attributes">
                    <Divider />
                    <div className="key_attributes_border">
                      <ProductKeyAttributes
                        title="Key attributes"
                        attributes={Object.entries(
                          singleProduct?.key_attributes
                        )?.map(([key, value]) => {
                          return { key, value };
                        })}
                      />
                      <ProductKeyAttributes
                        title="Packing and Delivery"
                        attributes={Object.entries(
                          singleProduct?.packaging_details
                        )?.map(([key, value]) => {
                          return { key, value };
                        })}
                      />
                    </div>
                  </div> */}
                </Col>

                {/* ****************************** right *************************** */}
                <Col xs={24} md={12} className="responsive-padding">
                  <ProductDescription
                    quantity={quantity}
                    incrementQuantity={incrementQuantity}
                    decrementQuantity={decrementQuantity}
                    onLoginRegister={handleLoginRegister}
                    onStartOrder={handleStartOrder}
                    onSchedule={handleSchedule}
                    onAddToCart={handleAddToCart}
                    selectedVariant={selectedVariant}
                    setSelectedVariant={setSelectedVariant}
                    cartBtnLoading={cartBtnLoading}
                    product={singleProduct}
                    isFavorite={isFavorite}
                    favBtnLoading={favouriteLoading}
                    handleFavouriteToggle={handleFavouriteToggle}
                  />
                  <div className="delivery_method">
                    <Divider />
                    <div className="delivery_method_main">
                      <h2>Delivery</h2>
                      <p>
                        All orders placed before 12 noon Monday to Wednesday
                        will be sent out the same day for next-day delivery.
                      </p>
                    </div>
                    <div className="delivery_method_main">
                      <h2>Secure Payments</h2>
                      <p>
                        Every payment you make on Frostflow is secured with SSL
                        encryption and PCI DSS data protection protocols
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* RATTING REVIRWS */}
            </div>
          </div>
          <div className="product_ratting">
            <div className="tabs">
              <Button
                className={activeBtn === "keyAttribute" ? "active-btn" : ""}
                onClick={() => {
                  setActiveBtn("keyAttribute");
                }}
              >
                key attribute
              </Button>
              <Button
                className={activeBtn === "ratingReviews" ? "active-btn" : ""}
                onClick={() => {
                  setActiveBtn("ratingReviews");
                }}
              >
                rating & reviews ({count})
              </Button>
            </div>
            {/* <Divider /> */}
            {/* Overall Rating */}
            {activeBtn === "keyAttribute" && (
              <div className="key_attributes">
                {/* <Divider /> */}
                <div className="key_attributes_border">
                  <ProductKeyAttributes
                    title="Key attributes"
                    attributes={Object.entries(
                      singleProduct?.key_attributes
                    )?.map(([key, value]) => {
                      return { key, value };
                    })}
                  />
                  <ProductKeyAttributes
                    title="Packing and Delivery"
                    attributes={Object.entries(
                      singleProduct?.packaging_details
                    )?.map(([key, value]) => {
                      return { key, value };
                    })}
                  />
                </div>
              </div>
            )}
            {activeBtn === "ratingReviews" && (
              <>
                <h3>Ratings & Reviews ({count})</h3>

                {/* Rating Section */}
                <div className="rating_section">
                  <h1>{count}</h1>
                  <div className="rating_reviews">
                    <div>
                      <Rate
                        allowHalf
                        defaultValue={count}
                        disabled
                        style={{ color: "#ff8c00" }}
                      />
                      <p>Very Satisfied</p>
                    </div>
                    <p style={{ fontSize: "14px" }}>
                      Based on {count} reviews for{" "}
                      <span
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          textDecorationLine: "underline",
                        }}
                      >
                        verified purchases{" "}
                        <CheckCircleOutlined style={{ color: "green" }} />
                      </span>
                    </p>
                  </div>
                </div>

                {/* Filters and Dropdown */}
                <div className="review_filter_btn_sec">
                  <div className="review_filter_btn_group">
                    {/* Buttons for filters */}
                    <Button
                      onClick={() => setFilter("all")}
                      className={`review_filter_button ${filter === "all" ? "active" : ""}`}
                    >
                      All
                    </Button>
                    <Button
                      onClick={() => setFilter("withImages")}
                      className={`review_filter_button ${filter === "withImages" ? "active" : ""}`}
                    >
                      With Photos/Videos
                    </Button>

                    <Button
                      // type={filter === "rating" ? "primary" : "default"}
                      // onClick={() => setFilter("rating")}
                      className="review_filter_button"
                    >
                      Rating
                    </Button>
                  </div>

                  {/* Dropdown for sorting */}
                  <div>
                    <div
                      className="rating_sorting"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <AiOutlineSwap className="rotate" />
                      <label style={{ marginLeft: "8px" }}>Sort by:</label>
                      <Select
                        popupClassName="common-dropdown"
                        defaultValue={reviewSortBy}
                        onChange={handleSortChange}
                        // style={{ width: 200 }}
                      >
                        <Option value="newest">Newest first</Option>
                        <Option value="rating_high">Highest rated</Option>
                        <Option value="rating_low">Lowest rated</Option>
                        <Option value="oldest">Oldest first</Option>
                      </Select>
                    </div>
                  </div>
                </div>

                {/* Reviews Section */}
                <ReviewsList
                  reviews={reviews}
                  current={currentReviewPage}
                  onChange={onChange}
                />
              </>
            )}
          </div>
          {/* Packing & Delivery */}
          {/* <PackingMethod /> */}

          {/* RELATE PRODUCT */}
          {relatedProducts?.length > 0 && (
            <div className="relate_product">
              <h3>Related Products</h3>
              <div className="row">
                {relatedProducts?.map((product) => (
                  <div key={product?.product_id} className="col">
                    <ProductCard
                      title={product?.name}
                      discription={product?.description}
                      weight={product?.pricing[0]?.weight}
                      category="Seafood"
                      stock={product?.pricing[0]?.stock_quantity}
                      price={product?.pricing[0]?.price}
                      productData={product}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* RELATED SEARCH  */}
          {/* <Divider />
          <div className="relate_search">
            <h3>Related Searches</h3>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7].map(
                (variant, index) => (
                  <Button key={index} className="weight_pkt_button">
                    dc Shoes
                  </Button>
                )
              )}
            </div>
          </div> */}

          {/* footer */}
          <Footer />
        </>
      ) : (
        <Loader />
      )}

      {/* MODAL SECTION */}
      <CardModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        quantity={quantity}
        incrementQuantity={incrementQuantity}
        decrementQuantity={decrementQuantity}
        onChange={handleSwitchChange}
      />
    </div>
  );
};

export default ProductDetails;
