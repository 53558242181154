import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form } from "antd";
import { useNavigate } from "react-router";
import {
  Input,
  SelectInput,
  SigninWith,
  TextWithDivider,
} from "../../../components";
import route from "../../../config/route";
import PhoneInput from "react-phone-input-2";
import { googleLogin, register } from "../../../store/services/user";

const CreateAccount = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [phone, setPhone] = useState({
    phone: "",
    telephoneCode: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    category: "",
    categoryName: "",
  });
  const [reRender, setReRender] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthText, setPasswordStrengthText] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const handleGoogleSignup = () => googleLogin();

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setUserData({ ...userData, password });
    setReRender(password);
    const strength = calculatePasswordStrength(password);
    setPasswordStrength(strength);
    setPasswordStrengthText(getStrengthText(strength));
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    return strength;
  };

  const getStrengthText = (strength) => {
    switch (strength) {
      case 0:
        return "";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "";
    }
  };

  const handleNextStep = async () => {
    try {
      await form.validateFields(["email", "password"]);
      setCurrentStep(2);
    } catch (error) {}
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const payload = {
        business_name: values?.businessName,
        business_address: values?.businessAddress,
        contact_name: values?.contactName,
        business_category:
          selectedCategory.category?.toLowerCase() === "other"
            ? selectedCategory?.categoryName
            : selectedCategory?.category,
        email: userData?.email,
        password: userData?.password,
        phone_number: `+${phone?.phone}`,
        // profile_image: "http://example.com/profile.jpg",
        promotional_email: values?.remember || false,
        registration_number: values?.registrationNumber,
        // stripe_customer_id: "cus_123456789",
        telephone_code: phone?.telephoneCode,
        username: values?.contactName,
      };
      const response = await register(payload);
      if (response) {
        navigate(route.accountSecurity);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="createAccountContainer">
      <section>
        {currentStep === 1 ? (
          <>
            <h2 className="authHeading">Create an Account</h2>
            <p className="authDescription">
              Already have an account?{" "}
              <span onClick={() => navigate(route.signin)}>Sign in</span>
            </p>
          </>
        ) : (
          <h2 className="authHeading authHeading-mb">
            Complete Sign Up Process
          </h2>
        )}

        <Form
          form={form}
          name="createAccount"
          style={{
            width: "100%",
          }}
          // initialValues={{}}
          onFinish={onFinish}
          autoComplete="off"
          className="signinForm"
        >
          {currentStep === 1 && (
            <>
              <Form.Item
                label={null}
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input
                  label="Email"
                  required
                  onChange={(event) =>
                    setUserData({ ...userData, email: event?.target?.value })
                  }
                />
              </Form.Item>

              <Form.Item
                label={null}
                name="password"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long!",
                  },
                  {
                    pattern: /(?=.*[A-Z])/,
                    message:
                      "Password must contain at least 1 uppercase letter!",
                  },
                  {
                    pattern: /(?=.*\d)/,
                    message: "Password must contain at least 1 number!",
                  },
                ]}
              >
                <Input
                  label="Password"
                  type="password"
                  required
                  onChange={(event) => handlePasswordChange(event)}
                />
              </Form.Item>
              {reRender !== "" && (
                <div className="passwordStrengthBox">
                  <div className="passwordStrength">
                    {[
                      { active: passwordStrength >= 1 },
                      { active: passwordStrength >= 2 },
                      { active: passwordStrength >= 3 },
                      { active: passwordStrength >= 4 },
                    ].map((v, i) => {
                      return (
                        <div
                          key={i}
                          className={`${v?.active ? "active" : ""}`}
                        ></div>
                      );
                    })}
                  </div>
                  <div className="textBox">
                    <p>{passwordStrengthText}</p>
                  </div>
                </div>
              )}
              <Form.Item label={null}>
                <Button
                  className="pi55"
                  type="primary"
                  onClick={handleNextStep}
                  htmlType="button"
                >
                  Sign up
                </Button>
              </Form.Item>
            </>
          )}

          {currentStep === 2 && (
            <>
              <Form.Item
                label={null}
                name="businessName"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input label="Business Name" required />
              </Form.Item>
              <Form.Item
                label={null}
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <SelectInput
                  label="Type of Business"
                  options={[
                    { value: "Catering", label: "Catering" },
                    { value: "Grocery Store", label: "Grocery Store" },
                    { value: "Restaurant", label: "Restaurant" },
                    { value: "Wholesalers", label: "Wholesalers" },
                    { value: "Other", label: "Other" },
                  ]}
                  required
                  value={selectedCategory?.category}
                  onChange={(value) =>
                    setSelectedCategory({
                      category: value,
                    })
                  }
                />
              </Form.Item>
              {selectedCategory?.category === "Other" && (
                <Form.Item
                  label={null}
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input
                    label="Category Name"
                    value={selectedCategory.categoryName}
                    onChange={(event) =>
                      setSelectedCategory({
                        ...selectedCategory,
                        categoryName: event.target.value,
                      })
                    }
                  />
                </Form.Item>
              )}
              <Form.Item
                label={null}
                name="registrationNumber"
                rules={[
                  {
                    required: false,
                    message: "Required!",
                  },
                ]}
              >
                <Input label="Registration Number" required />
              </Form.Item>
              <Form.Item
                label={null}
                name="businessAddress"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input label="Business Address" required />
              </Form.Item>
              <Form.Item
                label={null}
                name="contactName"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input label="Contact Name" required />
              </Form.Item>
              <Form.Item
                label={null}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <PhoneInput
                  country={"us"}
                  value={phone?.phone}
                  onChange={(phone, country) =>
                    setPhone({
                      phone: `${phone}`,
                      telephoneCode: country?.dialCode,
                    })
                  }
                  className={phone ? "phoneInputValid" : ""}
                />
              </Form.Item>
              <Form.Item
                name="remember"
                className="remember"
                valuePropName="checked"
                label={null}
              >
                <Checkbox defaultChecked={false}>
                  I want to receive information on new products, services and
                  promotion codes
                </Checkbox>
              </Form.Item>
              <Form.Item label={null}>
                <Button
                  className="pi55"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Complete Sign up
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </section>
      {currentStep === 1 && (
        <>
          <TextWithDivider text="Or Sign in with" />
          <section className="section2">
            <SigninWith signinWith="Google" onClick={handleGoogleSignup} />
          </section>
        </>
      )}
    </div>
  );
};

export default CreateAccount;
