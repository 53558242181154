import React from "react";
import { Card, Col, Row, Image, Tooltip, Spin } from "antd";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";

const ProductImages = ({
  mainImage,
  thumbnails,
  // handleFavouriteToggle,
  // favBtnLoading,
  // isFavorite,
  setMainImage,
}) => {
  return (
    <Card style={{ position: "relative" }}>
      {/* Main Image */}
      <div style={{ height: "405px" }}>
        <Image
          src={mainImage}
          alt="Main Product"
          className="main_product_img"
        />

        {/* Heart Icon */}
        {/* {favBtnLoading ? (
          <div className="fav-action-loading">
            <Spin />
          </div>
        ) : (
          <Tooltip
            title={isFavorite ? "Remove from Favorites" : "Add to Favorites"}
          > */}
            {/* <div className="heart_icon" onClick={handleFavouriteToggle}>
              {isFavorite ? (
                <HeartFilled
                  style={{
                    color: "black",
                    fontSize: "24px",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <HeartOutlined
                  style={{
                    color: "gray",
                    fontSize: "24px",
                    verticalAlign: "middle",
                  }}
                />
              )}
            </div> */}
          {/* </Tooltip>
       )} */}
      </div> 

      {/* Thumbnail Images */}
      <Row gutter={10} style={{ marginTop: "10px" }}>
        {thumbnails.map((thumb, index) => (
          <Col key={index} span={6}>
            <Image
              src={thumb}
              alt={`Thumbnail ${index + 1}`}
              preview={false}
              style={{
                height: "100px",
                border:
                  mainImage === thumb ? "2px solid #1890ff" : "1px solid #ddd",
                cursor: "pointer",
              }}
              onClick={() => setMainImage(thumb)}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default ProductImages;
