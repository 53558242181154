import React from "react";

const TextWithDivider = ({ text }) => {
  return (
    <div className="textWithDivider">
      <div className="line"></div>
      <p className="text">{text}</p>
      <div className="line"></div>
    </div>
  );
};

export default TextWithDivider;
