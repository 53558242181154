import { Spin } from "antd";
import React from "react";

function Loader({text}) {
  return (
    <div
      style={{
        width: "100%",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" />
      {text && <p>{text}</p>}
    </div>
  );
}

export default Loader;
