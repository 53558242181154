import { Carousel } from "antd";
import React from "react";

function HeroSection({ children }) {
  return (
    <div className="hero-section">
      <Carousel
        className="carousel-main"
        dots={{
          className: "carousel-step-style",
        }}
      >
        <div className="carousel-item">
          <div>{children}</div>
        </div>
      </Carousel>
    </div>
  );
}

export default HeroSection;
