import React, { useEffect, useState } from "react";
import {
  Footer,
  Header,
  Loader,
  ProductCard,
  SimpleHeader,
} from "../../../../components";
import { Button, Col, Row } from "antd";
import image from "../../../../assets/images/tempProduct01.png";
import { useSelector } from "react-redux";
import {
  clearWishlist,
  getWishlist,
} from "../../../../store/services/wishlist";

const AddToFavorites = () => {
  const { wishlist, loading } = useSelector((state) => state.wishlist);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    getWishlist();
  }, []);

  const handleClearWishList = async () => {
    try {
      setBtnLoading(true);
      await clearWishlist();
    } catch (error) {
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="favorites-container">
        <div className="hdng-btn-row">
          <h2>Favorites</h2>
          {wishlist && wishlist?.length > 0 && (
            <Button
              loading={btnLoading}
              disabled={btnLoading}
              onClick={handleClearWishList}
            >
              Clear
            </Button>
          )}
        </div>

        {loading ? (
          <Loader />
        ) : wishlist && wishlist?.length > 0 ? (
          <Row gutter={[16, 16]} justify="start">
            {wishlist &&
              wishlist?.length > 0 &&
              wishlist?.map((product) => (
                <Col key={product?.product_id} xs={24} sm={12} md={12} lg={6}>
                  <ProductCard
                    title={product?.name}
                    discription={product?.description}
                    weight={product?.pricing[0]?.weight}
                    category="Seafood"
                    stock={product?.pricing[0]?.stock_quantity}
                    price={product?.pricing[0]?.price}
                    btn1={"Move to Cart"}
                    btn2={"Proceed to Checkout"}
                    className="customized_card"
                    productData={product}
                  />
                </Col>
              ))}
          </Row>
        ) : (
          <div className="no-addtocart">
            <h1>You don't have any favourited product</h1>
            <p>
              See something you like but not ready to purchase? We'll keep it{" "}
              <br /> save for you in yourfavorites
            </p>
          </div>
        )}

        {/* {wishlist?.length === 0 && <div className="product-you-may-like">
          <h2>Product you may like</h2>
          <Row gutter={[16, 16]} justify="center">
            {[1, 2, 3, 4].map((product, index) => (
              <Col key={index} xs={24} sm={12} md={12} lg={6}>
                <ProductCard
                  imgUrl={image}
                  title="Premium Snail Delicacy"
                  discription="Savor the ocean's bounty with our handpicked snails. Perfect for adventurous palates,"
                  weight="8kG (MOQ)"
                  category="Seafood"
                  className="customized_card"
                />
              </Col>
            ))}
          </Row>
        </div>} */}
      </div>
      {/* footer */}
      <Footer />
    </div>
  );
};

export default AddToFavorites;
