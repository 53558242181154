import { Button, Form } from "antd";
import React, { useState } from "react";
import Input from "../../../Input";
import { changePassword } from "../../../../store/services/user";

function ChangePassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const payload = {
        old_password: values?.oldPassword,
        new_password: values?.newPassword,
        confirm_password: values?.confirmPassword,
        };
      const response = await changePassword(payload);
      if (response) {
        form.resetFields();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="changePasswordBody">
      <div className="changePasswordHeader">
        <p>Change Password</p>
      </div>
      <div className="changePasswordContent">
        <Form
          form={form}
          style={{
            width: "100%",
          }}
          onFinish={onFinish}
          autoComplete="off"
          className="changePasswordForm"
        >
          <Form.Item
            label={null}
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Input label="Old Password" type="password" />
          </Form.Item>

          <Form.Item
            label={null}
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Input label="New Password" type="password" />
          </Form.Item>

          <Form.Item
            label={null}
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Required!",
              },
              {
                validator: (_, value) =>
                  value && value !== form.getFieldValue("newPassword")
                    ? Promise.reject(new Error("Passwords do not match!"))
                    : Promise.resolve(),
              },
            ]}
          >
            <Input label="Confirm Password" type="password" />
          </Form.Item>

          <Form.Item className="save-btn-form-item" label={null}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default ChangePassword;
