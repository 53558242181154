import { Button, Image, Input, Modal, Select, Upload } from "antd";
import React, { useState } from "react";
import { FaStar } from "react-icons/fa6";
import { ReviewPublishedModal } from "../index";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { MdDeleteOutline } from "react-icons/md";
import { RiVideoFill } from "react-icons/ri";
import { addReview, uploadReviewImage } from "../../../store/services/review";
import { Loader } from "../../index";
const { Dragger } = Upload;
const { TextArea } = Input;

function ReviewModal({ isReivewModalVisible, setIsReivewModalVisible, id }) {
  const [reviewPublishedModalVisible, setReviewPublishedModalVisible] =
    useState(false);
  const [fileList, setFileList] = useState([]);
  const [reviewData, setReviewData] = useState({
    rating: undefined,
    content: undefined,
    uploadedUrls: [],
  });
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async (file) => {
    try {
      setUploading(true);
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      const response = await uploadReviewImage(formData);
      if (response) {
        setReviewData({
          ...reviewData,
          uploadedUrls: [...reviewData?.uploadedUrls, response?.url],
        });
        setFileList([...fileList, file]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: "file",
    multiple: true,
    listType: "picture",
    accept: "image/*,video/*",
    fileList,
    showUploadList: false,
    beforeUpload: async (file) => {
      await handleUpload(file);
      return false;
    },
    onRemove: (file) => {
      setFileList(fileList.filter((item) => item.uid !== file.uid));
    },
  };

  const handleRemoveFile = (index) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);

    const newUploadedUrls = [...reviewData?.uploadedUrls];
    newUploadedUrls.splice(index, 1);
    setReviewData({ ...reviewData, uploadedUrls: newUploadedUrls });
  };

  const handleClose = () => {
    setFileList([]);
    setReviewData({
      rating: undefined,
      content: undefined,
      uploadedUrls: [],
    });
    setIsReivewModalVisible(false);
  };

  const handlePublishReview = async () => {
    try {
      setLoading(true);
      const payload = {
        title: "",
        rating: reviewData?.rating,
        content: reviewData?.content,
        product_id: id,
        images: reviewData?.uploadedUrls,
      };
      const response = await addReview(payload);
      if (response) {
        handleClose();
        setReviewPublishedModalVisible(true);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={isReivewModalVisible}
        onCancel={() => handleClose()}
        title={false}
        footer={null}
        width={600}
        className="review-modal-parent"
      >
        <div className="review-modal">
          <h5 className="title">Review</h5>
          <div className="content-parent">
            <div className="field-label-parent">
              <label>Upload images</label>
              {uploading ? (
                <Loader />
              ) : (
                <Dragger {...uploadProps} className="upload-box">
                  <p className="ant-upload-drag-icon">
                    <img src={uploadIcon} />
                  </p>
                  <p className="ant-upload-text">
                    Drop your images or videos here or{" "}
                    <span>click to browse</span>
                  </p>
                </Dragger>
              )}
              <div className="files-list">
                {fileList.map((file, index) => (
                  <div key={file.uid} className="file-item">
                    <div className="icon-parent">
                      {file.type.startsWith("image") ? (
                        <img src={URL.createObjectURL(file)} />
                      ) : (
                        <RiVideoFill className="video-icon" />
                      )}
                    </div>
                    <p>{file?.name}</p>
                    <div className="file-actions">
                      <MdDeleteOutline
                        className="deleteIcon"
                        onClick={() => handleRemoveFile(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="field-label-parent">
              <label>Rating</label>
              <Select
                popupClassName="common-dropdown"
                value={reviewData?.rating}
                className="rating-select"
                onChange={(value) =>
                  setReviewData({ ...reviewData, rating: value })
                }
                options={[1, 2, 3, 4, 5].map((count) => ({
                  value: count,
                  label: (
                    <span className="reviewModal-select-item">
                      {Array.from({ length: count }, (_, index) => (
                        <FaStar key={index} />
                      ))}
                      <p>{count} Star Rating</p>
                    </span>
                  ),
                }))}
              />
            </div>
            <div className="field-label-parent">
              <label>Feedback</label>
              <TextArea
                value={reviewData?.content}
                onChange={(e) =>
                  setReviewData({ ...reviewData, content: e.target.value })
                }
                className="feedback-textarea"
                placeholder="Write down your feedback about our product & services"
                autoSize={{
                  minRows: 6,
                  maxRows: 8,
                }}
              />
            </div>
            <Button
              className="outlined-btn save-btn"
              loading={loading}
              disabled={
                loading || reviewData?.rating == 0 || !reviewData?.content
              }
              onClick={handlePublishReview}
            >
              Publish Review
            </Button>
          </div>
        </div>
      </Modal>
      <ReviewPublishedModal
        reviewPublishedModalVisible={reviewPublishedModalVisible}
        setReviewPublishedModalVisible={setReviewPublishedModalVisible}
      />
    </>
  );
}

export default ReviewModal;
