import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router";
import route from "../../../config/route";

const AccountCreationSuccessful = () => {
  const navigate = useNavigate();

  const handleVerifyAccount = () => {
    navigate(route.verificationProcesses);
  };

  return (
    <div className="welcomeBackContainer">
      <h2 className="authHeading">Account Creation Successful</h2>
      <p className="welcomeText">
        Welcome to Frostflow! Start your verification to access prices and place
        orders. Click below to provide your details, and our team will contact
        you soon
      </p>
      <Button className="pi55" type="primary" onClick={handleVerifyAccount}>
        Verify Account
      </Button>
    </div>
  );
};

export default AccountCreationSuccessful;
