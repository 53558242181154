import React, { use } from "react";
import AppLogo from "../AppLogo";
import { useLocation, useNavigate } from "react-router";
import route from "../../config/route";
import { IoMdArrowRoundBack } from "react-icons/io";

const SimpleHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="simpleHeader">
      {/* {pathname === route.signin || pathname === route.signup ? (
        <>
          <IoMdArrowRoundBack
            onClick={() => navigate(route.home)}
            size={33}
            style={{ marginRight: "20px", cursor: "pointer" }}
          />
        </>
      ) : null} */}
      <AppLogo />
    </div>
  );
};

export default SimpleHeader;
