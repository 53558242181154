import React from "react";
import { useNavigate } from "react-router";
import route from "../../config/route";
import { isUserAuthenticated } from "../../utils/index";
import { useSelector } from "react-redux";

const AppLogo = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const isAuthenticated = isUserAuthenticated(user);

  return (
    <img
      className="defaultLogo"
      src={require("../../assets/images/logo.png")}
      alt={"Logo"}
      // onClick={() => navigate(route.home)}
      onClick={() => navigate(isAuthenticated ? route.exploreHome : route.home)}
    />
  );
};

export default AppLogo;
