import { notification } from "antd";
import { store } from "..";
import { privateAPI } from "../../config/constants";
import {
  setLoading,
  setProducts,
  setSingleProduct,
} from "../slices/productSlice";

export const getProducts = async (Payload = {
  page: 1,
  page_size: 10
}) => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/products", Payload);
    if (response) {
      store.dispatch(setProducts(response?.data?.products));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getSingleProduct = async (id) => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get(`/products/${id}`);
    if (response) {
      store.dispatch(setSingleProduct(response?.data));
    }
    return response.data;
  } catch (error) {
    notification.error({
      message:
        typeof error?.response?.data?.detail === "string"
          ? error?.response?.data?.detail
          : "Server Error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getProductSuggestions = async (query) => {
  try {
    const response = await privateAPI.get(
      `/products/suggestions?search_query=${query}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
