import { notification } from "antd";
import { store } from "..";
import { setLoading, setWishlist } from "../slices/wishlistSlice";
import { privateAPI } from "../../config/constants";

const handleError = (msg) => {
  notification.error({
    message: msg || "Server Error",
  });
};

export const getWishlist = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/wishlist/");
    if (response) {
      store.dispatch(setWishlist(response?.data?.products));
    }
    return response.data;
  } catch (error) {
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const addToWishlist = async (id) => {
  try {
    const response = await privateAPI.post(`/wishlist/add/${id}`);
    if (response) {
      await getWishlist();
      notification.success({
        message: response?.data?.message,
      });
    }
    return response.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const removeToWishlist = async (id) => {
  try {
    const response = await privateAPI.delete(`/wishlist/remove/${id}`);
    if (response) {
      await getWishlist();
      notification.success({
        message: response?.data?.message,
      });
    }
    return response.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const clearWishlist = async () => {
  try {
    const response = await privateAPI.delete(`/wishlist/clear`);
    if (response) {
      await getWishlist();
      notification.success({
        message: response?.data?.message,
      });
    }
    return response.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};