import React, { useEffect, useState } from "react";
import snail from "../../assets/images/tempProduct01.png";
import { FaChevronRight, FaTrashAlt } from "react-icons/fa";
import { GoTrash } from "react-icons/go";
import { FiInfo } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import { removeCartItem, updateCart } from "../../store/services/cart";
import { Spin } from "antd";

const ProductCardView = ({ product, variant }) => {
  const [loading, setLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const updateCartItem = async (type) => {
    try {
      setLoading(true);

      const currentQty = variant?.quantity || 0;
      const newQty =
        type === "increment"
          ? currentQty + 1
          : type === "decrement" && currentQty > 1
          ? currentQty - 1
          : currentQty;

      if (newQty === currentQty) return;

      const payload = {
        product_id: product?.product_id,
        pricing_id: variant?.pricing_id,
        quantity: newQty,
      };

      await updateCart(payload);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleCartItemRemove = async () => {
    try {
      setRemoveLoading(true);
      const payload = {
        product_id: product?.product_id,
        pricing_id: variant?.pricing_id,
      };
      await removeCartItem(payload);
    } catch (error) {
    } finally {
      setRemoveLoading(false);
    }
  };

  return (
    <div className="cart-item">
      <div className="item-details">
        <img src={product?.image_url} alt="" className="item-image" />
        <div className="item-info">
          <p className="item-name">{product?.name}</p>
          <p className="item-price">
            ${variant?.price} <span>/ {variant?.weight}kg</span>{" "}
            <FiInfo className="info-icon" />
          </p>
        </div>
        <div className="arrow">
          {" "}
          <FaChevronRight />
        </div>
      </div>
      <div className="item-actions">
        <div className="quantity_select_section card_quantity">
          {loading ? (
            <div className="quantity-loading">
              <Spin />
            </div>
          ) : (
            <div className="quantity_select">
              <button
                className="quentity_btn"
                onClick={() => updateCartItem("decrement")}
              >
                <FiMinus style={{ paddingRight: "3px", marginTop: "4px" }} />
              </button>
              <span style={{ fontSize: "16px" }}>{variant?.quantity}</span>
              <button
                className="quentity_btn"
                onClick={() => updateCartItem("increment")}
              >
                +
              </button>
            </div>
          )}
        </div>
        <p className="item-total">${variant?.item_total}</p>
        {removeLoading ? (
          <Spin />
        ) : (
          <button className="delete-btn" onClick={handleCartItemRemove}>
            <GoTrash size={22} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCardView;
