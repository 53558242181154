import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router";
import route from "../../../config/route";
import { getActiveSessions } from "../../../store/services/user";

const CallConfirmation = () => {
  const navigate = useNavigate();

  const handleProceed = async () => {
    const activeSessionsRes = await getActiveSessions();
    if (activeSessionsRes) {
      navigate(route.exploreHome);
    }
  };

  return (
    <div className="welcomeBackContainer">
      <h2 className="authHeading">We will call you!</h2>
      <p className="welcomeText">
        Our team will contact you within 24 hours to validate your information.
        Once validated, you’ll gain access to view product prices
      </p>
      <Button className="pi55" type="primary" onClick={handleProceed}>
        Proceed to Dashboard
      </Button>
    </div>
  );
};

export default CallConfirmation;
