import React from "react";
import { ServiceCard } from "../../index";

function WhyFrostFlow() {
  return (
    <div className="whyFrostFlow-sec-main">
      <h1 className="hdng">Why Choose FrostFlow</h1>
      <p className="text">
        At Frostflow, we’re not just about seafood; we’re about an oceanic
        experience that transcends the ordinary. Here’s why you’ll love choosing
        Frostflow:
      </p>
      <div className="services-cards-parent">
        <ServiceCard
          title="Fresh & Authentic"
          discription="Our seafood isn’t just good—it’s exceptional. We source from pristine waters, ensuring every bite is a celebration of flavor."
        />
        <ServiceCard
          title="Consistent Quality"
          discription="Our team knows seafood like the tides know the shore. From snails to prawns, we’ve mastered the art of oceanic delight."
        />
        <ServiceCard
          title="Dependable Service"
          discription="Each product is carefully curated, chosen for its freshness, texture, and taste. We don’t settle for anything less than extraordinary."
        />
      </div>
    </div>
  );
}

export default WhyFrostFlow;
