export const lightTheme = {
  primary: "#065295",
  secondary: "#24A7DF",
  primaryHover: "#40a9ff",
  primaryActive: "#096dd9",
  primaryText: "#000",
  primaryBlueText: "#065295",
  constantPrimary: "#065295",

  bgWhite: "#fff",
  bgModal: "#fff",
  bgPrimary: "#EEF6FC",
  bgSecondary: "#F1F2F3",
  bgBlue:"#003666",
  btnPrimary:'#E5F3FF',

  border: "rgba(202, 202, 202, 1)",
  borderBlack:"rgba(0, 0, 0, 1)",
  borderGray: "#99A0AF",
  borderD8 :"#D8D8D8",

  placeholder: "#bfbfbf",
  disabled: "#f5f5f5",

  shadow: "rgba(24, 144, 255, 0.2)",

  primaryBlue100: "#065295",
  primaryBlue90: "rgba(34, 144, 229, 1)",
  primaryBlue70: "#1E80CC",
  primaryLightBlue5: "#F2F9FF", 

  textGray100: "rgba(16, 17, 18, 1)",
  textGray70: "rgba(112, 112, 112, 1)",
  textGray10: "rgba(214, 217, 219, 1)",
  textGray50:"rgba(130, 138, 155, 1)",
  textGraySand:'rgba(120, 128, 135, 1)',
  textGray101:'rgba(92, 92, 92, 1)',
  textGray95:'rgba(95, 108, 114, 1)',
  textGray53:"rgba(53, 53, 53, 1)",

  tRowBg: "#FCFBFA",
};

export const darkTheme = {
  primary: "#065295", // Dark Blue
  secondary: "#24A7DF", // Sky Blue
  primaryHover: "#40a9ff", // Light Blue
  primaryActive: "#096dd9", // Deep Blue
  primaryText: "#FFF", // White
  primaryBlueText: "#065295", // Dark Blue
  constantPrimary: "#065295", // Dark Blue

  bgWhite: "#fff", // White
  bgModal: "#fff", // White
  bgPrimary: "#EEF6FC", // Light Blue Gray
  bgSecondary: "#F1F2F3", // Light Gray
  bgBlue:"#003666", // Dark Blue
  btnPrimary:'#E5F3FF', // Light Blue

  border: "rgba(202, 202, 202, 1)", // Light Gray
  borderBlack:"rgba(0, 0, 0, 1)", // Black
  borderGray: "#99A0AF", // Gray
  borderD8 :"#D8D8D8", // Gray

  placeholder: "#bfbfbf", // Gray
  disabled: "#f5f5f5", // Very Light Gray

  shadow: "rgba(24, 144, 255, 0.2)", // Translucent Blue

  primaryBlue100: "#065295", // Dark Blue
  primaryBlue90: "rgba(34, 144, 229, 1)", // Bright Blue

  textGray100: "rgba(16, 17, 18, 1)", // Almost Black
  textGray70: "rgba(112, 112, 112, 1)", // Medium Gray
  textGray10: "rgba(214, 217, 219, 1)", // Light Gray
  textGray50:"rgba(130, 138, 155, 1)", //blue gray
  textGraySand:'rgba(120, 128, 135, 1)',//sand gray
  textGray101:'rgba(92, 92, 92, 1)',//dark gray
  textGray95:'rgba(95, 108, 114, 1)',//dark gray
  textGray53:"rgba(53, 53, 53, 1)",//dark gray

  tRowBg: "#FCFBFA", // Off-White
}; 

