import React from "react";
import { Avatar, Rate, Pagination } from "antd";

const ReviewsList = ({ reviews, current, onChange }) => {
  return (
    <div className="reviews-section">
      {reviews.map((review, index) => (
        <div key={index} className="review-item">
          {/* User Info */}
          <div className="review-user-info">
            <Avatar size="large" className="review-avatar">
              {review?.username?.charAt(0) || ''}
            </Avatar>
            <div>
              <h5 className="review-user-name">
                {review?.username || ''}{" "}
                {/* <img
                  src={`https://flagcdn.com/w40/${getCountryCode(
                    review.country
                  )}.png`}
                  alt={review.country}
                  style={{ width: "20px", marginLeft: "5px" }}
                /> */}
              </h5>
              {/* <p className="review-date">{review.date}</p> */}
            </div>
          </div>

          {/* Rating and Content */}
          <div className="review-content">
            <Rate
              disabled
              defaultValue={review.rating}
              className="review-rate"
            />
            <p className="review-text">{review.content}</p>

            {/* Photos */}
            <div className="review-photos">
              {review.images.map(({ image_url, image_id }) => (
                <img
                  key={image_id}
                  src={image_url}
                  alt="Review"
                  className="review-photo"
                />
              ))}
            </div>
          </div>
        </div>
      ))}

      {/* Pagination */}
      <Pagination current={current} onChange={onChange} total={10} />
    </div>
  );
};

// function getCountryCode(flagEmoji) {
//   // Convert the flag emoji to its Unicode code points
//   const codePoints = Array.from(
//     flagEmoji,
//     (char) => char.codePointAt(0) - 127397
//   );

//   // Map the code points back to characters and join them to form the country code
//   return codePoints
//     .map((cp) => String.fromCharCode(cp))
//     .join("")
//     .toLowerCase();
// }
export default ReviewsList;
