import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  filters: [],
  appliedFilters: {
    // search: null,
    // category_id: null,
    // sub_categories: null,
    // min_price: null,
    // max_price: null,
    // min_rating: null,
    // max_rating: null,
    // origin: null,
    // organic: null,
    // sort_by: null,
  },
};

export const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setFilters: (state, action) => {
      state.filters = action?.payload;
    },
    setAppliedFilters: (state, action) => {
      state.appliedFilters = action?.payload;
    },
  },
});

export const { setLoading, setFilters, setAppliedFilters } =
  filterSlice.actions;

export default filterSlice.reducer;
