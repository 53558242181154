
const route = {
  // Auth
  signin: "/signin",
  verification: "/verification",
  welcome: "/welcome",
  signup: "/signup",
  createAccount: "/create-account",
  accountSecurity: "/account-security",
  accountCreationSuccessful: "/account-creation-successful",
  createNewPassword: "/create-new-password",
  verificationForm: "/verification-form",
  callConfirmation: "/call-confirmation",
  verificationProcesses: "/verification-processes",

  // Reset Password
  resetPasswordOption: "/reset-password-option",
  resetPassword: "/reset-password",
  checkEmail: "/check-email",
  verifyCode: "/verify-code",
  verifyCodeEmail: '/signup/email/verify',
  thankYou: "/thank-you",

  // App
  home: "/",
  exploreHome: "/home-explore",
  category: "/category/:id",
  ProductDetails: "/product-details/:id",
  PersonalInformation:"/checkout-details",
  paymentMethod:"/checkout-payment_method",
  tradeAssuranceOrder:'/trade-assurance-order',
  wireTransferInformation:'/wire-transfer-information',
  recurringOrderOption: "/recurring-order-option",
  paymentSecurity: "/payment-security",
  orderSuccessfull: "/order-successfull",
  shoppingCart: "/shopping-cart",
  addToFavorites: "/add-to-favorites",

  // Profile Management
  orders: "/orders",
  cards: "/cards",
  accountSettings: '/account-settings',
  orderDetails: "/order-detail/:id"
};

export default route;
