import { Breadcrumb, Button, Checkbox, Col, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../../index";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { IoArrowBack, IoLocationOutline } from "react-icons/io5";
import { CiLock } from "react-icons/ci";
import { Input, SelectInput } from "../../../index";
import PhoneInput from "react-phone-input-2";
import {
  addShippingAddress,
  deleteShippingAddress,
  updateShippingAddress,
} from "../../../../store/services/shipping";
import { IoIosAddCircleOutline } from "react-icons/io";
import { HomeOutlined, RightOutlined } from "@ant-design/icons";
import route from "../../../../config/route";
import {
  getCities,
  getCountries,
  getStates,
} from "../../../../store/services/location";
import { useLocation, useNavigate } from "react-router";

function ShippingAddress({ isEditing, onEditClick }) {
  const [selectedEditableAddress, setSelectedEditableAddress] = useState(null);
  return isEditing ? (
    <Edit
      onEditClose={onEditClick}
      address_id={selectedEditableAddress}
      setAddressId={setSelectedEditableAddress}
    />
  ) : (
    <View
      onEditClick={onEditClick}
      setSelectedAddressId={setSelectedEditableAddress}
      selectedAddressId={selectedEditableAddress}
    />
  );
}

const View = ({ onEditClick, selectedAddressId, setSelectedAddressId }) => {
  const { shippingAddress, loading } = useSelector((state) => state.shipping);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [statusChngLoading, setStatusChngLoading] = useState(false);

  const handleUpdateAddressStatus = async ({ id, updatedData }) => {
    try {
      setSelectedAddressId(id);
      setStatusChngLoading(true);
      const payload = updatedData;
      await updateShippingAddress({ id, data: payload });
    } catch (error) {
      console.log(error);
    } finally {
      setStatusChngLoading(false);
    }
  };

  const handleEditClick = (address_id) => {
    setSelectedAddressId(address_id);
    onEditClick();
  };

  const handleAddClick = () => {
    onEditClick();
  };

  const handleDeleteAddress = async (address_id) => {
    try {
      setSelectedAddressId(address_id);
      setDeleteLoading(true);
      await deleteShippingAddress(address_id);
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="shippingAddressBody">
      <div className="shippingAddressHeader">
        <p>Shipping Address</p>
        <IoIosAddCircleOutline onClick={handleAddClick} />
      </div>
      <div className="shippingAddressContent">
        {loading ? (
          <Loader />
        ) : (
          shippingAddress?.map((address) => (
            <div className="shippingAddressCard">
              <div className="dflex">
                <h6>{address?.full_name}</h6>
                {selectedAddressId === address?.address_id && deleteLoading ? (
                  <Spin />
                ) : (
                  <MdDeleteOutline
                    onClick={() => handleDeleteAddress(address?.address_id)}
                  />
                )}
              </div>
              <div className="addressParent">
                <p>{address?.address_line1 || ""}</p>
                {address?.address_line2 && <p>{address?.address_line2}</p>}
              </div>
              <span>
                <label>Phone Number:</label>
                <p>{address?.phone_number}</p>
              </span>
              <span>
                <label>Email:</label>
                <p>{address?.email}</p>
              </span>
              <div className="actionsParent">
                {selectedAddressId === address?.address_id &&
                statusChngLoading ? (
                  <Spin />
                ) : (
                  <Checkbox
                    className="defaultAddressCheckBox"
                    checked={address?.is_default}
                    onChange={(e) =>
                      handleUpdateAddressStatus({
                        id: address?.address_id,
                        updatedData: {
                          ...address,
                          is_default: e.target.checked,
                        },
                      })
                    }
                  >
                    Set as default shipping address
                  </Checkbox>
                )}
                <Button onClick={() => handleEditClick(address?.address_id)}>
                  Edit Address
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export const Edit = ({ onEditClose, address_id, setAddressId }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const { shippingAddress } = useSelector((state) => state.shipping);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    loading: dropdownLoading,
    countries,
    states,
    cities,
  } = useSelector((state) => state.location);
  const [address, setAddress] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const showBreadCrumbOrHeader = pathname === "/account-settings";

  useEffect(() => {
    if (address_id) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [address_id]);

  useEffect(() => {
    if (isEditMode && address_id) {
      const address = shippingAddress.find(
        (address) => address.address_id === address_id
      );
      setAddress(address);
    }
  }, [address_id, isEditMode]);

  useEffect(() => {
    if (isEditMode && address_id) {
      setPhone(address?.phone_number);
      form.setFieldsValue({
        country: address?.country,
        fullName: address?.full_name,
        phone: address?.phone_number,
        email: address?.email,
        address1: address?.address_line1,
        address2: address?.address_line2 || "",
        state: address?.state,
        city: address?.city,
        postalCode: address?.postal_code,
        isDefault: address?.is_default,
      });
    }
  }, [address]);

  useEffect(() => {
    getCountries();
    if (isEditMode && address_id) {
      if (address?.country) {
        getStates({
          country: address?.country,
        });
      }
      if (address?.state && address?.city) {
        getCities({
          country: address?.country,
          state: address?.state,
        });
      }
    }
  }, [address_id]);

  const onFinish = async (values) => {
    try {
      setBtnLoading(true);
      const payload = {
        full_name: values?.fullName,
        address_line1: values?.address1,
        address_line2: values?.address2 || null,
        city: values?.city,
        state: values?.state,
        postal_code: values?.postalCode,
        country: values?.country,
        phone_number: phone?.charAt(0) === "+" ? phone : `+${phone}`,
        email: values?.email,
        is_default: values?.isDefault || false,
      };
      if (!isEditMode) {
        const response = await addShippingAddress(payload);
        if (response) {
          form.resetFields();
          onEditClose && onEditClose();
        }
      } else {
        const response = await updateShippingAddress({
          id: address_id,
          data: payload,
        });
        if (response) {
          setAddressId(null);
          form.resetFields();
          onEditClose && onEditClose();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleCountrySelect = async (country) => {
    try {
      form.setFieldsValue({
        state: null,
        city: null,
      });
      const payload = {
        country,
      };
      await getStates(payload);
    } catch (error) {}
  };

  const handleStateSelect = async (state) => {
    try {
      form?.setFieldValue({
        city: null,
      });
      const payload = {
        country: form.getFieldValue("country"),
        state,
      };
      await getCities(payload);
    } catch (error) {}
  };

  return (
    <div className="shippingAddressContainer">
      {showBreadCrumbOrHeader && (
        <div className="breadcrumbBox">
          <Breadcrumb
            separator={<RightOutlined className="breadcrumb-seperator" />}
            items={[
              {
                title: <HomeOutlined size={18} />,
                className: "breadCrumbItem",
                onClick: () => navigate(route.exploreHome),
              },
              {
                title: <span>User Account</span>,
                className: "breadCrumbItem",
              },
              {
                title: <span>Card & Address</span>,
                onClick: onEditClose,
                className: "breadCrumbItem",
              },
              {
                title: "Edit Shipping Address",
                className: "breadCrumbItem",
              },
            ]}
          />
        </div>
      )}
      <div className="shippingAddressEditBody">
        {showBreadCrumbOrHeader ? (
          <>
            <span>
              <IoLocationOutline />
              <h5>Shipping Address</h5>
            </span>
            <span className="noteParent">
              <CiLock />
              <p>
                Your personal information is encrypted and will only be used for
                delivery purposes.
              </p>
            </span>
          </>
        ) : (
          isEditMode && <IoArrowBack onClick={onEditClose} />
        )}
        <Form
          form={form}
          style={{
            width: "100%",
          }}
          autoComplete="off"
          name="shippingAddressForm"
          onFinish={onFinish}
        >
          <Row gutter={[20, 0]}>
            <Col span={24}>
              <Form.Item
                label={null}
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <SelectInput
                  label="Country / region"
                  showSearch
                  onChange={(value) => handleCountrySelect(value)}
                  loading={
                    !form.getFieldValue("country") && dropdownLoading
                      ? true
                      : false
                  }
                  options={
                    countries && countries?.length
                      ? countries?.map((country) => {
                          return {
                            value: country,
                            label: country,
                          };
                        })
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={null}
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input label="Full Name" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={null}
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input label="Email Address" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={null}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <PhoneInput
                  country={"us"}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  className={phone ? "phoneInputValid" : ""}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={null}
                name="address1"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input label="Street address or P.O. box" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={null} name="address2">
                <Input label="Apartment, suite, unit, building, floor (optional)" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={null}
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <SelectInput
                  label="State / province"
                  disabled={!form.getFieldValue("country")}
                  showSearch
                  loading={
                    !form.getFieldValue("state") && dropdownLoading
                      ? true
                      : false
                  }
                  onChange={(value) => handleStateSelect(value)}
                  options={
                    states && states?.length
                      ? states?.map((state) => {
                          return {
                            value: state,
                            label: state,
                          };
                        })
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={null}
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <SelectInput
                  disabled={!form.getFieldValue("state")}
                  label="City"
                  showSearch
                  loading={
                    !form.getFieldValue("city") && dropdownLoading
                      ? true
                      : false
                  }
                  options={
                    cities && cities?.length
                      ? cities?.map((city) => {
                          return {
                            value: city,
                            label: city,
                          };
                        })
                      : []
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item
                label={null}
                name="postalCode"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input label="Postal Code" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={null} name="isDefault" valuePropName="checked">
                <Checkbox>Set as default shipping address</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={null}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="pi55"
                  type="primary"
                  htmlType="submit"
                  loading={btnLoading}
                  disabled={btnLoading}
                >
                  {!isEditMode ? "Save" : "Save Changes"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ShippingAddress;
