import { Col, Row } from "antd";
import React from "react";
import { Button, ProductCard } from "../../index";
import TEMPORARYPRODUCT1 from "../../../assets/images/tempProduct01.png";
import TEMPORARYPRODUCT2 from "../../../assets/images/tempProduct02.png";
import TEMPORARYPRODUCT3 from "../../../assets/images/tempProduct03.png";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { isUserAuthenticated } from "../../../utils/index";

function Categories() {
  const { products, loading } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.user);
  const isAuthenticated = isUserAuthenticated(user);
  const navigate = useNavigate();
  return (
    <div className="categories-section-container">
      <h1 className="hdng">Frozen Food Categories</h1>
      <h5 className="sub-hdng">
        Sustainably sourced, expertly frozen, and ready for your kitchen
      </h5>
      <Row className="product-cards-parent" gutter={[10, 30]} justify="start">
        {products?.slice(0, 3)?.map((product) => (
          <Col span={24} sm={12} md={8}>
            <ProductCard
              title={product?.name}
              discription={product?.description}
              weight={product?.pricing[0]?.weight}
              category="Seafood"
              stock={product?.pricing[0]?.stock_quantity}
              productData={product}
              className={`product-card-style-landing-page ${
                !isAuthenticated && "product-card-landing-unauth"
              }`}
            />
          </Col>
        ))}
      </Row>
      <Button
        className="outlined-btn explore-now-btn"
        onClick={() => navigate("/home-explore")}
      >
        Explore Now
      </Button>
    </div>
  );
}

export default Categories;
