import React from "react";
import { Button, Input as AntInput } from "antd";

const VerifyCode = (props) => {
  const {
    heading,
    description,
    value,
    setValue,
    handleSubmit,
    handleChangeNumberOrEmail,
    resendCode,
    contactValue,
    loading,
    type,
  } = props;
  const isEmail = type === "email";
  const handleHavingTrouble = () => {};

  const onChange = (text) => {
    console.log("Text:", text);
  };

  const onInput = (value) => {
    if (/^\d*$/.test(value?.join(""))) {
      setValue(value?.join(""));
    } else {
      return;
    }
  };

  const sharedProps = {
    value,
    onChange,
    onInput,
  };

  return (
    <div className="verifyCodeBody">
      <h2 className="heading">Verify Your {heading}</h2>
      <p className="description">{`A 6-digit verification code has been sent to ${description}`}</p>
      <p className="codeText">{contactValue}</p>

      <div className="otpInput">
        <p>6-Digit Verification Code</p>
        <AntInput.OTP {...sharedProps} />
      </div>
      <Button
        className="pi55"
        type="primary"
        onClick={handleSubmit}
        loading={loading}
        disabled={loading}
      >
        Submit Code
      </Button>

      <div className="links">
        <p onClick={handleHavingTrouble}>Having Trouble?</p>
        <p onClick={handleChangeNumberOrEmail}>{isEmail ? "Change email address" :"Change phone number"}</p>
        <p onClick={resendCode}>Resend Code</p>
      </div>
    </div>
  );
};

export default VerifyCode;
