import React from "react";
import AppLogo from "../AppLogo";
import { message } from "antd";
import { useLocation } from "react-router";
import SimpleHeader from "../SimpleHeader";

const AuthLayout = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const { pathname } = useLocation();
  const isVerficationProcesses = pathname === "/verification-processes";

  const { children } = props;
  return (
    <>
      {contextHolder}
      <div className="authLayout">
        <SimpleHeader />
        {/* <div className="authHeader">
          <AppLogo />
        </div> */}
        <div
          className={`authContent ${isVerficationProcesses ? "bgPrimary" : ""}`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
