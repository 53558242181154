import { notification } from "antd";
import { privateAPI } from "../../config/constants";
import { setCartData, setLoading } from "../slices/cartSlice";
import { store } from "..";

const handleError = (msg) => {
  notification.error({
    message: msg || "Server Error",
  });
};

export const getCarts = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/cart/");
    if (response) {
      store.dispatch(setCartData(response?.data));
    }
    return response.data;
  } catch (error) {
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getSelectedCarts = async (selectedProducts) => {
  try {
    const queryString = selectedProducts
      .map((id) => `item_ids=${id}`)
      .join("&");
    const response = await privateAPI.get(`/cart/?${queryString}`);
    return response.data;
  } catch (error) {}
};

export const addToCart = async (payload) => {
  try {
    const { product_id, pricing_id, quantity } = payload;
    const response = await privateAPI.post(
      `/cart/add/${product_id}/${pricing_id}?quantity=${quantity}`
    );
    if (response) {
      notification.success({
        message: response?.data?.message,
      });
    }
    return response.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const updateCart = async (payload) => {
  try {
    const { product_id, pricing_id, quantity } = payload;
    const response = await privateAPI.put(
      `/cart/update/${product_id}/${pricing_id}?quantity=${quantity}`
    );
    if (response) {
      await getCarts();
      notification.success({
        message: response?.data?.message,
      });
    }
    return response.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const removeCartItem = async (payload) => {
  try {
    const { product_id, pricing_id } = payload;
    const response = await privateAPI.delete(
      `/cart/remove/${product_id}/${pricing_id}`
    );
    if (response) {
      await getCarts();
      notification.success({
        message: response?.data?.message,
      });
    }
    return response.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const updateCartShippingAddress = async (id) => {
  try {
    const response = await privateAPI.put(
      `/cart/update-shipping-address?shipping_address_id=${id}`
    );
    if (response) {
      await getCarts();
      notification.success({
        message:
          response?.data?.message || "Shipping address updated successfully",
      });
    }
    return response?.data?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};
