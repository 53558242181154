import React, { useState } from "react";
// import Button from "../../Button";
import { Button, Select, Spin, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { getToken, isUserAuthenticated } from "../../../utils/index";
import { SelectInput } from "../../index";

const ProductDescription = ({
  product,
  category,
  selectedVariant,
  setSelectedVariant,
  quantity,
  cartBtnLoading,
  incrementQuantity,
  decrementQuantity,
  onLoginRegister,
  onStartOrder,
  onSchedule,
  onAddToCart,
  handleFavouriteToggle,
  isFavorite,
  favBtnLoading,
}) => {
  const [activeButton, setActiveButton] = useState(""); // State to track the active button
  const { user } = useSelector((state) => state.user);
  const isAuthenticated = isUserAuthenticated(user);

  // Function to handle button click and set the active state
  const handleButtonClick = (buttonName, callback) => {
    setActiveButton(buttonName);
    if (callback) callback();
  };
  return (
    <div className="product_discription">
      <div className="product-title-parent">
        <h2 className="product_title">{product?.name}</h2>
        {isAuthenticated &&
          (favBtnLoading ? (
            <div className="fav-action-loading">
              <Spin />
            </div>
          ) : (
            <div className="favorite_icon" onClick={handleFavouriteToggle}>
              <Tooltip
                title={
                  isFavorite ? "Remove from Favorites" : "Add to Favorites"
                }
              >
                {isFavorite ? (
                  <HeartFilled
                    style={{
                      color: "black",
                      fontSize: "24px",
                      verticalAlign: "middle",
                    }}
                  />
                ) : (
                  <HeartOutlined
                    style={{
                      color: "gray",
                      fontSize: "24px",
                      verticalAlign: "middle",
                    }}
                  />
                )}
              </Tooltip>
            </div>
          ))}
      </div>
      <div className="product_limitedAddition">
        <p className="product_sold">
          {product?.total_sold} Sold{" | "}
          {selectedVariant?.stock_quantity === 0 ? (
            <span className="stockTag outOfStock">Out of Stock</span>
          ) : selectedVariant?.stock_quantity <= 20 ? (
            <span className="stockTag limited_stock">Limited Stock</span>
          ) : (
            <span className="stockTag inStock">In Stock</span>
          )}
        </p>

        {/* Categories */}
        <p className="categories">
          {user ? (
            <>
              {" "}
              <strong className="categories_hash"> #3 Best Seller</strong>{" "}
              <span>in</span>{" "}
            </>
          ) : (
            <strong>Categories: </strong>
          )}

          <a href="#">{product?.category?.name}</a>
        </p>
      </div>

      {/* Price */}
      {user && (
        <div className="product_price">
          <h1>${selectedVariant?.normal_price}</h1>
        </div>
      )}

      {/* Weight Variants */}
      <div className="weight_varient">
        <h3>
          weight variant <span style={{ color: "#F60004" }}>*</span>
        </h3>
        <SelectInput
          defaultValue={selectedVariant?.weight}
          options={[
            { label: selectedVariant?.weight, value: selectedVariant?.weight },
          ]}
        />
        <div className="prefered-variant">
          <h3>
            preferred variant options{" "}
            <span style={{ color: "#F60004" }}>*</span>
          </h3>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
            {product?.pricing?.map((pricingData) => (
              <Button
                key={pricingData?.pricing_id}
                className={`weight_pkt_button ${
                  selectedVariant?.pricing_id === pricingData?.pricing_id &&
                  "active_weight_button"
                }`}
                onClick={() => setSelectedVariant(pricingData)}
              >
                {pricingData?.variant}
                {/* <p className="weight_pkt_button_total">1</p> */}
              </Button>
            ))}
            {/* {weightVariants.map((variant, index) => (
            <Button
              key={index}
              className="weight_pkt_button active_weight_button"
            >
              {variant}
              <p className="weight_pkt_button_total">1</p>
            </Button>
          ))} */}
          </div>
        </div>
      </div>

      {/* Quantity Selector */}
      <div className="quantity_select_section">
        <h5>Quantity</h5>
        <div className="quantity_select">
          <button className="quentity_btn" onClick={decrementQuantity}>
            -
          </button>
          <span style={{ fontSize: "16px" }}>{quantity}</span>
          <button className="quentity_btn" onClick={incrementQuantity}>
            +
          </button>
        </div>
      </div>

      {/* Login/Register Button */}
      {!user && (
        <Button
          className={`login_btn ${
            activeButton === "login" ? "btn_border" : "btn_border"
          }`}
          onClick={() => handleButtonClick("login", onLoginRegister)}
        >
          Login / Register to view price
        </Button>
      )}
      {user && (
        <div className="group_btn">
          <Button
            className="login_btn"
            style={{ background: "var(--primary)", color: "var(--bgWhite)" }}
            onClick={() => handleButtonClick("startOrder", onStartOrder)}
          >
            Schedule Order
          </Button>
          {/* <Button
            className="login_btn"
            onClick={() => handleButtonClick("schedule", onSchedule)}
          >
            Schedule
          </Button> */}
          <Button
            loading={cartBtnLoading}
            disabled={cartBtnLoading}
            className="login_btn"
            onClick={() => handleButtonClick("addToCart", onAddToCart)}
          >
            Add to cart
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProductDescription;
