import { Checkbox, InputNumber, Slider, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFilters } from "../../../store/services/filter";
import { Loader } from "../../index";
import useDebounce from "../../../utils/useDebounce";
import { store } from "../../../store";
import { setAppliedFilters } from "../../../store/slices/filterSlice";

function ProductsFilterBy() {
  const { filters, loading, appliedFilters } = useSelector(
    (state) => state.filters
  );
  const { user } = useSelector((state) => state.user);
  const [appliedDebounceFilters, setAppliedDebounceFilters] = useState({
    min_price: null,
    max_price: null,
    min_rating: null,
    max_rating: null,
  });

  useEffect(() => {
    if (Object.values(appliedDebounceFilters)?.some((value) => value)) {
      debouncedFilterChange();
    }
  }, [appliedDebounceFilters]);

  const debouncedFilterChange = useDebounce(() => {
    store.dispatch(
      setAppliedFilters({
        ...appliedFilters,
        ...appliedDebounceFilters,
      })
    );
  }, 500);

  const handleFilterChange = (event, filter, value) => {
    switch (filter) {
      case "price_range":
        setAppliedDebounceFilters({
          ...appliedDebounceFilters,
          min_price: value[0] || null,
          max_price: value?.[1] || null,
        });
        break;
      case "rating_range":
        setAppliedDebounceFilters({
          ...appliedDebounceFilters,
          min_rating: value[0] || null,
          max_rating: value?.[1] || null,
        });
        break;
      case "origins":
        store.dispatch(
          setAppliedFilters({
            ...appliedFilters,
            origin: event.target.checked ? value : null,
          })
        );
        break;
      case "organic_options":
        store.dispatch(
          setAppliedFilters({
            ...appliedFilters,
            organic: event.target.checked ? value : null,
          })
        );
        break;
      case "categories":
        store.dispatch(
          setAppliedFilters({
            ...appliedFilters,
            sub_categories: event.target.checked ? [value?.id] : null,
          })
        );
        break;
      case "packaging_types":
        store.dispatch(
          setAppliedFilters({
            ...appliedFilters,
            packaging: event.target.checked ? value : null,
          })
        );
        break;
      default:
        break;
    }
  };

  const isChecked = (filterName, status) => {
    switch (filterName) {
      case "origins":
        return appliedFilters?.origin === status;
      case "organic_options":
        return appliedFilters?.organic && appliedFilters?.organic !== null;
      case "categories":
        return appliedFilters?.sub_categories?.[0] === status?.id;
      case "packaging_types":
        return appliedFilters?.packaging === status;
      default:
        return false;
    }
  };

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <div className="filterBy-main">
      <h5>Filter By</h5>
      <div className="filters-parent">
        {loading ? (
          <Loader />
        ) : (
          filters &&
          Object.keys(filters).map((filter) => {
            if (
              (filter === "price_range" && user && user?.uid) ||
              filter === "rating_range"
            ) {
              return (
                <div className="price-filter-main" key={filter}>
                  <h6 className="filter-title">
                    {filter
                      .split("_")
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      .join(" ")}
                    :
                  </h6>
                  {filter === "price_range" && (
                    <div className="price-inputs-parent">
                      <div>
                        <label>Min Price</label>
                        <InputNumber
                          min={filters[filter][0]}
                          max={filters[filter][1]}
                          value={
                            appliedDebounceFilters?.min_price ||
                            filters[filter][0]
                          }
                          formatter={(value) => `$${value}`}
                          className="price-input"
                          onChange={(value) =>
                            handleFilterChange(null, filter, [value, null])
                          }
                        />
                      </div>
                      <div>
                        <label>Max Price</label>
                        <InputNumber
                          min={filters[filter][0]}
                          max={filters[filter][1]}
                          value={
                            appliedDebounceFilters?.max_price ||
                            filters[filter][1]
                          }
                          formatter={(value) => `$${value}`}
                          className="price-input"
                          onChange={(value) =>
                            handleFilterChange(null, filter, [null, value])
                          }
                        />
                      </div>
                    </div>
                  )}
                  <Slider
                    range
                    min={filters[filter][0]}
                    max={filters[filter][1]}
                    value={[
                      filter === "price_range"
                        ? appliedDebounceFilters.min_price || filters[filter][0]
                        : appliedDebounceFilters?.min_rating ||
                          filters[filter][0],
                      filter === "price_range"
                        ? appliedDebounceFilters.max_price || filters[filter][1]
                        : appliedDebounceFilters?.max_rating ||
                          filters[filter][1],
                    ]}
                    className="price-range-picker"
                    onChange={(value) =>
                      handleFilterChange(
                        null,
                        filter,
                        value[0] === filters[filter][0]
                          ? [null, value[1]]
                          : value[1] === filters[filter][1]
                          ? [value[0], null]
                          : value
                      )
                    }
                  />
                </div>
              );
            } else if (
              filter === "origins" ||
              filter === "categories" ||
              filter === "organic_options" ||
              filter === "packaging_types"
            ) {
              return (
                <div className="single-filter-parent" key={filter}>
                  <h6 className="filter-title">
                    {filter
                      .split("_")
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      .join(" ")}
                    :
                  </h6>
                  {filters[filter].map((status) => (
                    <Checkbox
                      checked={isChecked(filter, status)}
                      key={String(status)}
                      onChange={(event) =>
                        handleFilterChange(event, filter, status)
                      }
                    >
                      {filter === "categories" ? status?.name : String(status)}
                    </Checkbox>
                  ))}
                </div>
              );
            }
          })
        )}
      </div>
    </div>
  );
}

export default ProductsFilterBy;
