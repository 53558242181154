import React from "react";
import { SimpleHeader } from "../../../../../components";
import { Button } from "antd";
import { useNavigate, useLocation } from "react-router";
import route from "../../../../../config/route";

function OrderSuccessFull() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleVerifyAccount = () => {
    //   navigate(route.PersonalInformation);
    console.log("Go to homepage clicked");
  };

  const handleModify = () => {
    console.log("Modify clicked");
    // Add navigation or logic for modifying order here
  };

  return (
    <div>
      <SimpleHeader />
      <div className="recurringOrderOption">
        <div className="welcomeBackContainer">
          <h2 className="authHeading">Order Successfull</h2>
          <p className="welcomeText">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum
            dolor sit
          </p>

          <Button className="pi55" type="primary" onClick={handleVerifyAccount}>
            Go to homepage
          </Button>
          <div className="additionalButtons">
            <Button className="modifyButton" onClick={handleModify}>
              Continue Shopping
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccessFull;
