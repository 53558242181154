import { Modal, Spin, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../Button";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import route from "../../config/route";
import { useNavigate } from "react-router";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import {
  addToWishlist,
  getWishlist,
  removeToWishlist,
} from "../../store/services/wishlist";
import { isUserAuthenticated } from "../../utils/index";

function ProductCard({
  productData = {},
  title = "",
  discription = "",
  weight = "",
  category = "",
  stock = 0,
  className = "",
  price,
  btn1,
  btn2,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [favouriteLoading, setFavouriteLoading] = useState(false);
  const { wishlist } = useSelector((state) => state.wishlist);
  const [isFavorite, setIsFavorite] = useState(false);
  const isAuthenticated = isUserAuthenticated(user);

  const addToFavourite = async (event) => {
    event?.stopPropagation();
    try {
      setFavouriteLoading(true);
      await addToWishlist(productData?.product_id);
    } catch (error) {
      console.log(error);
    } finally {
      setFavouriteLoading(false);
    }
  };

  const removeToFavourite = async (event) => {
    event?.stopPropagation();
    try {
      setFavouriteLoading(true);
      await removeToWishlist(productData?.product_id);
    } catch (error) {
      console.log(error);
    } finally {
      setFavouriteLoading(false);
    }
  };

  const handleViewDetails = (event) => {
    event?.stopPropagation();
    navigate(`/product-details/${productData?.product_id}`);
  };

  useEffect(() => {
    if (
      wishlist?.length > 0 &&
      wishlist.some(
        (wishlistProduct) =>
          wishlistProduct?.product_id === productData?.product_id
      )
    ) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [wishlist, productData]);

  return (
    <>
      <div
        className={`product-card ${className} ${
          isAuthenticated ? "border-show" : ""
        }`}
      >
        <div className="product-img-parent">
          <img
            src={
              productData?.images?.find((imageData) => imageData?.is_primary)
                ?.image_url || productData?.images?.[0]?.image_url
            }
            className="product-img"
          />

          {/* Heart Icon */}
          {isAuthenticated ? (
            favouriteLoading ? (
              <div className="fav-action-loading">
                <Spin />
              </div>
            ) : (
              <Tooltip
                title={
                  isFavorite ? "Remove from Favorites" : "Add to Favorites"
                }
              >
                {isFavorite ? (
                  <div
                    className="favProduct-heart_icon visible-icon"
                    onClick={(event) => removeToFavourite(event)}
                  >
                    <HeartFilled className="icn" />
                  </div>
                ) : (
                  <span
                    className="favProduct-heart_icon"
                    onClick={(event) => addToFavourite(event)}
                  >
                    <FaRegHeart className="icn" />
                  </span>
                )}
              </Tooltip>
            )
          ) : null}
        </div>
        <div className="product-info-parent">
          <h4 className="product-name">{title}</h4>
          <p className="product-discription">{discription}</p>
          <div className="product-weight_categoryText-parent">
            <p>{weight}</p>
            <p>{productData?.category?.name}</p>
            {stock === 0 ? (
              <Tag className="stockTag outOfStockTag">Out of Stock</Tag>
            ) : stock <= 20 ? (
              <Tag className="stockTag limitedStockTag">Limited Stock</Tag>
            ) : (
              <Tag className="stockTag instockTag">In Stock</Tag>
            )}
          </div>
        </div>
        {isAuthenticated && (
          <h1 className="product-price">
            ${productData?.pricing?.[0]?.normal_price || ""}
          </h1>
        )}
        {isAuthenticated && btn1 && btn2 && (
          <div className="group-btns">
            <Button className="add-to-cart-btn">{btn1}</Button>
            <Button className="buy-now-btn">{btn2}</Button>
          </div>
        )}
        <div
          className="detail-login-buttons-parent"
          style={{
            bottom: isAuthenticated ? "-50px" : "-60px",
          }}
        >
          {!isAuthenticated ? (
            <Button
              className="login-btn"
              onClick={(e) => {
                e.stopPropagation();
                setIsModalVisible(true);
              }}
            >
              Log in or Sign up to View Price
            </Button>
          ) : (
            <Button
              className="view-details-btn"
              onClick={(event) => handleViewDetails(event)}
            >
              View Details
            </Button>
          )}
        </div>
        {/* MODAL  */}
      </div>
      <Modal
        title={""}
        footer={null}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="verify-modal-container">
          <div className="verify-modal-content">
            <h2 className="heading">Verify to see Price</h2>
            <p className="sometext">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer
            </p>

            <Button
              className="verify_btn"
              type="primary"
              onClick={() => navigate(route.verificationProcesses)}
            >
              Start Verification Process
            </Button>
            <div className="additionalButtons">
              <Button
                className="verify_btn_nobg"
                onClick={() => setIsModalVisible(false)}
              >
                Do this later
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductCard;
