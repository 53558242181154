import {
  AutoComplete,
  Badge,
  Button,
  Divider,
  Dropdown,
  Input,
  Select,
  Space,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { CiSearch, CiShoppingCart, CiUser } from "react-icons/ci";
import snail from "../../assets/images/tempProduct01.png";
import route from "../../config/route";
import { useNavigate, useSearchParams } from "react-router";
import { useSelector } from "react-redux";
import { logout } from "../../store/services/user";
import { getCategories, getSubCategories } from "../../store/services/category";
import useDebounce from "../../utils/useDebounce";
import {
  getProducts,
  getProductSuggestions,
} from "../../store/services/product";
import { IoIosArrowDown, IoIosNotificationsOutline } from "react-icons/io";
import { getCarts } from "../../store/services/cart";
import Loader from "../Loader";
import {
  isUserAuthenticated,
  isUserEmailVerified,
  isUserPhoneVerified,
} from "../../utils/index";
import { store } from "../../store";
import { setAppliedFilters } from "../../store/slices/filterSlice";
import { getWishlist } from "../../store/services/wishlist";

const Header = () => {
  const navigate = useNavigate();
  const [shopingCard, setShopingCard] = useState(false);
  const { categories } = useSelector((state) => state.category);
  const { user, loading } = useSelector((state) => state.user);
  // const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const { subCategories, subCategoriesLoading } = useSelector(
    (state) => state.category
  );
  const { cartData, loading: cartLoading } = useSelector((state) => state.cart);
  const carts = cartData?.items;
  const [suggestions, setSuggestions] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const isAuthenticated = isUserAuthenticated(user);
  const isUserVerified = isUserEmailVerified(user) && isUserPhoneVerified(user);
  const { appliedFilters } = useSelector((state) => state.filters);

  const signout = async () => {
    try {
      const response = await logout();
      if (response) {
        navigate(route.signin);
      }
    } catch (error) {}
  };

  useEffect(() => {
    isAuthenticated && getWishlist()
    isAuthenticated && getCarts();
  }, [isAuthenticated]);

  useEffect(() => {
    getCategories();
  }, []);

  const getSuggestions = async () => {
    try {
      setSuggestionsLoading(true);
      const response = await getProductSuggestions(debouncedSearch);
      if (response) {
        setSuggestions(response);
      }
    } catch (error) {
    } finally {
      setSuggestionsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (selectedCategory) {
  //     getSubCategories(selectedCategory);
  //   }
  // }, [selectedCategory]);

  useEffect(() => {
    if (debouncedSearch?.trim()) {
      getSuggestions();
    }
  }, [debouncedSearch]);

  // useEffect(() => {
  //   categories?.length > 0 && setSelectedCategory(categories[0]?.category_id);
  // }, [categories]);

  const handleCategoryChange = (categoryId) => {
    navigate(`/category/${categoryId}`)
    // setSelectedSubCategory(undefined);
    // setSelectedCategory(categoryId);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  useEffect(() => {
    if (search?.trim()) {
      debouncedFunction(search);
    }
  }, [search]);

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearch(searchParams.get("search"));
    }
  }, [searchParams]);

  const debouncedFunction = useDebounce((value, key) => {
    setDebouncedSearch(value);
  }, 1000);

  const handleSearch = (value) => {
    setSearch(value);
    if (!value) {
      setSuggestions([]);
      if (searchParams.get("search")) {
        searchParams.delete("search");
        setSearchParams(searchParams);
        getProducts();
      }
    }
  };

  // useEffect(() => {
  //   if (selectedCategory || selectedSubCategory) {
  //     store.dispatch(
  //       setAppliedFilters({
  //         ...appliedFilters,
  //         category_id: selectedCategory,
  //         sub_categories: selectedSubCategory
  //           ? [selectedSubCategory]
  //           : undefined,
  //       })
  //     );
  //   }
  // }, [selectedCategory, selectedSubCategory]);

  return (
    <>
    <div className="header-main">
      <div className="headr-row1">
        <img
          alt="Logo"
          src={require("../../assets/images/logo.jpeg")}
          className="logo-img"
          onClick={() =>
            navigate(isAuthenticated ? route.exploreHome : route.home)
          }
        />

        <Space.Compact className="search-input-parent">
          <Select
            className="search-dropdown"
            popupClassName="common-dropdown"
            defaultValue="all"
            options={[
              {
                value: "all",
                label: "All",
              },
            ]}
          />
          {/* <Input placeholder="Search frozen items" className="search-field" /> */}
          <AutoComplete
            className="search-field"
            popupClassName="header-product-search-dropdown"
            value={search}
            onSelect={(id) => navigate(`/product-details/${id}`)}
            options={
              suggestions && suggestions.length > 0
                ? suggestions?.map(({ name, product_id }) => {
                    return {
                      value: product_id,
                      label: (
                        <div className="search-dropdown-item">
                          <CiSearch size={20} />
                          <p>{name}</p>
                        </div>
                      ),
                    };
                  })
                : []
            }
            notFoundContent={
              suggestionsLoading && (
                <div className="dflex-center">
                  <Spin />
                </div>
              )
            }
            onSearch={handleSearch}
            placeholder="Search frozen items"
            style={{ width: "100%" }}
          />
          <Button
            type="primary"
            className="search-butn"
            onClick={() =>
              search?.trim() &&
              navigate(`${route.exploreHome}?search=${debouncedSearch}`)
            }
          >
            <CiSearch size={30} />
            Search
          </Button>
        </Space.Compact>
        <div className="btns-parent">
          {/* {isAuthenticated && (
            <Button className="btn">
              <IoIosNotificationsOutline />
              <span>
                <Badge
                  showZero
                  count={0}
                  className="cart-itemsCount-badge"
                ></Badge>
                <p className="text">Notification</p>
              </span>
            </Button>
          )} */}

          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <div className="dropdown-content-parent">
                      {!loading && (
                        <>
                          {isAuthenticated ? (
                            <div className="user-name-label-parent">
                              <h2>Hello {user?.username}</h2>
                              {isUserVerified ? (
                                <h6 className="userStatus user-verified-status">
                                  Verified
                                </h6>
                              ) : (
                                <h6 className="userStatus user-notverified-status">
                                  Not Verified
                                </h6>
                              )}
                            </div>
                          ) : (
                            <div className="btn-parent unauthorized-dropdown-btns">
                              <Button onClick={() => navigate(route.signin)}>
                                Sign in
                              </Button>
                              <Button
                                onClick={() => navigate(route.createAccount)}
                              >
                                Register
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                      {isAuthenticated && (
                        <>
                          <div className="nav-items">
                            <div
                              onClick={() =>
                                handleNavigate(route.accountSettings)
                              }
                            >
                              Profile Management
                            </div>
                            <div
                              onClick={() =>
                                handleNavigate(route.addToFavorites)
                              }
                            >
                              Wishlist
                            </div>
                            <div onClick={() => handleNavigate(route.cards)}>
                              Cards
                            </div>
                            <div onClick={() => handleNavigate(route.orders)}>
                              Orders
                            </div>
                          </div>
                          <div className="nav-items">
                            <div>Settings</div>
                            <div>Help Center</div>
                          </div>
                          <div className="btn-parent">
                            <Button onClick={signout}>Sign Out</Button>
                          </div>
                        </>
                      )}
                    </div>
                  ),
                },
              ],
            }}
            trigger={["click"]}
            arrow
            placement="topRight"
            className="home-dropdown"
            overlayClassName="home-dropdown-overlay"
          >
            {isAuthenticated ? (
              <Button className="btn">
                <CiUser />
                <span className="text">
                  {user?.username}
                  <p>My Account</p>
                </span>
              </Button>
            ) : (
              <Button className="btn">
                <CiUser />
                <span className="text">
                  welcome
                  <p>Sign in</p>
                </span>
              </Button>
            )}
          </Dropdown>

          {isAuthenticated && (
            <div className="cart-parent">
              <Button
                className="btn"
                onClick={() => setShopingCard(!shopingCard)}
              >
                <CiShoppingCart />
                <span>
                  <Badge
                    showZero
                    count={carts ? carts?.length : 0}
                    className="cart-itemsCount-badge"
                  ></Badge>
                  <p className="text">Cart</p>
                </span>
              </Button>
              {shopingCard && (
                <div
                  className={`shopping-card ${
                    shopingCard ? "show-card" : "hide-card"
                  }`}
                >
                  <h4>Shopping cart, {carts ? carts?.length : 0} items</h4>
                  <div className="shopping-card-body">
                    {cartLoading ? (
                      <Loader />
                    ) : (
                      carts &&
                      carts?.length > 0 &&
                      carts?.map(
                        ({ product_id, name, variants, image_url }) => {
                          return (
                            <>
                              <div key={product_id} className="cart-parent">
                                <div className="cart-image">
                                  <img src={image_url} width={120} alt="" />
                                </div>
                                <div className="cart-details">
                                  <p>{name}</p>
                                  {variants?.map(
                                    ({
                                      pricing_id,
                                      weight,
                                      price,
                                      quantity,
                                    }) => {
                                      return (
                                        <div
                                          className="cart-list"
                                          key={pricing_id}
                                        >
                                          <img
                                            src={image_url}
                                            alt=""
                                            width={40}
                                          />
                                          <div className="cart-list-details">
                                            <p>Weight: {weight}</p>
                                            <p>
                                              <span>{price}</span> x{quantity}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                              <Divider className="divider" />
                            </>
                          );
                        }
                      )
                    )}
                  </div>

                  <div className="cart-footer">
                    <Button
                      className="cart-btn"
                      onClick={() => navigate(route.shoppingCart)}
                    >
                      Go to cart
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="headr-row2">
        <Dropdown
          className="category-dropdown"
          // onChange={(categoryId) => handleCategoryChange(categoryId)}
          rootClassName="category-menu-popup"
          menu={{
            triggerSubMenuAction: "click",
            items:
              categories && categories?.length > 0
                ? categories?.map(({ name, category_id }, index) => {
                    return {
                      key: category_id,
                      value: category_id,
                      label: (
                        <p onClick={() => handleCategoryChange(category_id)}>
                          {name}
                        </p>
                      ),
                      // children: subCategoriesLoading
                      //   ? [
                      //       {
                      //         key: "loading",
                      //         value: "loading",
                      //         label: (
                      //           <div className="dflex-center">
                      //             <Spin />
                      //           </div>
                      //         ),
                      //       },
                      //     ]
                      //   : subCategories?.length > 0
                      //   ? subCategories?.map((subCategory) => {
                      //       return {
                      //         key: subCategory?.category_id,
                      //         value: subCategory?.category_id,
                      //         label: (
                      //           <p
                      //             // onClick={() =>
                      //             //   setSelectedSubCategory(
                      //             //     subCategory?.category_id
                      //             //   )
                      //             // }
                      //           >
                      //             {subCategory?.name}
                      //           </p>
                      //         ),
                      //       };
                      //     })
                      //   : [],
                    };
                  })
                : [],
          }}
        >
          <Button className="category-dropdown-trigger">
            All Products
          </Button>
        </Dropdown>
        <ul className="category-list">
          {categories?.slice(0, 4)?.map(({ name, category_id }) => (
            <li
              className="category-list-item"
              onClick={() => navigate(`/category/${category_id}`)}
            >
              {name}
            </li>
          ))}
        </ul>
      </div>
    </div>
    <div className="header-height-spacing"></div>
    </>
  );
};

export default Header;
