import React from "react";
import ModalWrapper from "../../ModalWrapper";
import { Button } from "antd";

function ReviewPublishedModal({
  reviewPublishedModalVisible,
  setReviewPublishedModalVisible,
}) {
  return (
    <ModalWrapper
      open={reviewPublishedModalVisible}
      setOpen={setReviewPublishedModalVisible}
      title={false}
      size="500"
    >
      <div className="review-published-modal">
        <h5>Thank You!</h5>
        <p>Your review has now been published and visible for others to see.</p>
        <Button
          className="btn"
          onClick={() => setReviewPublishedModalVisible(false)}
        >
          Continue
        </Button>
      </div>
    </ModalWrapper>
  );
}

export default ReviewPublishedModal;
