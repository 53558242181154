import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  countries: null,
  states: null,
  cities: null,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setCountries: (state, action) => {
      state.countries = action?.payload;
    },
    setStates: (state, action) => {
      state.states = action?.payload;
    },
    setCities: (state, action) => {
      state.cities = action?.payload;
    },
  },
});

export const { setLoading, setCountries, setStates, setCities } =
  locationSlice.actions;

export default locationSlice.reducer;
