import React from "react";
import Button from "../../Button";
import { Divider, Drawer, Switch } from "antd";
import { useNavigate } from "react-router";

// DrawerHeader Component
const DrawerHeader = () => (
  <div className="drawer_pricing">
    <p>Price before shipping</p>
    <h1>$1500</h1>
  </div>
);

// VariantList Component
const VariantList = ({ quantity, incrementQuantity, decrementQuantity }) => (
  <div className="drawer_weight_varient">
    <h2>Select Weight Variant (5kg per unit)</h2>
    {[...Array(3)].map((_, index) => (
      <div className="varient_list" key={index}>
        <p>Price before shipping</p>
        <h3>$1500</h3>
        <div className="quantity_select_section">
          <div className="quantity_select">
            <button className="quentity_btn" onClick={decrementQuantity}>
              -
            </button>
            <span style={{ fontSize: "16px" }}>{quantity}</span>
            <button className="quentity_btn" onClick={incrementQuantity}>
              +
            </button>
          </div>
        </div>
      </div>
    ))}
  </div>
);

// ScheduleDelivery Component
const ScheduleDelivery = ({ onChange }) => (
  <div className="drawer_schedule">
    <div className="schedule_switch">
      <p>Schedule Delivery</p>
      <Switch defaultChecked onChange={onChange} />
    </div>
    <div className="schedule_btn_group">
      <Button className="weight_pkt_button schedule_btn">Weekly</Button>
      <Button className="weight_pkt_button">Bi-Weekly</Button>
      <Button className="weight_pkt_button">Monthly</Button>
    </div>
  </div>
);

// ShippingDetails Component
const ShippingDetails = () => (
  <div className="drawer_shipping">
    <h3>Shipping</h3>
    <p>Shipping is not calculated yet</p>
  </div>
);

//  DeliveryType component
const DeliveryType = () => (
  <div className="delivery_type_modal">
    <h3>Delivery Type</h3>
    <div className="delivery_type_btn">
      <Button className="weight_pkt_button">One Time</Button>
      <Button className="weight_pkt_button">Reoccurring</Button>
    </div>
  </div>
)


// DrawerFooter Component
const DrawerFooter = ({ navigate }) => (
  <div className="drawer_footer">
    <div className="shipping_fee p_b">
      <p className="item">Item subtotal</p>
      <p className="price">USD 23.40</p>
    </div>
    <div className="shipping_fee">
      <p className="item">Shipping Fee</p>
      <p className="price">USD 23.40</p>
    </div>
    <Divider />
    <div className="shipping_fee">
      <p className="item">Subtotal</p>
      <p className="price">USD 23.40</p>
    </div>
    <Button
      className="login_btn btn_border"
      onClick={() => navigate("/recurring-order-option")}
    >
      Proceed to Pay
    </Button>
  </div>
);

// CardModal Component
const CardModal = ({
  isModalOpen,
  setIsModalOpen,
  quantity,
  incrementQuantity,
  decrementQuantity,
  onChange,
}) => {
  const navigate = useNavigate();
  return (
    <Drawer
      title="Cart"
      onClose={() => setIsModalOpen(false)}
      open={isModalOpen}
    >
      <div className="drawer_main">
        <DrawerHeader />
        <VariantList
          quantity={quantity}
          incrementQuantity={incrementQuantity}
          decrementQuantity={decrementQuantity}
        />
        <ScheduleDelivery onChange={onChange} />
        <DeliveryType />
        <ShippingDetails />
      </div>
      <DrawerFooter navigate={navigate} />
    </Drawer>
  );
};

export default CardModal;
