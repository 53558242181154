import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "antd";

import { GoArrowLeft } from "react-icons/go";
import { BsUpload } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import {
  updateUserProfile,
  uploadUserProfileImg,
} from "../../../../store/services/user";
import { SelectInput, Input } from "../../../index";

function Profile({ isEditing, onEditClick }) {

  return (
    <div className="profileBody">
      <div className="profileHeader">
        {isEditing && (
          <span onClick={onEditClick}>
            <GoArrowLeft />
          </span>
        )}
        <p>Account Setting</p>
      </div>
      <div className="profileContent">
        {isEditing ? (
          <Edit onEditClick={onEditClick} />
        ) : (
          <View onEditClick={onEditClick} />
        )}
      </div>
    </div>
  );
}

const View = ({ onEditClick }) => {
  const { profile } = useSelector((state) => state.user);

  return (
    <div className="viewBox">
      <div className="viewHeader">
        <div
          className="profileImage"
          style={{
            backgroundImage: `url(${
              profile?.profile_image
                ? profile?.profile_image
                : require("../../../../assets/images/user.jpg")
            })`,
          }}
        />
        <div className="viewHeaderRight">
          <p className="nameText">{profile?.contact_name}</p>
          <p className="addressText">{profile?.business_address}</p>
        </div>
      </div>
      <div className="ViewProfileDetail">
        <div className="row">
          <p>
            Email: <span>{profile?.email}</span>
          </p>
        </div>
        <div className="row">
          <p>
            Reg Number: <span>{profile?.registration_number}</span>
          </p>
        </div>
        <div className="row">
          <p>
            Business Address: <span>{profile?.business_address}</span>
          </p>
        </div>
        <div className="row">
          <p>
            Business Name: <span>{profile?.business_name}</span>
          </p>
        </div>
        <div className="row">
          <p>
            Business Category: <span>{profile?.business_category}</span>
          </p>
        </div>
        <div className="row">
          <p>
            Phone: <span>{profile?.phone_number}</span>
          </p>
        </div>
      </div>
      <div className="footer">
        <Button onClick={onEditClick}>Edit Account</Button>
      </div>
    </div>
  );
};

const Edit = ({onEditClick}) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [phone, setPhone] = useState("");
  const { profile } = useSelector((state) => state.user);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    category: "",
    categoryName: "",
  });

  const onFinish = async (values) => {
    try {
      setBtnLoading(true);
      const payload = {
        contact_name: values?.name,
        business_name: values?.businessName,
        business_address: values?.businessAddress,
        phone_number: phone?.charAt(0) === "+" ? `${phone}` : `+${phone}`,
        registration_number: values?.registrationNumber,
        business_category: selectedCategory.category === "other"
        ? selectedCategory?.categoryName
        : selectedCategory?.category,
        profile_image: file,
      };
     const response = await updateUserProfile(payload);
      if (response) {
        onEditClick()
      }
    } catch (error) {
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUploadProfileImg = async (file_) => {
    try {
      const formData = new FormData();
      if (file_) {
        formData.append("file", file_);
      }
      const response = await uploadUserProfileImg(formData);
      if (response) {
        setFile(response?.url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPhone(profile?.phone_number);
    setSelectedCategory({
      category: profile?.business_category,
      categoryName: profile?.business_category,
    });
    form.setFieldsValue({
      name: profile?.contact_name,
      address: profile?.businessAddress,
      email: profile?.email,
      registrationNumber: profile?.registration_number,
      businessAddress: profile?.business_address,
      businessName: profile?.business_name,
      category: profile?.business_category,
      categoryName: "",
      phone: profile?.phone_number,
    });
  }, [profile]);

  return (
    <div className="editBox">
      <div className="uploadBox">
        <div className="wrapper">
          <input
            type="file"
            id="upload"
            hidden
            onChange={(e) => handleUploadProfileImg(e.target.files[0])}
          />
          <label
            htmlFor="upload"
            style={{
              backgroundImage: `url(${
                file ||
                profile?.profile_image ||
                require("../../../../assets/images/user.jpg")
              })`,
            }}
          >
            <span>
              <BsUpload />
              <p>Upload Image</p>
            </span>
          </label>
        </div>
      </div>
      <Form
        form={form}
        name="profileForm"
        style={{
          width: "100%",
        }}
        onFinish={onFinish}
        autoComplete="off"
        className="profileForm"
      >
        <Row gutter={[20, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={null}
              name="businessName"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input label="Business Name" required />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={null}
              name="category"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <SelectInput
                label="Select Category"
                options={[
                  { value: "Catering", label: "Catering" },
                  { value: "Grocery Store", label: "Grocery Store" },
                  { value: "Restaurant", label: "Restaurant" },
                  { value: "Wholesalers", label: "Wholesalers" },
                  { value: "Other", label: "Other" },
                ]}
                required
                value={selectedCategory?.category}
                onChange={(value) =>
                  setSelectedCategory({
                    category: value,
                  })
                }
              />
            </Form.Item>
          </Col>
          {selectedCategory?.category === "Other" && (
            <Col xs={24} lg={12}>
              <Form.Item
                label={null}
                name="categoryName"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input
                  label="Category Name"
                  value={selectedCategory.categoryName}
                  onChange={(event) =>
                    setSelectedCategory({
                      ...selectedCategory,
                      categoryName: event.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} lg={12}>
            <Form.Item
              label={null}
              name="registrationNumber"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input label="Registration Number" required />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={null}
              name="businessAddress"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input label="Business Address" required />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={null}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input label="Contact Name" required />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={null}
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <PhoneInput
                country={"us"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                className={phone ? "phoneInputValid" : ""}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={null}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input label="Email" required />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={null}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                className="pi55"
                type="primary"
                htmlType="submit"
                loading={btnLoading}
                disabled={btnLoading}
              >
                Save Changes
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Profile;
