import React from "react";
import { Button } from "../../index";

function ClaimCatch() {
  return (
    <div className="claimFreshCatch-main-container">
      <div>
        <h5 className="hdng">Claim Your Fresh Catch</h5>
        <p className="discription">
          Discover Oceanic Delights: Dive into a world of premium frozen
          seafood. Our expertly frozen selection guarantees freshness, flavor,
          and convenience. Stock up on quality seafood today!
        </p>
        <Button className="btn">Explore Now</Button>
      </div>
    </div>
  );
}

export default ClaimCatch;
