import { store } from "..";
import { privateAPI } from "../../config/constants";
import {
  setCategories,
  setCategoriesLoading,
  setSubCategories,
  setSubCategoriesLoading,
} from "../slices/categorySlice";

export const getCategories = async () => {
  try {
    store.dispatch(setCategoriesLoading(true));
    const response = await privateAPI.get("/products/main-categories");
    if (response) {
      store.dispatch(setCategories(response?.data));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setCategoriesLoading(false));
  }
};

export const getSubCategories = async (parentCategoryId) => {
  try {
    store.dispatch(setSubCategoriesLoading(true));
    const response = await privateAPI.get(
      `/products/categories/${parentCategoryId}/subcategories`
    );
    if (response) {
      store.dispatch(setSubCategories(response?.data?.subcategories));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  }finally{
    store.dispatch(setSubCategoriesLoading(false));
  }
};
