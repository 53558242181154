import React from "react";
import { IoMdClose } from "react-icons/io";

const _title = "Modal Title";
const _size = 400;

const ModalWrapper = (props) => {
  const { open, setOpen, size = _size, title = _title, children } = props;
  return (
    <div className={`modalWrapper ${open && "showModal"}`}>
      <div
        className="modalBody"
        style={{ width: `min(${size}px, calc(100% - 20px))` }}
      >
        <button className="closeModal" onClick={() => setOpen(false)}>
          <IoMdClose size={20} />
        </button>
        {title && <div className="modalTitle">{title}</div>}
        <div className="modalContent">{children}</div>
      </div>
    </div>
  );
};

export default ModalWrapper;
