import { Button } from "antd";
import {
  Banner,
  Footer,
  Header,
  HeroSection,
  Loader,
  ProductCard,
  ProductsFilterBy,
} from "../../../components";
import { useEffect, useState } from "react";
import { getProducts } from "../../../store/services/product";
import { useSelector } from "react-redux";
import {
  getCategories,
  getSubCategories,
} from "../../../store/services/category";
import { getWishlist } from "../../../store/services/wishlist";
import { IoCloseSharp } from "react-icons/io5";
import { useSearchParams } from "react-router";
import { max } from "moment";
import { getToken, isUserAuthenticated } from "../../../utils/index";
import { store } from "../../../store";
import { setAppliedFilters } from "../../../store/slices/filterSlice";

const ExploreHome = () => {
  const { products, loading } = useSelector((state) => state.product);
  const [productsCount, setProductsCount] = useState(0);
  const { categories, subCategories } = useSelector((state) => state.category);
  const { filters, appliedFilters } = useSelector((state) => state.filters);
  const { user } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const isAuthenticated = isUserAuthenticated(user);
  let [searchParams] = useSearchParams();

  useEffect(() => {
    const searchValue = searchParams.get("search");
    store.dispatch(
      setAppliedFilters({ ...appliedFilters, search: searchValue })
    );
  }, [searchParams]);

  useEffect(() => {
    categories?.length > 0 &&
      store.dispatch(
        setAppliedFilters({
          ...appliedFilters,
          category_id: categories[0]?.category_id,
        })
      );
  }, [categories]);

  useEffect(() => {
    if (appliedFilters?.category_id) {
      getSubCategories(appliedFilters?.category_id);
    }
  }, [appliedFilters]);

  useEffect(() => {
    const validFilters = Object.fromEntries(
      Object.entries(appliedFilters).filter(([_, value]) => value)
    );
    const response = getProducts({
      page: currentPage,
      page_size: 10,
      ...validFilters,
    });
    if (response) {
      setProductsCount(response?.total);
    }
  }, [appliedFilters, currentPage]);

  const removeFilter = (filterName) => {
    const updatedFilters = { ...appliedFilters };
    delete updatedFilters[filterName];
    store.dispatch(setAppliedFilters(updatedFilters));
  };

  const reserFilters = () => {
    store.dispatch(setAppliedFilters({
      category_id: categories[0]?.category_id
    }));
  };

  const filtersApplied = Object.entries(appliedFilters)?.filter(
    ([filterName, v]) =>
      filterName !== "search" && filterName !== "category_id" && v
  );

  const handleSubCategoryClick = (subCategoryId) => {
    store.dispatch(
      setAppliedFilters({
        ...appliedFilters,
        sub_categories: subCategoryId ? [subCategoryId] : null,
      })
    );
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="explore-home-container">
      <Header />
      <Banner
        title="Frostflow: Where Flavor Meets Freshness"
        discription="Sustainably sourced, expertly frozen, and ready for your kitchen."
        showGreetingText={true}
      />
      {subCategories?.length > 0 && (
        <div className="categories-tabs-main-container">
          <ul className="categories-tabs-list">
            <li
              className={`category-tab-item ${
                !appliedFilters?.sub_categories
                  ? "active-category-tab-item"
                  : ""
              }`}
              onClick={() => handleSubCategoryClick(null)}
            >
              All Products
            </li>
            {subCategories?.map((subCategory) => (
              <li
                className={`category-tab-item ${
                  appliedFilters?.sub_categories &&
                  appliedFilters?.sub_categories[0] === subCategory?.category_id
                    ? "active-category-tab-item"
                    : ""
                }`}
                onClick={() => handleSubCategoryClick(subCategory?.category_id)}
              >
                {subCategory?.name}
              </li>
            ))}
          </ul>
        </div>
      )}
      {filtersApplied?.length > 0 && (
        <div className="filters-tag-parent-div">
          <Button onClick={reserFilters} className="reset-button">
            Reset Filters
          </Button>

          {filtersApplied.map(([filterName, value], index) =>
            filterName === "sub_categories" ? (
              <p key={index} className="filter-tag">
                {subCategories.find(
                  ({ category_id }) => category_id === value[0]
                )?.name || ""}
                <IoCloseSharp
                  size={24}
                  style={{ marginLeft: "10px" }}
                  onClick={() => removeFilter(filterName)}
                />
              </p>
            ) : (
              <p key={index} className="filter-tag">
                {value}
                <IoCloseSharp
                  size={24}
                  style={{ marginLeft: "10px" }}
                  onClick={() => removeFilter(filterName)}
                />
              </p>
            )
          )}
        </div>
      )}
      <div
        className="filter-and-products-main-row"
        style={{
          paddingBottom: products?.length < productsCount ? "0px" : "10rem",
        }}
      >
        <ProductsFilterBy />
        <div className="products-parent">
          {loading ? (
            <Loader />
          ) : products?.length > 0 ? (
            products?.map((product) => (
              <ProductCard
                title={product?.name}
                discription={product?.description}
                weight={product?.pricing[0]?.weight}
                category="Seafood"
                stock={product?.pricing[0]?.stock_quantity}
                price={product?.pricing[0]?.price}
                productData={product}
              />
            ))
          ) : (
            <p style={{ textAlign: "center", flex: "1" }}>Coming Soon!</p>
          )}
        </div>
      </div>
      <div className="dflex-center">
        {products?.length < productsCount && (
          <Button
            className="outlined-btn load-more-btn"
            onClick={handleLoadMore}
          >
            Load More
          </Button>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ExploreHome;
