import { Carousel, Col, Row } from "antd";
import React from "react";

function Testimonials() {
  const testimonials = [
    {
      name: "Mr Adeyemi",
      discription:
        "I’m very glad for my snails purchase from you guys, the package is very nice, hygienic and good value for money. Please keep it up.",
    },
    {
      name: "Ryan Menzie",
      discription:
        "The frozen snails from FrostFlow are top-notch! Fresh, perfectly cleaned, and easy to cook— my customers loved them peppered with cold drinks. The packaging is excellent, and the quality is unbeatable. Highly recommend!",
    },
    {
      name: "Aldrich Fowler",
      discription:
      "Estimates are written to our website and delivered to customers. This saves you money by not requiring separate estimation and invoicing software."
    },
  ];
  return (
    <div className="testimonials-main-container">
      <h1 className="hdng">Client Testimonial</h1>
      <Carousel className="testimonials-carousal-main" 
      dots= {false}>
        <div>
          <Row gutter={[30, 0]} justify="center">
            {testimonials?.map(({ name, discription }) => (
              <Col xs={24} sm={12} xl={8}>
                <div className="testimonial">
                  <p className="comment">{discription}</p>
                  <h6 className="name">{name}</h6>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Carousel>
    </div>
  );
}

export default Testimonials;
