import React, { useEffect, useState } from "react";
import { Modal, Form, Button, notification, Col, Row, Checkbox } from "antd";
import visa from "../../../assets/images/visa.png";
import master from "../../../assets/images/master-card.png";
import maerican from "../../../assets/images/american-express.png";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { countryCodes, stripePromise } from "../../../config/constants";
import { useForm } from "antd/es/form/Form";
import {
  addPaymentMethod,
  editPaymentMethod,
} from "../../../store/services/payments";
import Input from "../../Input";
import { useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getStates,
} from "../../../store/services/location";
import SelectInput from "../../SelectInput";
import { addShippingAddress } from "../../../store/services/shipping";

const AddCardModal = ({ isVisible, onClose, isEditMode, selectedCardEdit }) => {
  return (
    <Modal
      className="addCardModal"
      title={
        <div>
          <h2>{"Add a new card"}</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={visa} width={40} alt="Visa" />
            <img src={master} width={30} alt="MasterCard" />
            <img src={maerican} width={40} alt="American Express" />
            <img src={master} width={30} alt="MasterCard" />
          </div>
        </div>
      }
      footer={null}
      open={isVisible}
      onCancel={onClose}
    >
      <Elements stripe={stripePromise}>
        <AddCardform
          onClose={onClose}
          // isEditMode={isEditMode}
          // selectedCardEdit={selectedCardEdit}
        />
      </Elements>
    </Modal>
  );
};

const AddCardform = ({ onClose, isEditMode, selectedCardEdit }) => {
  const { cards } = useSelector((state) => state.payment);
  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [cvcError, setCvcError] = useState("");
  const { user } = useSelector((state) => state.user);
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const {
    loading: dropdownLoading,
    countries,
    states,
    cities,
  } = useSelector((state) => state.location);

  useEffect(() => {
    getCountries();
  }, []);

  const handleCardNumberChange = (event) => {
    setCardNumberError(event.error?.message || "");
  };

  const handleExpiryChange = (event) => {
    setExpiryError(event.error?.message || "");
  };

  const handleCvcChange = (event) => {
    setCvcError(event.error?.message || "");
  };

  const handleCountrySelect = async (country) => {
    try {
      form.setFieldsValue({
        state: null,
        city: null,
      });
      const payload = {
        country,
      };
      await getStates(payload);
    } catch (error) {}
  };

  const handleStateSelect = async (state) => {
    try {
      form?.setFieldValue({
        city: null,
      });
      const payload = {
        country: form.getFieldValue("country"),
        state,
      };
      await getCities(payload);
    } catch (error) {}
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);

      if (!stripe || !elements) {
        setIsSubmitting(false);
        return;
      }

      await form.validateFields();

      const cardElement = elements.getElement(CardNumberElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: values?.name,
          address: {
            line1: values?.address1,
            line2: values?.address2,
            city: values?.city,
            state: values?.state,
            postal_code: values?.postalCode,
            country:
              countryCodes?.find(
                ({ name }) =>
                  name?.trim()?.toLowerCase() ===
                  values?.country?.trim()?.toLowerCase()
              )?.code || null,
          },
        },
      });

      console.log(paymentMethod);

      if (error) {
        notification.error({
          message: error.message,
        });
      } else {
        // if (isEditMode && selectedCardEdit) {
        //   const payload = {
        //     id: paymentMethod?.id,
        //     data: {
        //       card: {
        //         exp_month: paymentMethod.card.exp_month,
        //         exp_year: paymentMethod.card.exp_year,
        //       },
        //     },
        //   }
        //   const response = await editPaymentMethod(payload);
        //   if (response) {
        //     form.resetFields();
        //     onClose();
        //   }
        // } else {
        const response = await addPaymentMethod(paymentMethod?.id);
        if (response) {
          const payload = {
            full_name: values?.name,
            address_line1: values?.address1,
            address_line2: values?.address2 || null,
            city: values?.city,
            state: values?.state,
            postal_code: values?.postalCode,
            country: values?.country,
            phone_number: user?.phone_number,
            email: user?.email,
            is_default: true,
          };
          const res = await addShippingAddress(payload);
          form.resetFields();
          onClose();
        }
        // }
      }
    } catch (error) {}
    setIsSubmitting(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Arial, sans-serif",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#ff4d4f",
        iconColor: "#ff4d4f",
      },
    },
    showIcon: true,
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="addCardForm"
      style={{ width: "100%" }}
      autoComplete="off"
      className="addCardForm"
      onFinish={(values) => handleSubmit(values)}
    >
      <Form.Item
        label="Card Number"
        required
        validateStatus={cardNumberError ? "error" : ""}
        help={cardNumberError}
      >
        <div className={`${cardNumberError ? "stripe-element-error" : ""}`}>
          <CardNumberElement
            className="common-input"
            options={cardElementOptions}
            onChange={handleCardNumberChange}
          />
        </div>
      </Form.Item>
      <Form.Item label="Name on card" name="name">
        <Input label="Name on card" />
      </Form.Item>
      <div style={{ display: "flex", gap: "16px" }}>
        <Form.Item
          label="Expiration Date"
          required
          style={{ flex: 1 }}
          validateStatus={expiryError ? "error" : ""}
          help={expiryError}
        >
          <div className={`${expiryError ? "stripe-element-error" : ""}`}>
            <CardExpiryElement
              className="common-input"
              options={cardElementOptions}
              onChange={handleExpiryChange}
            />
          </div>
        </Form.Item>

        <Form.Item
          label="CVC"
          required
          style={{ flex: 1 }}
          validateStatus={cvcError ? "error" : ""}
          help={cvcError}
        >
          <div className={`${cvcError ? "stripe-element-error" : ""}`}>
            <CardCvcElement
              className="common-input"
              options={cardElementOptions}
              onChange={handleCvcChange}
            />
          </div>
        </Form.Item>
      </div>
      <h2>Billing Address</h2>
      <Row gutter={[20, 0]}>
        <Col span={24}>
          <Form.Item
            label={null}
            name="country"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <SelectInput
              label="Country / region"
              showSearch
              onChange={(value) => handleCountrySelect(value)}
              loading={
                !form.getFieldValue("country") && dropdownLoading ? true : false
              }
              options={
                countries && countries?.length
                  ? countries?.map((country) => {
                      return {
                        value: country,
                        label: country,
                      };
                    })
                  : []
              }
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={null}
            name="address1"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Input label="Street address or P.O. box" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={null} name="address2">
            <Input label="Apartment, suite, unit, building, floor (optional)" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={null}
            name="state"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <SelectInput
              label="State / province"
              disabled={!form.getFieldValue("country")}
              showSearch
              loading={
                !form.getFieldValue("state") && dropdownLoading ? true : false
              }
              onChange={(value) => handleStateSelect(value)}
              options={
                states && states?.length
                  ? states?.map((state) => {
                      return {
                        value: state,
                        label: state,
                      };
                    })
                  : []
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={null}
            name="city"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <SelectInput
              disabled={!form.getFieldValue("state")}
              label="City"
              showSearch
              loading={
                !form.getFieldValue("city") && dropdownLoading ? true : false
              }
              options={
                cities && cities?.length
                  ? cities?.map((city) => {
                      return {
                        value: city,
                        label: city,
                      };
                    })
                  : []
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label={null}
            name="postalCode"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Input label="Postal Code" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={null} name="rememberCard" valuePropName="checked">
            <Checkbox>Remember this card</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: 8 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          disabled={!stripe || isSubmitting}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default AddCardModal;
