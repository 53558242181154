import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import Input from "../../Input";
import PhoneInput from "react-phone-input-2";
import { sendVerificationCode } from "../../../store/services/user";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import route from "../../../config/route";

const VerifyNumberAndEmailModal = (props) => {
  const { open, setOpen, type, handleSendCode, loading, verification } = props;
  const [value, setValue] = useState(undefined);
  const { user } = useSelector((state) => state.user);

  const isEmail = type === "email";

  const handleCancel = () => {
    setOpen(false);
    setValue(undefined);
  };

  useEffect(() => {
    open && setValue(undefined);
  }, [open]);

  // useEffect(() => {
  //   if(verification){
  //     const findVericationInfo = user?.verifications?.find(({verification_id})=>verification_id === verification?.verification_id)
  //     if(findVericationInfo?.is_verified){
  //       Navigate(route.exploreHome)
  //     }
  //   }
  // }, [user])

  useEffect(() => {
    if(user){
      setValue(isEmail ? user?.email: user?.phone_number);
    }
  }, [type, open, user]);

  return (
    <Modal
      centered
      title={false}
      footer={false}
      open={open}
      onCancel={handleCancel}
      className="verifyNumberAndEmailModal"
    >
      <h2 className="heading">
        Verify Your {isEmail ? "Email Address" : "Phone Number"}
      </h2>
      <p className="description">{`Kindly enter your ${
        isEmail ? "email" : "mobile number"
      } to verify`}</p>
      <div className="inputContainer">
        {isEmail ? (
          <Input
            label="Email"
            type="email"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            required
          />
        ) : (
          <PhoneInput
            country={"us"}
            value={value}
            onChange={(phone) => setValue(phone)}
          />
        )}
      </div>
      <div className="footer">
        <Button
          type="primary"
          onClick={() =>
            handleSendCode({ type, value: isEmail ? value : value?.charAt(0) === '+' ? `${value}` : `+${value}` })
          }
          style={{ paddingInline: "24px" }}
          className="footer-btn"
          loading={loading}
          disabled={loading}
        >
          Send Code
        </Button>
        <Button onClick={handleCancel} style={{ paddingInline: "24px" }}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default VerifyNumberAndEmailModal;
