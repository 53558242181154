import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import route from "./route";
import { ProfileManagLayout } from "../components";

const PrivateWrapper = ({ type }) => {
  const isAuthenticated = localStorage.getItem("token");

  if (!isAuthenticated) {
    return <Navigate to={route.signin} />;
  } else {
    return type === "profile" ? (
      <ProfileManagLayout>
        <Outlet />
      </ProfileManagLayout>
    ) : (
      <Outlet />
    );
  }
};

export default PrivateWrapper;
