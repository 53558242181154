import React from "react";

const click = () => {};
const _style = {};
const _class = "";

const Button = (props) => {
  const {
    onClick = click,
    style = _style,
    className = _class,
    children
  } = props;

  const handleWaveEffect = (event) => {
    const button = event.currentTarget;
    const wave = document.createElement("span");
    wave.className = "waveEffect";
    const rect = button.getBoundingClientRect();
    const waveX = event.clientX - rect.left;
    const waveY = event.clientY - rect.top;
    wave.style.left = `${waveX}px`;
    wave.style.top = `${waveY}px`;
    button.appendChild(wave);
    wave.addEventListener("animationend", () => {
      wave.remove();
    });
    onClick(event);
  };

  return (
    <button
      className={`primaryButton ${className}`}
      style={{ ...style }}
      onClick={handleWaveEffect}
    >
      {children}
    </button>
  );
};

export default Button;
