import React from "react";
import {
  Button,
  ProductKeyAttributes,
  SimpleHeader,
} from "../../../../components";
import { Alert, Breadcrumb } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const WireTransferInformation = () => {
  const tableData = [
    {
      label: "Payment currency:",
      value: "USD",
    },
    {
      label: "Beneficiary account number:",
      value: (
        <>
          100001 61624177 <a href="#">View account certificate</a>
        </>
      ),
    },
    {
      label: "SWIFT code:",
      value: "CHASSGSGXXX or CHASSGSG",
    },
    {
      label: "Beneficiary country/region:",
      value: "Singapore",
    },
    {
      label: "Beneficiary name:",
      value: "Xuchang Harmony Hair Products Co., Ltd.",
    },
    {
      label: "Beneficiary address:",
      value: "8 Shenton Way, #45-01, AXA Tower, Singapore 068811",
    },
    {
      label: "Beneficiary bank:",
      value: (
        <>
          JPMorgan Chase Bank N.A., Singapore Branch{" "}
          <img
            src="jpmorgan_logo.png"
            alt="J.P. Morgan"
            style={{ height: "20px", marginLeft: "5px" }}
          />
        </>
      ),
    },
    {
      label: "Beneficiary bank address:",
      value: "168 Robinson Road, Capital Tower 17-00, Singapore 068912",
    },
    {
      label: "Bank code:",
      value: "7153",
    },
    {
      label: "Branch code:",
      value: "001",
    },
    {
      label: "Remark:",
      value: (
        <>
          232807035001023327{" "}
          <span style={{ color: "red", fontWeight: "bold" }}>
            Please attach this order number
          </span>
        </>
      ),
    },
  ];

  return (
    <div>
      <SimpleHeader />
      <div className="wire-transfer-main">
        <Breadcrumb
          separator={<RightOutlined className="breadcrumb-seperator" />}
          items={[
            {
              href: "/trade-assurance-order",
              title: (
                <>
                  <span>Checkout</span>
                </>
              ),
            },
            {
              title: "Wire transfer information",
            },
          ]}
        />
        <div className="wire-transfer-container">
          <div className="order-num">
            <p className="order_num">
              Order No. <span>23423423423423423423 </span>
            </p>
            <p className="order_notice">
              After completing the wire transfer (T/T) payment at the bank or
              online, please notify the supplier to link the receive funds to
              your order
            </p>
          </div>
          <h3>International wire transfer (T/T) account</h3>
          <div className="amount-summary">
            <p className="payment">
              Payment amount: <span>USD 75.07</span>
            </p>
            <p className="process">
              {" "}
              Payment processing fee charged by your bank:{" "}
              <span>Depends on your bank</span>
            </p>
            <p className="time">
              Processing time: <span>3-7 business days</span>
            </p>
            <div className="btn-grounp">
              <Button className="btn">Save as image</Button>
              <Button className="btn">Print</Button>
            </div>
          </div>
          <div className="alert">
            <Alert
              description="To avoid any potential exchange loss, we strongly suggest you paying in USD and require your bank to confirm beneficiary bank's finaly receipt currency in USD ( especially when an intermediary is needed)."
              type="info"
              showIcon
            />
          </div>
          <div class="payment-table">
            <table>
              <tr>
                <th>Payment currency:</th>
                <td>USD</td>
              </tr>
              <tr className="bg-gray">
                <th>Beneficiary account number:</th>
                <td>
                  100001 61624177 <a href="#">View account certificate</a>
                </td>
              </tr>
              <tr>
                <th>SWIFT code:</th>
                <td>CHASSGSGXXX or CHASSGSG</td>
              </tr>
              <tr className="bg-gray">
                <th>Beneficiary country/region:</th>
                <td>Singapore</td>
              </tr>
              <tr>
                <th>Beneficiary name:</th>
                <td>Xuchang Harmony Hair Products Co., Ltd.</td>
              </tr>
              <tr className="bg-gray">
                <th>Beneficiary address:</th>
                <td>8 Shenton Way, #45-01, AXA Tower, Singapore 068811</td>
              </tr>
              <tr>
                <th>Beneficiary bank:</th>
                <td>
                  JPMorgan Chase Bank N.A., Singapore Branch
                 <span className="bold">J.P.Morgan</span>
                </td>
              </tr>
              <tr className="bg-gray">
                <th>Beneficiary bank address:</th>
                <td>
                  168 Robinson Road, Capital Tower 17-00, Singapore 068912
                </td>
              </tr>
              <tr>
                <th>Bank code:</th>
                <td>7153</td>
              </tr>
              <tr className="bg-gray">
                <th>Branch code:</th>
                <td>001</td>
              </tr>
              <tr>
                <th>Remark:</th>
                <td>
                  232807035001023327{" "}
                  <span class="remark">Please attach this order number</span>
                </td>
              </tr>
            </table>
          </div>

          <div className="notice">
            <h3>Notice</h3>
            <div className="pera">
              <p>
                You will ONLY be covered by Trade Assurance when you make a payment to this supplier's account show above.
              </p>
              <p>
                Please pay attension to fill in the correct information for beneficiary account and paying account including the remitter's name and address (include the street address, Building Name, State and Country) as the beneficiary bank will review tha account number to recive the payment. 
              </p>
            </div>
          </div>
        </div>

        <div className="footer-breadcrum">

        <Breadcrumb
          separator={<LeftOutlined className="breadcrumb-seperator" />}
          items={[
            {
              href:'#',
              title: (
                <>
                  <span></span>
                </>
              ),
            },
            {
              href: "/trade-assurance-order",
              title: "Back to checkout",
              className:'f-b-title'
            },
          ]}
        />
        <p >view order details</p>
        </div>
      </div>
    </div>
  );
};

export default WireTransferInformation;
