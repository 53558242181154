import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import route from "./route";
import { AuthLayout } from "../components";

const AuthWrapper = () => {
  const isAuthenticated = localStorage.getItem("token");

  return !isAuthenticated ? (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ) : (
    <Navigate to={route.exploreHome} />
  );
};

export default AuthWrapper;
