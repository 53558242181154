import { notification } from "antd";
import { store } from "..";
import { privateAPI } from "../../config/constants";
import { setLoading, setShippingAddress } from "../slices/shippingSlice";

const handleError = (msg) => {
  notification.error({
    message: msg || "Server Error",
  });
};

export const getShippingAddress = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/shipping_address/shipping-address");
    if (response) {
      store.dispatch(setShippingAddress(response?.data?.data));
    }
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const addShippingAddress = async (data) => {
  try {
    const response = await privateAPI.post(
      "/shipping_address/shipping-address",
      data
    );
    if (response) {
      await getShippingAddress();
      notification.success({
        message:
          response?.data?.message || "Shipping address added successfully",
      });
    }
    return response?.data?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const updateShippingAddress = async ({ id, data }) => {
  try {
    const response = await privateAPI.put(
      `/shipping_address/shipping-address/${id}`,
      data
    );
    if (response) {
      await getShippingAddress();
      notification.success({
        message:
          response?.data?.message || "Shipping address updated successfully",
      });
    }
    return response?.data?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const deleteShippingAddress = async (id) => {
  try {
    const response = await privateAPI.delete(
      `/shipping_address/shipping-address/${id}`
    );
    if (response) {
      await getShippingAddress();
      notification.success({
        message:
          response?.data?.message || "Shipping address deleted successfully",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};
