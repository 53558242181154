import React, { useEffect, useState } from "react";
import SimpleHeader from "../../../../components/SimpleHeader";
import { SlLocationPin } from "react-icons/sl";
import { CiLock, CiDollar } from "react-icons/ci";
import { BsBoxSeam } from "react-icons/bs";
import { GrDeliver } from "react-icons/gr";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import { Button, Checkbox, Collapse, Divider, Form, Modal, Radio } from "antd";
import Input from "../../../../components/Input";
import {
  AddCardModal,
  CardModal,
  EditOrAddShippingAddress,
  Loader,
  ProductCardView,
  SelectInput,
  StripePaymentModal,
} from "../../../../components";
import visa from "../../../../assets/images/visa.png";
import master from "../../../../assets/images/master-card.png";
import maerican from "../../../../assets/images/american-express.png";
import paypal from "../../../../assets/images/pp.png";
import tt from "../../../../assets/images/tt.PNG";
import { useNavigate } from "react-router";
import { MdLockOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { getShippingAddress } from "../../../../store/services/shipping";
import {
  getCarts,
  getSelectedCarts,
  updateCartShippingAddress,
} from "../../../../store/services/cart";
import { checkOut } from "../../../../store/services/payments";

const PaymentMethod = () => {
  const { shippingAddress } = useSelector((state) => state.shipping);
  const { cartData, loading } = useSelector((state) => state.cart);
  const [stripeModalVisible, setStripeModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShippingAddressVisible, setIsShippingAddressVisible] =
    useState(undefined);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [activeKey, setActiveKey] = useState(null);
  const [selectedShipAddressForEdit, setSelectedShipAddressForEdit] =
    useState(null);
  const [quantity, setQuantity] = useState(1);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [btnLoading, setBtnLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const cart = cartData?.items;
  const { checkoutItems } = useSelector((state) => state.payment);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [selectedCartData, setSelectedCartData] = useState(null);

  useEffect(() => {
    getShippingAddress();
    getCarts();
  }, []);

  const onFinish = (values) => {
    console.log("Form Data:", values);
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleChangeCartShippingAddress = async (id) => {
    try {
      await updateCartShippingAddress(id);
    } catch (error) {}
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const incrementQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };
  const handleSwitchChange = (checked) => console.log("Switch state:", checked);

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const generateUpcomingYears = (numberOfYears = 10) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: numberOfYears }, (_, i) => ({
      label: currentYear + i,
      value: currentYear + i,
    }));
  };

  const handleAddShippingClick = (event) => {
    setSelectedShipAddressForEdit(null);
    setIsShippingAddressVisible(event.target.checked);
  };

  const handleEditShippAddressClick = (id) => {
    setSelectedShipAddressForEdit(id);
    setIsShippingAddressVisible(true);
  };

  const handleCheckOut = async () => {
    try {
      setBtnLoading(true);
      // let payload = null;
      // if (checkoutItems.length > 0) {
      //   payload = {
      //     payment_method: paymentMethod,
      //     items: [checkoutItems],
      //   };
      // } else {
      //   payload = {
      //     payment_method: paymentMethod,
      //   };
      // }
      const payload = {
        payment_method: paymentMethod,
        item_ids: checkoutItems?.length > 0 ? checkoutItems : undefined,
      };
      const resposne = await checkOut(payload);
      if (resposne) {
        setClientSecret(resposne?.payment_intent_id);
        setStripeModalVisible(true);
      }
    } catch (error) {
    } finally {
      setBtnLoading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="shipping_collapse_header">
          <p className="title">
            <SlLocationPin size={22} /> Shipping address
          </p>
          {activeKey !== "1" && cartData?.shipping_address_id && (
            <>
              <div className="shipping_info">
                <p>{cartData?.shipping_address?.full_name}</p>
                <p>{cartData?.shipping_address?.address_line1}</p>
                <p>{cartData?.shipping_address?.postal_code}</p>
              </div>
              <Button
                className="shipping_change_btn"
                onClick={() => {
                  setActiveKey("1");
                  handleEditShippAddressClick(cartData?.shipping_address_id);
                }}
              >
                Change
              </Button>
            </>
          )}
        </div>
      ),
      children: (
        <div className="shipping-expand-container">
          <p className="note-text">
            <MdLockOutline />
            Your personal information is encrypted and will only be used for
            delivery purposes.
          </p>
          {/* <Radio.Group
            className="radio-btns-group"
            options={[
              { value: "pickup", label: "Pickup" },
              { value: "delivery", label: "Delivery" },
            ]}
          /> */}
          {!selectedShipAddressForEdit &&
            shippingAddress &&
            shippingAddress?.length > 0 &&
            shippingAddress?.map((address) => (
              <div
                className="shipping-address-content-container"
                key={address?.address_id}
              >
                <div className="shipping-address-parent">
                  <Checkbox
                    className="address-checkbox"
                    checked={
                      address?.address_id === cartData?.shipping_address_id
                    }
                    onChange={() =>
                      handleChangeCartShippingAddress(address?.address_id)
                    }
                  >
                    <div className="address-text-parent">
                      <p>{address?.full_name}</p>
                      <p>{address?.address_line1}</p>
                      <p>
                        {address?.state}, {address?.city},{" "}
                        {address?.postal_code}
                      </p>
                    </div>
                  </Checkbox>
                  <Button
                    checked={selectedShipAddressForEdit ? true : false}
                    className="shipping_change_btn"
                    onClick={() =>
                      handleEditShippAddressClick(address?.address_id)
                    }
                  >
                    Change
                  </Button>
                </div>
              </div>
            ))}
          {!selectedShipAddressForEdit && (
            <Checkbox
              className="add_shipping_address_btn"
              checked={isShippingAddressVisible}
              onChange={(event) => handleAddShippingClick(event)}
            >
              Add New Shipping Address
            </Checkbox>
          )}
          {isShippingAddressVisible && (
            <EditOrAddShippingAddress
              onEditClose={() => {
                setSelectedShipAddressForEdit(null);
                setIsShippingAddressVisible(false);
              }}
              address_id={selectedShipAddressForEdit}
              setAddressId={setSelectedShipAddressForEdit}
            />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="payment_method">
          <CiDollar size={22} className="icons" />
          <p className="text">Payment method</p>
        </div>
      ),
      children: (
        <div className="payment_section">
          {/* <div className="add_card" onClick={() => showModal()}>
            <p>
              {" "}
              <GoPlus /> Add a new card{" "}
            </p>
            <div className="add_card_icon">
              <img src={visa} width={50} alt="card" />
              <img src={master} width={40} alt="card" />
              <img src={maerican} width={50} alt="card" />
              <img src={master} width={40} alt="card" />
            </div>
          </div>
          <div className="select_payment">
            <Radio
              className="radio"
              onChange={() => navigate("/trade-assurance-order")}
            >
              Other payment method
            </Radio>
            <div className="payment_icons">
              <img src={paypal} width={20} alt="card" /> Paypal
              <img src={paypal} width={20} alt="card" /> Pay Later
              <img src={tt} width={50} alt="card" />
            </div>
          </div> */}
          <Radio
            className="radio"
            onChange={() => setPaymentMethod("card")}
            checked={paymentMethod === "card"}
          >
            Card
          </Radio>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="payment_method on-delivery">
          <div>
            <BsBoxSeam className="icons" size={22} />
            <p className="text">Items and delivery options</p>
          </div>
          {/* <div className="delivery_type">
            <p>Delivery type</p>
            <Button className="weight_pkt_button schedule_btn">One-time</Button>
            <Button
              className="weight_pkt_button btn_outline"
              onClick={() => setIsModalOpen1(true)}
            >
              Reoccurring
            </Button>
          </div> */}
        </div>
      ),
      children: (
        <div className="item_delivery_option">
          {/* <div className="delivery_date">
            <p>
              Delivery by Jan 2 |<span> Sipping fee:</span> UCD 51.16
            </p>

            <p className="shipping_change">Change</p>
          </div> */}
          {cart &&
            cart.length > 0 &&
            (checkoutItems && checkoutItems.length > 0
              ? cart.filter(({ product_id }) =>
                  checkoutItems.includes(product_id)
                )
              : cart
            ).map((item) =>
              item?.variants?.map((variant) => (
                <ProductCardView
                  key={variant.id}
                  product={item}
                  variant={variant}
                />
              ))
            )}
        </div>
      ),
    },
  ];

  const handleStripeCloseModal = () => {
    setClientSecret(null);
    setStripeModalVisible(false);
  };

  const getSelectedProductsData = async () => {
    try {
      setSummaryLoading(true);
      const response = await getSelectedCarts(checkoutItems);
      if (response) {
        setSelectedCartData(response);
      }
    } catch (error) {
    } finally {
      setSummaryLoading(false);
    }
  };

  useEffect(() => {
    if (checkoutItems?.length > 0) {
      getSelectedProductsData();
    }
  }, [checkoutItems]);

  return (
    <>
      <div className="checkout_info_main">
        <SimpleHeader />
        <div className="information_body">
          <h3>
            Checkout (
            {checkoutItems?.length > 0 ? checkoutItems?.length : cart?.length}{" "}
            items)
          </h3>
          <div className="information_container">
            {/* left side container */}
            <div className="left-side">
              <Collapse
                accordion
                className="collapse"
                expandIcon={() => null}
                activeKey={activeKey}
                onChange={(arr) => setActiveKey(arr[0])}
                items={items}
              />
            </div>

            {/* Right SIde Container */}
            <div className="right-side">
              <div className="right-side-container">
                {summaryLoading ? (
                  <Loader />
                ) : (
                  <>
                    <p className="summary">
                      Order summary (
                      {checkoutItems?.length > 0
                        ? checkoutItems?.length
                        : cart?.length}{" "}
                      Items)
                    </p>
                    <div className="total-pay">
                      <p>Item subtotal</p>
                      <p>
                        USD{" "}
                        {checkoutItems?.length > 0
                          ? selectedCartData?.total
                          : cartData?.total}
                      </p>
                    </div>
                    <div className="total-pay" style={{ padding: "10px 0px" }}>
                      <p>Shipping fee</p>
                      <p>USD {cartData?.shipping_fee}</p>
                    </div>
                    <div className="total-pay">
                      <p>Shipping discount</p>
                      <p className="discount">
                        -USD {cartData?.shipping_discount}
                      </p>
                    </div>
                    <Divider style={{ margin: "12px 0px" }} />
                    <div className="total-pay">
                      <p>Subtotal</p>
                      <p>
                        USD{" "}
                        {(checkoutItems?.length > 0
                          ? selectedCartData?.total
                          : cartData?.total) +
                          cartData?.shipping_fee -
                          cartData?.shipping_discount}
                      </p>
                    </div>

                    <Button
                      className="pi55 payment_btn pay_btn"
                      type="primary"
                      loading={btnLoading}
                      disabled={btnLoading}
                      onClick={handleCheckOut}
                    >
                      Proceed to pay
                    </Button>

                    <div className="protection-order">
                      <h4>Protection for this order</h4>
                      <div className="order-cont">
                        <div className="order-cont-heading">
                          <GrDeliver />
                          <p>On-time Dispatch Guarantee</p>
                        </div>
                        <p>Dispatched within 7 days of payment</p>
                      </div>
                      <div className="order-cont">
                        <div className="order-cont-heading">
                          <IoShieldCheckmarkOutline />
                          <p>Secure Payments</p>
                        </div>
                        <p>
                          Every payment you make on Alibaba.com is secured with
                          strict SSL encryption and PCI DSS data protection
                          protocols <span>View Details</span>
                        </p>
                      </div>
                      <div className="order-cont">
                        <div className="order-cont-heading">
                          <GrDeliver />
                          <p>Standard Refund Policy</p>
                        </div>
                        <p>
                          Claim a refund if you orders dosen't ship, is missing,
                          or arrives with product issues
                        </p>
                      </div>
                    </div>

                    <div className="order-trade">
                      <p>
                        frostflow protects all your order place and paid on the
                        plateform with{" "}
                        <span>
                          {" "}
                          <FaFileInvoiceDollar
                            style={{ color: "yellow" }}
                          />{" "}
                          Trade Assurance
                        </span>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* MODAL  */}
        {/* <Modal
        title={
          <div>
            {" "}
            <h2> Add a new card </h2>{" "}
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={visa} width={40} alt="card" />
              <img src={master} width={30} alt="card" />
              <img src={maerican} width={40} alt="card" />
              <img src={master} width={30} alt="card" />
            </div>
          </div>
        }
        footer={null}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          name="information"
          style={{
            width: "100%",
          }}
          // initialValues={{}}
          onFinish={onFinish}
          autoComplete="off"
          className="signinForm"
        >
          <Form.Item
            label={null}
            name="cardNumber"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Input label="Card Number" required />
          </Form.Item>

          <div className="d-flex">
            <Form.Item
              label={null}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input label="First Name" required />
            </Form.Item>
            <Form.Item
              label={null}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input label="Last Name" required />
            </Form.Item>
          </div>

          <div className="d-flex">
            <Form.Item
              label={null}
              name="month"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <SelectInput
                label="Month"
                name="month"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                options={months}
                required
                className="month_select"
              />
            </Form.Item>
            <Form.Item
              label={null}
              name="year"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <SelectInput
                label="Year"
                name="year"
                value={selectedYear}
                onChange={(value) => setSelectedYear(value)}
                options={generateUpcomingYears(10)}
                required
              />
            </Form.Item>
            <Form.Item
              label={null}
              name="cvv/cvc"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input label="CVV / CVC" required />
            </Form.Item>
          </div>

          <Form.Item name="remember" valuePropName="checked" label={null}>
            <Checkbox className="remember_title">Remember this card</Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit" className="payment_btn">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}

        <AddCardModal
          isVisible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />

        {/* card modal */}
        <CardModal
          isModalOpen={isModalOpen1}
          setIsModalOpen={setIsModalOpen1}
          quantity={quantity}
          incrementQuantity={incrementQuantity}
          decrementQuantity={decrementQuantity}
          onChange={handleSwitchChange}
        />
      </div>
      {clientSecret && (
        <StripePaymentModal
          open={stripeModalVisible}
          handleClose={handleStripeCloseModal}
          clientSecret={clientSecret}
        />
      )}
    </>
  );
};

export default PaymentMethod;
