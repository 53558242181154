import React, { useEffect, useState } from "react";
import {
  ChangePassword,
  CloseAccount,
  NotificationSetting,
  Profile,
  ShippingAddress,
} from "../../../../components";
import { getUserProfile } from "../../../../store/services/user";
import { getShippingAddress } from "../../../../store/services/shipping";

const AccountSettings = () => {
  const [isEditing, setIsEditing] = useState({
    profile: false,
    shippingAddress: false,
  });

  useEffect(() => {
    getUserProfile();
    getShippingAddress();
  }, []);

  const handleEditClick = (editableSection) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [editableSection]: !prevState[editableSection],
    }));
  };

  return (
    <div className="profileContainer">
      {!isEditing?.shippingAddress && (
        <Profile
          isEditing={isEditing.profile}
          onEditClick={() => handleEditClick("profile")}
        />
      )}
      {!isEditing?.profile && (
        <ShippingAddress
          isEditing={isEditing.shippingAddress}
          onEditClick={() => handleEditClick("shippingAddress")}
        />
      )}
      {!isEditing?.shippingAddress && !isEditing?.profile && <ChangePassword />}
      {!isEditing?.shippingAddress && !isEditing?.profile && (
        <NotificationSetting />
      )}
      {
        !isEditing?.shippingAddress && !isEditing?.profile && (
          <CloseAccount />
        )
      }
    </div>
  );
};

export default AccountSettings;
