import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import route from "../../../config/route";

const CheckEmail = () => {
  const navigate = useNavigate();

  return (
    <div className="checkEmailContainer">
      <h2 className="authHeading">Check Your Email</h2>
      <p className="authSubText">We sent you a link to reset your password.</p>
      <p className="authSubText">
        If you don’t see this email, please check your spam folder
      </p>
      <p className="authSubText">
        Remember your password? {" "}
        <span onClick={() => navigate(route.signin)}>Sign in</span>
      </p>
    </div>
  );
};

export default CheckEmail;
