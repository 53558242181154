import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import route from "./route";

// Wrappers
import AuthRoutes from "./AuthRoutes";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

// Pages
import {
  // Auth
  CodeVerification,
  SignIn,
  WelcomeBack,
  SignUp,
  CreateAccount,
  AccountSecurity,

  // App
  Home,
  AccountCreationSuccessful,
  CreateNewPassword,
  VerificationForm,
  CallConfirmation,
  VerificationProcesses,
  ExploreHome,
  ProductDetails,
  ResetPasswordOption,
  CheckEmail,
  ResetPassword,
  VerifyCodePage,
  ThankYou,
  Profile,
  Orders,
  Cards,
  PersonalInformation,
  PaymentMethod,
  AccountSettings,
  TradeAssuranceOrder,
  WireTransferInformation,
  RecurringOrderOption,
  PaymentSecurity,
  ShoppingCart,
  AddToFavorites,
  CheckoutPage,
  OrderDetails,
  Category,
} from "../pages";
import OrderSuccessFull from "../pages/App/Home/Order/OrderSuccess";
import Invoice from "../pages/App/ProfileManagement/Invoice";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public */}
        <Route element={<PublicRoutes />}>
          <Route path={route.home} element={<Home />} />
          <Route
            path={route.PersonalInformation}
            element={<PersonalInformation />}
          />
          <Route
            path={route.tradeAssuranceOrder}
            element={<TradeAssuranceOrder />}
          />
          <Route
            path={route.wireTransferInformation}
            element={<WireTransferInformation />}
          />
          <Route
            path={route.recurringOrderOption}
            element={<RecurringOrderOption />}
          />
          <Route path={route.paymentSecurity} element={<PaymentSecurity />} />
          <Route path={route.orderSuccessfull} element={<OrderSuccessFull />} />
          <Route path={route.invoice} element={<Invoice />} />
          <Route path="*" element={<Home />} />
        </Route>

        {/* Private */}
        <Route element={<PrivateRoutes />}>
          <Route path={route.exploreHome} element={<ExploreHome />} />
          <Route path={route.ProductDetails} element={<ProductDetails />} />
          <Route path={route.category} element={<Category />} />
          <Route path={route.shoppingCart} element={<ShoppingCart />} />
          <Route path={route.addToFavorites} element={<AddToFavorites />} />
          <Route path={route.paymentMethod} element={<PaymentMethod />} />
        </Route>
        <Route element={<PrivateRoutes type="profile" />}>
          <Route path={route.orders} element={<Orders />} />
          <Route path={route.orderDetails} element={<OrderDetails />} />
          <Route path={route.cards} element={<Cards />} />
          <Route path={route.accountSettings} element={<AccountSettings />} />
        </Route>

        {/* Auth */}
        <Route element={<AuthRoutes />}>
          <Route path={route.signin} element={<SignIn />} />
          <Route path={route.verification} element={<CodeVerification />} />
          <Route path={route.welcome} element={<WelcomeBack />} />
          {/* <Route path={route.signup} element={<SignUp />} /> */}
          <Route path={route.createAccount} element={<CreateAccount />} />
          <Route path={route.accountSecurity} element={<AccountSecurity />} />
          <Route
            path={route.accountCreationSuccessful}
            element={<AccountCreationSuccessful />}
          />
          <Route
            path={route.createNewPassword}
            element={<CreateNewPassword />}
          />
          <Route path={route.verificationForm} element={<VerificationForm />} />
          <Route path={route.callConfirmation} element={<CallConfirmation />} />
          <Route
            path={route.verificationProcesses}
            element={<VerificationProcesses />}
          />
          <Route
            path={route.resetPasswordOption}
            element={<ResetPasswordOption />}
          />
          <Route path={route.resetPassword} element={<ResetPassword />} />
          <Route path={route.checkEmail} element={<CheckEmail />} />
          <Route path={route.verifyCode} element={<VerifyCodePage />} />
          <Route path={route.verifyCodeEmail} element={<VerifyCodePage />} />
          <Route path={route.thankYou} element={<ThankYou />} />
          <Route path="*" element={<SignIn />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
