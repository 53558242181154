import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  products: [],
  singleProduct: {}
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setProducts: (state, action) => {
      state.products = action?.payload;
    },
    setSingleProduct: (state, action) => {
      state.singleProduct = action?.payload;
    },
  },
});

export const { setLoading, setProducts, setSingleProduct } = productSlice.actions;

export default productSlice.reducer;
