import React, { useEffect, useState } from "react";
import { VerifyCode } from "../../../components";
import { useNavigate, useSearchParams } from "react-router";
import route from "../../../config/route";
import { forgotPassword, verifyCode } from "../../../store/services/user";

const VerifyCodePage = () => {
  const getVerificationInfo = localStorage.getItem("verificationInfo");
  const verificationInfo =
    getVerificationInfo && JSON.parse(getVerificationInfo);
  const [value, setValue] = useState(undefined);
  const navigate = useNavigate();
  let [params] = useSearchParams();
  const token = params.get("token");
  const isEmail = verificationInfo?.contactType === "email";

  useEffect(() => {
    token && setValue(token);
  }, [token]);

  const handleChangeNumberOrEmail = () => {
    navigate(route.resetPasswordOption);
  };

  const handleResendCode = async () => {
    try {
      const payload = {
        contact_method: verificationInfo?.contactType,
        contact_value: verificationInfo?.contactValue,
      };
      await forgotPassword(payload);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    localStorage.setItem(
      "verificationInfo",
      JSON.stringify({
        code: value,
        contactType: verificationInfo?.contactType,
        contactValue: verificationInfo?.contactValue,
      })
    );
    navigate(route.resetPassword);
  };

  return (
    <div className="verifyCodePageContainer">
      <VerifyCode
        heading={isEmail ? "Email Address" : "Phone Number"}
        description={isEmail ? "email" : "number"}
        value={value}
        type={verificationInfo?.contactType || "email"}
        setValue={setValue}
        handleChangeNumberOrEmail={handleChangeNumberOrEmail}
        resendCode={handleResendCode}
        handleSubmit={handleSubmit}
        contactValue={verificationInfo?.contactValue}
      />
    </div>
  );
};

export default VerifyCodePage;
