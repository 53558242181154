import { notification } from "antd";
import { setLoading, setOrders, setSingleOrder } from "../slices/orderSlice";
import { privateAPI } from "../../config/constants";
import { store } from "..";

export const getOrders = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/orders/");
    if (response) {
      store.dispatch(setOrders(response?.data));
    }
    return response.data;
  } catch (error) {
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getSingleOrder = async (id) => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get(`/orders/${id}`);
    if (response) {
      store.dispatch(setSingleOrder(response?.data));
    }
    return response.data;
  } catch (error) {
    notification.error({
      message:
        typeof error?.response?.data?.detail === "string"
          ? error?.response?.data?.detail
          : "Server Error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};
