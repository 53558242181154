import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { Input, SelectInput } from "../../../components";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router";
import route from "../../../config/route";
import { businessVerification } from "../../../store/services/user";
import { useSelector } from "react-redux";

const VerificationForm = () => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [selectedCategory, setSelectedCategory] = useState({
    category: "",
    categoryName: "",
  });
  const onFinish = async (values) => {
    try {
      const payload = {
        request_id: user?.uid,
        user_id: user?.uid,
        business_name: values?.businessName,
        business_category:
          selectedCategory.category === "other"
            ? selectedCategory?.categoryName
            : selectedCategory?.category,
        registration_number: values?.registerationNumber,
        business_address: values?.businessAddress,
        contact_name: values?.contactName,
        phone_number: phone,
      };
      const response = await businessVerification(payload);
      if (response) {
        navigate(route.callConfirmation);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      businessName: user?.business_name,
      registerationNumber: user?.registration_number,
      category: user?.business_category,
      businessAddress: user?.business_address,
      contactName: user?.contact_name,
      phoneNumber: user?.phone_number,
    });
    setPhone(user?.phone_number);
    setSelectedCategory({
      category: user?.business_category,
      categoryName: user?.business_category,
    });
  }, [user]);

  return (
    <div className="verificationFormContainer">
      <h2 className="authHeading">Verification Form</h2>
      <p className="authDescription">Please confirm your Business details</p>

      <Form
        form={form}
        name="verificationForm"
        style={{
          width: "100%",
        }}
        onFinish={onFinish}
        autoComplete="off"
        className="verificationForm"
      >
        <Form.Item
          label={null}
          name="businessName"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Input label="Business Name" required />
        </Form.Item>
        <Form.Item
          label={null}
          name="registerationNumber"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Input label="Registeration Number" required />
        </Form.Item>
        <Form.Item
          label={null}
          name="category"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <SelectInput
            label="Select Category"
            options={[
              { value: "Catering", label: "Catering" },
              { value: "Grocery Store", label: "Grocery Store" },
              { value: "Restaurant", label: "Restaurant" },
              { value: "Wholesalers", label: "Wholesalers" },
              { value: "Other", label: "Other" },
            ]}
            required
            value={selectedCategory?.category}
            onChange={(value) =>
              setSelectedCategory({
                category: value,
              })
            }
          />
        </Form.Item>
        {selectedCategory?.category === "Other" && (
          <Form.Item
            label={null}
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Input
              label="Category Name"
              value={selectedCategory.categoryName}
              onChange={(event) =>
                setSelectedCategory({
                  ...selectedCategory,
                  categoryName: event.target.value,
                })
              }
            />
          </Form.Item>
        )}
        <Form.Item
          label={null}
          name="businessAddress"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Input label="Business Address" required />
        </Form.Item>
        <Form.Item
          label={null}
          name="contactName"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Input label="Contact Name" required />
        </Form.Item>
        <Form.Item
          label={null}
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Form.Item
            label={null}
            name="phoneNumber"
            rules={[
              {
                required: false,
                message: "Required!",
              },
            ]}
          >
            <PhoneInput
              country={"us"}
              value={phone}
              onChange={(phone, country) => setPhone(`+${phone}`)}
              className={phone ? "phoneInputValid" : ""}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label={null}>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Continue Information
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VerificationForm;
