import React from "react";

import { FcGoogle } from "react-icons/fc";
import { IoLogoApple } from "react-icons/io";
import { MdAlternateEmail } from "react-icons/md";

const SigninWith = (props) => {
  const { signinWith, signupWith, onClick } = props;

  const getIcon = () => {
    switch (signinWith || signupWith) {
      case "Google":
        return <FcGoogle size={24} />;
      case "Apple":
        return <IoLogoApple size={26} color="gray" />;
      case "Email":
        return <MdAlternateEmail size={20} color="gray" />;

      default:
        break;
    }
  };

  return (
    <div className="signinWithBox" onClick={onClick}>
      {signinWith ? (
        <p>Sign in with {signinWith}</p>
      ) : (
        <p>Sign up with {signupWith}</p>
      )}

      <div>{getIcon()}</div>
    </div>
  );
};

export default SigninWith;
