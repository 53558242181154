import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Modal, notification, Spin } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import route from "../../../config/route";
import { confirm_Payment } from "../../../store/services/payments";
import { stripePromise } from "../../../config/constants";
import { store } from "../../../store";
import { setCheckoutItems } from "../../../store/slices/paymentSlice";

function StripePaymentModal({ open, handleClose, clientSecret }) {
  const options = clientSecret ? { clientSecret } : null;

  return (
    <Modal
      open={open}
      onCancel={() => handleClose()}
      footer={null}
      className="payment-modal"
    >
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckOutForm handleClose={handleClose} />
        </Elements>
      )}
    </Modal>
  );
}

function CheckOutForm({ handleClose }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      if (!stripe || !elements) {
        return;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      console.log("^^^", paymentIntent);

      if (error) {
        notification.error({
          message: error.message,
        });
      } else if (paymentIntent && paymentIntent?.status === "succeeded") {
        store.dispatch(setCheckoutItems([]));
        const response = await confirm_Payment(paymentIntent?.id);
        if (response) {
          handleClose();
          navigate(route.orders);
        }
      }
    } catch (error) {
      notification.error({
        message: error.message || "something went wrong!",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <PaymentElement />
      <Button
        type="primary"
        htmlType="submit"
        className="submit-btn"
        loading={loading}
        disabled={loading}
      >
        {loading ? "Processing your payment..." : "Pay Now"}
      </Button>
    </form>
  );
}

export default StripePaymentModal;
