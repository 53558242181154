import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";

import { useNavigate } from "react-router";
import { Input } from "../../../components";
import route from "../../../config/route";
import { VerifyNumberAndEmailModal } from "../../../components/Modals";
import {
  getActiveSessions,
  getUser,
  sendVerificationCode,
  verifyCode,
} from "../../../store/services/user";
import { useSelector } from "react-redux";
import { isUserEmailVerified, isUserPhoneVerified } from "../../../utils/index";

const CodeVerification = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [verifyModal, setverifyModal] = useState(false);
  const [contactType, setContactType] = useState(null);
  const [verificationModalType, setVerificationModalType] = useState("email");
  const [contactValue, setContactValue] = useState("");
  const isEmail = contactType === "email";
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [loadingSubmitCode, setLoadingSubmitCode] = useState(false);
  const [verification, setVerification] = useState(undefined);

  const onFinish = async (values) => {
    try {
      setLoadingSubmitCode(true);
      const payload = {
        code: values?.code,
        contact_method: contactType,
        contact_value: contactValue,
      };
      const response = await verifyCode(payload);
      if (response) {
        const activeSessionsRes = await getActiveSessions();
        if (activeSessionsRes) {
         
          navigate(route.welcome);
        }
      }
    } catch (error) {}
    setLoadingSubmitCode(false);
  };

  const handleSendCode = async ({ type, value }) => {
    try {
      setContactValue(value);
      setContactType(type);
      setLoadingSendCode(true);
      const payload = {
        contact_method: type,
        contact_value: value,
      };
      const response = await sendVerificationCode(payload);
      if (response) {
        setverifyModal(false);
        setVerificationModalType(type === "email" ? "sms" : "email");
        await getUser();
        setVerification(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSendCode(false);
    }
  };

  useEffect(() => {
    if (user) {
         if (isUserEmailVerified(user) && !isUserPhoneVerified(user)) {
          setContactType('sms');
           setContactValue(user?.phone_number);
        } else {
          setContactType('email');
          setContactValue(user?.email);
      }
    }
  }, []);

  const sendCodeToEmail = () => setverifyModal(true);
  const resendCode = () => {
    handleSendCode({ type: contactType, value: contactValue });
  };
  const signinWithDiffAccount = () => navigate(route.signin);

  return (
    <>
      <div className="codeVerificationContainer">
        <h2 className="authHeading">Verification</h2>
        <p className="codeDescription">
          A 6-digit verification code has been sent to your {contactType}
        </p>
        <p className="codeText">{contactValue}</p>
        <section>
          <Form
            form={form}
            name="codeVerification"
            style={{
              width: "100%",
            }}
            onFinish={onFinish}
            autoComplete="off"
            className="codeVerificationForm"
          >
            <Form.Item
              label={null}
              name="code"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input type="number" label="6-Digit Verification Code" />
            </Form.Item>

            <Form.Item label={null}>
              <Button
                className="pi55"
                type="primary"
                htmlType="submit"
                loading={loadingSubmitCode}
                disabled={loadingSubmitCode}
              >
                Submit Code
              </Button>
            </Form.Item>
          </Form>

          <div className="links">
            {/* <p onClick={sendCodeToEmail}>
              Send code to{" "}
              {verificationModalType === "email" ? "email" : "phone"} instead
            </p> */}
            <p onClick={resendCode}>Resend code</p>
            <p onClick={signinWithDiffAccount}>
              Sign in with a different account
            </p>
          </div>
        </section>
      </div>
      <VerifyNumberAndEmailModal
        open={verifyModal}
        setOpen={setverifyModal}
        type={verificationModalType}
        handleSendCode={handleSendCode}
        loading={loadingSendCode}
        verification={verification}
      />
    </>
  );
};

export default CodeVerification;
