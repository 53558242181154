import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoriesLoading: false,
  categories: [],
  subCategoriesLoading: false,
  subCategories: []
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoriesLoading: (state, action) => {
      state.categoriesLoading = action?.payload;
    },
    setCategories: (state, action) => {
      state.categories = action?.payload;
    },
    setSubCategoriesLoading: (state, action) => {
      state.subCategoriesLoading = action?.payload;
    },
    setSubCategories: (state, action) => {
      state.subCategories = action?.payload;
    },
  },
});

export const { setCategoriesLoading, setCategories, setSubCategoriesLoading, setSubCategories } =  categorySlice.actions;

export default categorySlice.reducer;
