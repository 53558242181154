import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from "../../../../store/services/user";
import { Loader } from "../../../index";

function NotificationSetting() {
  const [notificationPreferences, setNotificationPreferences] = useState({
    promotional_emails: false,
    order_updates: false,
    shipping_updates: false,
    review_requests: false,
  });
  const [loading, setLoading] = useState(false);

  const getNotificationSettings = async () => {
    try {
      setLoading(true);
      const response = await getNotificationPreferences();
      if (response) {
        setNotificationPreferences({
          promotional_emails: response?.promotional_emails || false,
          order_updates: response?.order_updates || false,
          shipping_updates: response?.shipping_updates || false,
          review_requests: response?.review_requests || false,
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNotificationSettings();
  }, []);

  const handleChangeNotificationStatus = async ({ type, status }) => {
    try {
      setNotificationPreferences((prevState) => ({
        ...prevState,
        [type]: status,
      }));
      const payload = {
        [type]: status,
      };
      const response = await updateNotificationPreferences(payload);
      if (response) {
        getNotificationSettings();
      }
    } catch (error) {}
  };

  return (
    <div className="notificationSettingBody">
      <div className="notificationSettingHeader">
        <p>I would like to receive email notifications when:</p>
      </div>
      <ul className="notificationsList">
        {loading ? (
          <Loader />
        ) : (
          <>
            <li>
              <Switch
                className="switch-style"
                checked={notificationPreferences?.promotional_emails}
                onChange={(status) => handleChangeNotificationStatus({ type: "promotional_emails", status })}
              />
              You have a new product, service or promotional codes
            </li>
            <li>
              <Switch
                className="switch-style"
                checked={notificationPreferences?.order_updates}
                onChange={(status) => handleChangeNotificationStatus({ type: "order_updates", status })}
              />
              My order has been marked as complete
            </li>
            <li>
              <Switch
                className="switch-style"
                checked={notificationPreferences?.shipping_updates}
                onChange={(status) => handleChangeNotificationStatus({ type: "shipping_updates", status })}
              />
              I have received a delivery
            </li>
            <li>
              <Switch
                className="switch-style"
                checked={notificationPreferences?.review_requests}
                onChange={(status) => handleChangeNotificationStatus({ type: "review_requests", status })}
              />
              I have received a new message
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default NotificationSetting;
