import React from "react";
import { useSelector } from "react-redux";

function Banner({ title, discription, showGreetingText = false }) {
  const { user } = useSelector((state) => state.user);
  return (
    <div className="banner-section">
      <div className="text-section">
        <h1 className="hdng">{title}</h1>
        <h5 className="sub-hdng">{discription}</h5>
      </div>
      {showGreetingText && (
        <div className="greeting-text">
          Welcome to Frostflow, {user?.business_name}
        </div>
      )}
    </div>
  );
}

export default Banner;
