import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Breadcrumb, Button, Table } from "antd";
import { HomeOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getOrders } from "../../../../store/services/orders";
import { useNavigate } from "react-router";
import route from "../../../../config/route";

const Orders = () => {
  const { orders, loading } = useSelector((state) => state.orders);
  const navigate = useNavigate();

  useEffect(() => {
    getOrders();
  }, []);

  console.log(orders);

  const getOrderColumns = () => {
    return [
      {
        title: "IMG",
        dataIndex: "id",
        key: "id",
        render: (t, { items }) => (
          <div className="productImageWrapper">
            {items.map(
              ({ image_url }) =>
                image_url && (
                  <div>
                    <img src={image_url} width={20} />
                  </div>
                )
            )}
          </div>
        ),
      },
      {
        title: "ORDER ID",
        dataIndex: "id",
        key: "id",
        render: (id) => `#${id.slice(-8)}`,
      },
      {
        title: "DATE",
        dataIndex: "created_at",
        key: "created_at",
        render: (date) => moment(date).format("DD.MM.YYYY"),
      },
      {
        title: "STATUS",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "CATEGORIES",
        dataIndex: "categories",
        key: "categories",
        render: (_, { items }) => (
          <div className="category-names">
            {items.map(({ category }) => (
              <p>{category},</p>
            ))}
          </div>
        ),
      },

      {
        title: "PRICE",
        dataIndex: "total_amount",
        key: "total_amount",
        render: (amount, obj) => (
          <div className="priceBox">
            <h2>{`$${amount}`}</h2>
            <p onClick={() => navigate(`/order-detail/${obj?.id}`)}>
              View Details
            </p>
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "landOwnerMiner",
        key: "landOwnerMiner",
        render: (t) => <Button className="reOrderBtn">Re-order</Button>,
      },
    ];
  };

  return (
    <div className="ordersContainer">
      <div className="breadcrumbBox">
        <Breadcrumb
          separator={<RightOutlined className="breadcrumb-seperator" />}
          items={[
            {
              title: <HomeOutlined size={18} style={{cursor:"pointer"}} />,
              onClick: () => navigate(route.exploreHome),
            },
            {
              title: <span>User Account</span>,
            },
            {
              title: "Orders",
            },
          ]}
        />
      </div>
      <div className="tableContainer">
        <Table
          loading={loading}
          dataSource={orders}
          columns={getOrderColumns()}
          scroll={{ x: 800 }}
        />
      </div>
    </div>
  );
};

export default Orders;
