import { Col, Row } from "antd";
import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import logoLight from "../../assets/images/logolight.PNG";

const Footer = () => {
  return (
    <footer className="footer-main">
      <Row className="row1" justify="start" gutter={[0, 50]}>
        <Col className="col" span={24} sm={8}>
          <div>
            <h6 className="hdng">Categories</h6>
            <ul className="nav-list">
              <li className="nav-item">Shellfish</li>
              <li className="nav-item">Mollusks</li>
              <li className="nav-item">Fish</li>
              <li className="nav-item">Exotic</li>
            </ul>
          </div>
        </Col>
        <Col className="col" span={24} sm={8}>
          <div>
            <h6 className="hdng">Get Support</h6>
            <ul className="nav-list">
              <li className="nav-item">Help Center</li>
              <li className="nav-item">Live Chat</li>
              <li className="nav-item">Check Order Status</li>
              <li className="nav-item">Refunds</li>
            </ul>
          </div>
        </Col>
        <Col className="col" span={24} sm={8}>
          <div>
            <h6 className="hdng">Get to know us</h6>
            <ul className="nav-list">
              <li className="nav-item">About Us</li>
              <li className="nav-item">Terms & Conditions</li>
              <li className="nav-item">Contact Us</li>
              <li className="nav-item">Privacy Policy</li>
              <li className="nav-item">Cookie Policy</li>
            </ul>
          </div>
        </Col>
      </Row>
      <div className="row2">
        <div className="logo-text-parent">
          <img src={logoLight} alt="logo" />
          <p className="text">© FrostFlow. 2023</p>
        </div>
        <div className="social-icons-parent">
          <a target="_blank" href="https://x.com/Frostflow_UK">
          <RiTwitterXLine className="social-icon" />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/frostflow-foods/"
          >
            <FaLinkedin className="social-icon" />
          </a>
          <a target="_blank" href="https://www.instagram.com/frostflow.co.uk/">
            <FaInstagram className="social-icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
