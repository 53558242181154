import React from "react";
import { ServiceCard } from "../../../components";

import { Button } from "antd";
import { useNavigate } from "react-router";
import route from "../../../config/route";
import { getActiveSessions } from "../../../store/services/user";

const VerificationProcesses = () => {
  const navigate = useNavigate();

  const handleProceed = () => {
    navigate(route.verificationForm);
  };

  const handleSkipVerification = async () => {
    try {
      const activeSessionsRes = await getActiveSessions();
      if (activeSessionsRes) {
        navigate(route.exploreHome);
      }
    } catch (error) {}
  };

  return (
    <div className="verificationProcessesContainer">
      <p className="authHeading">Verification Process</p>
      {/* <p className="descriptionText">
        At Frostflow.co.uk, we're not just about seafood; we're about an oceanic
        experience that transcends tha ordinary. <br /> Here's why you'll love
        choosing Frostflow:
      </p> */}

      <div className="cardBox">
        <ServiceCard
          title="Step 1: Click Next"
          discription="Click on the button below to begin verification"
        />
        <ServiceCard
          title="Step 2: Confirm Information"
          discription="Confirm that the business details submitted during registration are correct"
        />
        <ServiceCard
          title="Step 3: Admin will call You"
          discription="Our team will reach out to you within 24hrs to validate your details and complete your verification"
        />
      </div>

      <Button className="pi55 nextBtn" type="primary" onClick={handleProceed}>
        Next
      </Button>

      <p className="doThisLater" onClick={handleSkipVerification}>
        Do this later
      </p>
    </div>
  );
};

export default VerificationProcesses;
