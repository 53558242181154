import { notification } from "antd";
import { privateAPI } from "../../config/constants";
import { store } from "..";
import { setCards, setLoading } from "../slices/paymentSlice";

const handleError = (msg) => {
  notification.error({
    message: msg || "Server Error",
  });
};

export const checkOut = async (payload) => {
  try {
    const response = await privateAPI.post("/payments/checkout", payload);
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
};

export const confirm_Payment = async (payment_intent_id) => {
  try {
    const response = await privateAPI.post(
      `/payments/confirm_payment?payment_intent_id=${payment_intent_id}`
    );
    if (response) {
      notification.success({
        message: response?.data?.message || "Payment successfull",
      });
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentMethods = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/payments/payment_methods");
    if (response) {
      store.dispatch(setCards(response?.data));
    }
    return response.data;
  } catch (error) {
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const addPaymentMethod = async (payment_method_id)=>{
  try {
    const response = await privateAPI.post(`/payments/add-payment-method?payment_method_id=${payment_method_id}`)
    if (response) {
      getPaymentMethods()
      notification.success({
        message: response?.data?.message || "Payment Method Added successfully!",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
}

export const editPaymentMethod = async ({id,data})=>{
  try {
    const response = await privateAPI.put(`/payments/edit-payment-method?payment_method_id=${id}`,data)
    if (response) {
      getPaymentMethods()
      notification.success({
        message: response?.data?.message || "Payment Method edited successfully!",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
}

export const deletePaymentMethod = async (id)=>{
  try {
    const response = await privateAPI.delete(`/payments/delete-payment-method?payment_method_id=${id}`)
    if (response) {
      getPaymentMethods()
      notification.success({
        message: response?.data?.message || "Payment Method deleted successfully!",
      });
    }
    return response?.data;
  } catch (error) {
    handleError(
      typeof error?.response?.data?.detail === "string"
        ? error?.response?.data?.detail
        : error?.response?.data?.detail?.[0]?.msg || "Server Error"
    );
  }
}