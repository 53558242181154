import React from "react";
import SelectInput from "../../../SelectInput";
import { Button, Checkbox } from "antd";
import { deleteAccount } from "../../../../store/services/user";
import { useNavigate } from "react-router";
import route from "../../../../config/route";

function CloseAccount() {
  const navigate = useNavigate();
  const reasons = [
    {
      value: "I'm not using this account anymore",
      label: "I'm not using this account anymore",
    },
    {
      value: "I have another account",
      label: "I have another account",
    },
    {
      value: "I want to create a new account",
      label: "I want to create a new account",
    },
    {
      value: "Account security concerns/Unauthorized activity",
      label: "Account security concerns/Unauthorized activity",
    },
    { value: "Privacy concerns", label: "Privacy concerns" },
    {
      value: "I don't wish to provide a reason",
      label: "I don't wish to provide a reason",
    },
  ];

  const handleCloseAccount = async () => {
    try {
      const response = await deleteAccount();
      if (response) {
        navigate(route.signin);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="closeAccountBody">
      <div className="closeAccountHeader">
        <p>Please close my account</p>
      </div>
      <div className="closeAccountContent">
        <p>
          Once your account is closed all of your information including the
          details of all of your orders will be permanently deleted
        </p>
        <div className="reasonParent">
          <label className="reasonLabel">
            Please select the main reason for closing your account (Optional)
          </label>
          <SelectInput
            label="reason"
            name="reason"
            options={reasons}
            className="reasonSelect"
          />
        </div>
        <Checkbox>
          Yes, I want to permanently close my account and delete my data.
        </Checkbox>
        <Button className="btn" onClick={handleCloseAccount}>
          Close Your Account
        </Button>
      </div>
    </div>
  );
}

export default CloseAccount;
