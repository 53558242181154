import { Breadcrumb, Button, Col, Dropdown, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { HomeOutlined, RightOutlined } from "@ant-design/icons";
import { FaArrowRightLong, FaCcMastercard } from "react-icons/fa6";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { BiCopy } from "react-icons/bi";
import { SiVisa } from "react-icons/si";
import { AddCardModal, Loader } from "../../../../components";
import {
  deletePaymentMethod,
  getPaymentMethods,
} from "../../../../store/services/payments";
import { useSelector } from "react-redux";

function Cards() {
  const { cards, loading: cartLoading } = useSelector((state) => state.payment);
  const [isAddCardModalVisible, setIsAddCardModalVisible] = useState(false);
  const [selectedCardEdit, setSelectedCardEdit] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState();

  // const handleEditClick = (cardData) => {
  //   setIsEditMode(true);
  //   setSelectedCardEdit(cardData);
  //   setIsAddCardModalVisible(true);
  // };

  const items = (cardData) => [
    // {
    //   label: <p>Edit Card</p>,
    //   key: "0",
    // },
    {
      label: <p>Delete Card</p>,
      key: "0",
      onClick: () => handleDeletePaymentMethod(cardData?.id),
    },
  ];

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const handleCardModalClose = () => {
    setIsAddCardModalVisible(false);
  };

  const handleDeletePaymentMethod = async (id) => {
    try {
      setLoading(true);
      const response = await deletePaymentMethod(id);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="cardsContainer">
        <div className="breadcrumbBox">
          <Breadcrumb
            separator={<RightOutlined className="breadcrumb-seperator" />}
            items={[
              {
                title: <HomeOutlined size={18} />,
              },
              {
                title: <span>User Account</span>,
              },
              {
                title: "Cards",
              },
            ]}
          />
        </div>
        <div className="cards-content">
          <div className="wrapper">
            <div className="header">
              <p className="headingText">Payment Option</p>
              <Button
                className="addCard-btn"
                onClick={() => setIsAddCardModalVisible(true)}
              >
                Add Card <FaArrowRightLong />
              </Button>
            </div>
            <div className="cards-parent-row">
              {cartLoading ? (
                <Loader />
              ) : (
                cards &&
                cards?.length > 0 &&
                cards.map((item) => (
                  <div
                    className="card"
                    style={{
                      background:
                        item?.card?.brand === "mastercard"
                          ? "radial-gradient(98.14% 214.97% at 0% 0%, #248E1D 0%, #2DB324 100%)"
                          : "radial-gradient(98.14% 214.97% at 0% 0%, #1B6392 0%, #124261 100%)",
                    }}
                  >
                    <div className="amount-actions-parent">
                      <span></span>

                      <Dropdown
                        menu={{ items: items(item) }}
                        trigger={["click"]}
                      >
                        <MdOutlineMoreHoriz />
                      </Dropdown>
                    </div>
                    <div className="card-number-parent">
                      <label>Card Number</label>
                      <p className="card-number">
                        **** **** **** {item?.card?.last4}
                        <BiCopy />
                      </p>
                    </div>
                    <div className="cardType-userName-parent">
                      {item?.card?.brand === "visa" ? (
                        <SiVisa className="card-type-icon" />
                      ) : item?.card?.brand === "mastercard" ? (
                        <FaCcMastercard className="card-type-icon" />
                      ) : null}
                      <h6>{item?.billing_details?.name || "-"}</h6>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <AddCardModal
        isVisible={isAddCardModalVisible}
        onClose={handleCardModalClose}
        // selectedCardEdit={selectedCardEdit}
        // isEditMode={isEditMode}
      />
    </>
  );
}

export default Cards;
