import React, { useEffect, useState } from "react";
import { message } from "antd";
import SimpleHeader from "../SimpleHeader";

import { FiLayers } from "react-icons/fi";
import { CiShop } from "react-icons/ci";
import { PiCreditCard } from "react-icons/pi";
import { SlSettings } from "react-icons/sl";
import { LuLogOut } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router";
import route from "../../config/route";

const ProfileManagLayout = (props) => {
  const { children } = props;
  const [_, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <div className="profileManagLayout">
        <SimpleHeader />
        <div className="profileManagBody">
          <Sider />
          <div className="profileManagContent">{children}</div>
        </div>
      </div>
    </>
  );
};

const Sider = () => {
  const [list] = useState([
    {
      name: "Orders",
      icon: <CiShop />,
      link: route.orders,
    },
    {
      name: "Cards",
      icon: <PiCreditCard />,
      link: route.cards,
    },
    {
      name: "Account Settings",
      icon: <SlSettings />,
      link: route.accountSettings,
    },
    {
      name: "Log-out",
      icon: <LuLogOut />,
    },
  ]);
  const [active, setActive] = useState("Account Settings");

  const { pathname } = useLocation();
  const navigate = useNavigate();
  console.log(pathname);

  useEffect(() => {
    switch (true) {
      case pathname === "/orders":
        setActive(list[0].name);
        break;

      case pathname === "/cards":
        setActive(list[1].name);
        break;

      case pathname === "/account-settings":
        setActive(list[2].name);
        break;

      case pathname.startsWith("/order-detail/"):
        setActive(list[0].name);
        break;

      default:
        setActive(list[0].name);
    }
  }, [pathname, list]);

  const handleNavigate = (link) => {
    navigate(link);
  };

  return (
    <div className="profileManagSider">
      <div className="header">
        <h5>Account Settings</h5>
      </div>
      <div className="listBox">
        {list.map((v) => {
          return (
            <div
              key={v?.name}
              className={`list ${v?.name === active ? "active" : ""}`}
              onClick={() => handleNavigate(v?.link)}
            >
              <span>{v?.icon}</span>
              <p>{v?.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileManagLayout;
