import React from "react";
import { Button } from "antd";
import { useNavigate, useLocation } from "react-router";
import route from "../../../../../config/route";
import { SimpleHeader } from "../../../../../components";

const RecurringOrderOption = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleVerifyAccount = () => {
    navigate(route.PersonalInformation);
  };

  const handleModify = () => {
    console.log("Modify clicked");
    // Add navigation or logic for modifying order here
  };

  const handleReturn = () => {
    console.log("Return clicked");
    // Add navigation or logic for returning to the previous step here
  };

  const isOrderOptionRoute = location.pathname === "/recurring-order-option";

  return (
    <>
      <SimpleHeader />
      <div className="recurringOrderOption">
        <div className="welcomeBackContainer">
          <h2 className="authHeading">Recurring Order option</h2>
          <p className="welcomeText">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum
            dolor sit
          </p>

          <Button className="pi55" type="primary" onClick={handleVerifyAccount}>
            Countinue
          </Button>
          <div className="additionalButtons">
            <Button className="modifyButton" onClick={handleModify}>
              Cancle
            </Button>
            <Button className="returnButton" onClick={handleReturn}>
              Modify
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecurringOrderOption;
