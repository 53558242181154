import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Footer,
  Header,
  Loader,
  OrderSummary,
  ProductCard,
  ProductCardView,
  SimpleHeader,
} from "../../../../components";
import { Checkbox, Col, Divider, Row } from "antd";
import image from "../../../../assets/images/tempProduct01.png";
import { GoTrash } from "react-icons/go";
import { BsFire } from "react-icons/bs";
import { IoAlertCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { getCarts, getSelectedCarts } from "../../../../store/services/cart";
import { useNavigate } from "react-router";
import route from "../../../../config/route";
import { loadStripe } from "@stripe/stripe-js";
import {
  CheckoutProvider,
  Elements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { store } from "../../../../store";
import { setCheckoutItems } from "../../../../store/slices/paymentSlice";

const ShoppingCart = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { cartData } = useSelector((state) => state.cart);
  const carts = cartData?.items;
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [selectedCartData, setSelectedCartData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getCarts();
  }, []);

  const getSelectedProductsData = async () => {
    try {
      setSummaryLoading(true);
      const response = await getSelectedCarts(selectedProducts);
      if (response) {
        setSelectedCartData(response);
      }
    } catch (error) {
    } finally {
      setSummaryLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProducts?.length > 0) {
      getSelectedProductsData();
    }
  }, [selectedProducts]);

  console.log(selectedCartData);

  const productData = [
    {
      id: 1,
      title: "Snail Product Lorem Ipsum",
      stockSold: "1000 sold recently",
      deliverBy: "Jan 6",
      minOrder: "50kg",
      image: image,
      alertMessage:
        "The total quantity of this product must be less than the stock value",
    },
    {
      id: 2,
      title: "Snail Product Lorem ipsum dolor sit amet consectetur ",
      stockSold: "1000 sold recently",
      deliverBy: "Jan 10",
      minOrder: "100kg",
      image: image,
      alertMessage:
        "The total quantity of this product must be less than the stock value",
    },
  ];

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    setSelectedProducts(
      isChecked ? productData.map((product) => product.id) : []
    );
  };

  const handleProductSelect = async (id) => {
    if (selectedProducts.includes(id)) {
      setSelectedProducts(
        selectedProducts.filter((productId) => productId !== id)
      );
      setSelectAll(false);
    } else {
      const updatedSelectedProducts = [...selectedProducts, id];
      setSelectedProducts(updatedSelectedProducts);
      if (updatedSelectedProducts.length === productData.length) {
        setSelectAll(true);
      }
    }
  };

  const handleProceedToCheckout = () => {
    store.dispatch(
      setCheckoutItems(selectedProducts.length > 0 ? selectedProducts : [])
    );
    navigate(route.paymentMethod);
  };

  return (
    <div>
      <Header />
      <div className="shopping-cart-container">
        <h2 className="">Shopping cart</h2>
        {carts && carts?.length > 0 ? (
          <div className="shopping-cart-items">
            {/* LEFT SIDE */}
            <div className="left-cart-item">
              {/* <div className="select-all">
                <div className="select-all-checkbox">
                  <Checkbox checked={selectAll} onChange={handleSelectAll}>
                    Select all items <span>({carts?.length})</span>
                  </Checkbox>
                </div>
                <Divider className="line" />
              </div> */}
              {carts &&
                carts?.length > 0 &&
                carts?.map((cartProduct) => (
                  <div
                    className="card-product-detail"
                    key={cartProduct?.product_id}
                  >
                    <div className="card-products">
                      <div className="product-image">
                        <div className="product-image-container">
                          <Checkbox
                            checked={selectedProducts.includes(
                              cartProduct?.product_id
                            )}
                            onChange={() =>
                              handleProductSelect(cartProduct?.product_id)
                            }
                          ></Checkbox>
                          <div className="product-image-detail">
                            <img
                              src={cartProduct?.image_url}
                              alt=""
                              width={50}
                            />
                            <div className="product-detail">
                              <p className="product-title">
                                {cartProduct?.name}
                              </p>
                              <p className="product-stock">
                                <BsFire className="fire" />{" "}
                                {cartProduct?.total_sold} Sold recently
                              </p>
                              <p className="product-deliver-time">
                                Deliver by{" "}
                                {cartProduct?.estimated_delivery_date}
                              </p>
                              <p className="product-weight">
                                Min. order: {cartProduct?.minimum_order}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <GoTrash size={22} /> */}
                      </div>
                      {cartProduct?.variants?.map((variant) => (
                        <ProductCardView
                          product={cartProduct}
                          variant={variant}
                        />
                      ))}
                      {/* <p className="alert-product-stock">
                          <IoAlertCircleOutline className="alert-icon" />{" "}
                          {product.alertMessage ?? ""}
                        </p> */}
                      {/* <p className="alert-product-stock">
                          <IoAlertCircleOutline className="alert-icon" />{" "}
                          {product.alertMessage ?? ""}
                        </p> */}
                    </div>
                  </div>
                ))}
            </div>

            {/* RIGHT SIDE */}
            {/* <OrderSummary
              title="Order summary"
              selectedProducts={selectedProducts}
              handleProceedCheckout={handleProceedToCheckout}
            /> */}
            <div className="right-cart-item">
              <div className="right-cart-container">
                {summaryLoading ? (
                  <Loader />
                ) : (
                  <>
                    <p className="summary">
                      Order summary (
                      {selectedProducts?.length > 0
                        ? selectedProducts?.length
                        : carts?.length}{" "}
                      Items)
                    </p>
                    <div className="total-pay">
                      <p>Item subtotal</p>
                      <p>
                        USD{" "}
                        {selectedProducts?.length > 0
                          ? selectedCartData?.total
                          : cartData?.total}
                      </p>
                    </div>
                    <div className="total-pay">
                      <p>Shipping fee</p>
                      <p>USD {cartData?.shipping_fee}</p>
                    </div>
                    <div className="total-pay">
                      <p>Shipping discount</p>
                      <p className="discount">
                        {" "}
                        -USD {cartData?.shipping_discount}
                      </p>
                    </div>
                    <Divider />
                    <div className="total-pay">
                      <p>Subtotal</p>
                      <p>
                        USD{" "}
                        {(selectedProducts?.length > 0
                          ? selectedCartData?.total
                          : cartData?.total) +
                          cartData?.shipping_fee -
                          cartData?.shipping_discount}
                      </p>
                    </div>
                    <Button
                      className="pi55 payment_btn pay_btn"
                      type="primary"
                      onClick={handleProceedToCheckout}
                    >
                      Proceed to Checkout
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="no-addtocart">
            <h1>You don't have any Item in Cart</h1>
            <p>
              See something you like but not ready to purchase? We'll keep it{" "}
              <br /> save for you in yourfavorites
            </p>
          </div>
        )}

        {/* RELATE PRODUCT */}
        {/* <div className="product-you-may-like">
          <h2>Product you may like</h2>
          <Row gutter={[16, 16]} justify="center">
            {[1, 2, 3, 4].map((product, index) => (
              <Col key={index} xs={24} sm={12} md={4} lg={6}>
                <ProductCard
                  imgUrl={image}
                  title="Premium Snail Delicacy"
                  discription="Savor the ocean's bounty with our handpicked snails. Perfect for adventurous palates,"
                  weight="8kG (MOQ)"
                  category="Seafood"
                  className="customized_card"
                />
              </Col>
            ))}
          </Row>
        </div> */}
      </div>
      {/* footer */}
      <Footer />
    </div>
  );
};

export default ShoppingCart;
