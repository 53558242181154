import React, { useEffect, useState } from "react";
import { Button, message } from "antd";

import { FaCircleCheck } from "react-icons/fa6";
import { TextWithDivider } from "../../../components";
import {
  VerifyCodeModal,
  VerifyNumberAndEmailModal,
} from "../../../components/Modals";
import { useNavigate } from "react-router";
import route from "../../../config/route";
import {
  getActiveSessions,
  getUser,
  sendVerificationCode,
} from "../../../store/services/user";
import { useSelector } from "react-redux";
import { isUserEmailVerified, isUserPhoneVerified } from "../../../utils/index";

const AccountSecurity = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [active, setActive] = useState("");
  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const navigate = useNavigate();

  const [verifyModal, setVarifyModal] = useState(false);
  const [codeModal, setCodeModal] = useState(false);
  const [contactValue, setContactValue] = useState("");
  const { user } = useSelector((state) => state.user);

  const isEmail = active === "email";

  useEffect(() => {
    if (isUserEmailVerified(user) && isUserPhoneVerified(user)) {
      navigate(route.accountCreationSuccessful);
    } else {
      if (!isUserPhoneVerified(user)) {
        setActive("sms");
      } else if (!isUserEmailVerified(user)) {
        setActive("email");
      }
    }
  }, [user]);

  const cards = [
    {
      key: "sms",
      title: "SMS Authentication",
      description:
        "System will prompts a combination of password and 6 digit code using SMS.",
    },
    {
      key: "email",
      title: "Email Authentication",
      description:
        "System will prompts a combination of password and 6 digit code using email address.",
    },
  ];

  const handleModal = () => {
    if (active !== "") {
      setVarifyModal(true);
    } else {
      messageApi.open({
        type: "error",
        content: "Please choose account security type",
      });
    }
  };

  const handleSendCode = async ({ type, value }) => {
    try {
      setLoadingSendCode(true);
      const payload = {
        contact_method: type,
        contact_value: value,
      };
      const response = await sendVerificationCode(payload);
      if (response) {
        setContactValue(value);
        setVarifyModal(false);
        setCodeModal(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingSendCode(false);
  };

  const handleSkipVerification = async () => {
    navigate(route.accountCreationSuccessful);
  };

  return (
    <>
      {contextHolder}
      <div className="accountSecurityContainer">
        <h2 className={`authHeading ${active && "color-hdng"}`}>
          Account Security
        </h2>

        <div className="typeDivider">
          <TextWithDivider text="Choose Account Security Type" />
        </div>

        <div className="authentication">
          {cards.map((v) => {
            const isVerified =
              (v.key === "email" && isUserEmailVerified(user)) ||
              (v.key === "sms" && isUserPhoneVerified(user));
            return (
              <div
                key={v?.key}
                onClick={() => !isVerified && setActive(v?.key)}
                className={
                  isVerified ? "active" : active === v?.key ? "selected" : ""
                }
              >
                <span className="checkIcon">
                  <FaCircleCheck />
                </span>
                <h2>{v?.title}</h2>
                <p>{v?.description}</p>
              </div>
            );
          })}
        </div>

        <p className="activateText">
          {`To Activate ${
            isEmail ? "Email" : "SMS"
          } authentication, you must have a verified ${
            isEmail ? "Email address" : "phone number"
          }`}
        </p>

        <Button className="pi55" type="primary" onClick={handleModal}>
          {isEmail ? "Verify Email address" : "Verify Phone Number"}
        </Button>

        <p className="skipText" onClick={handleSkipVerification}>
          Skip for now
        </p>
      </div>

      <VerifyNumberAndEmailModal
        open={verifyModal}
        setOpen={setVarifyModal}
        type={active}
        handleSendCode={handleSendCode}
        loading={loadingSendCode}
      />
      <VerifyCodeModal
        open={codeModal}
        setOpen={setCodeModal}
        type={active}
        emailOrPhoneModalOpen={verifyModal}
        setEmailOrPhoneModalOpen={setVarifyModal}
        setType={setActive}
        contactValue={contactValue}
      />
    </>
  );
};

export default AccountSecurity;
