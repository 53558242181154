import { Breadcrumb, Button, Col, Row, Steps, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { LuMessagesSquare } from "react-icons/lu";
import { ReviewModal } from "../../../../components/Modals";
import Invoice from "../Invoice";
import html2pdf from "html2pdf.js";
import { HomeOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import { getSingleOrder } from "../../../../store/services/orders";
import { useSelector } from "react-redux";
import { Loader } from "../../../../components";
import route from "../../../../config/route";

function OrderDetails() {
  let { id } = useParams();
  const [currentStep, setCurrentStep] = useState(2);
  const { singleOrder, loading } = useSelector((state) => state.orders);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    getSingleOrder(id);
  }, []);

  const items = [
    {
      title: <h6 className="step-title">Receiving orders</h6>,
      description: <p className="step-discription">05:43 AM</p>,
    },
    {
      title: <h6 className="step-title">Order processing</h6>,
      description: <p className="step-discription">01:21 PM</p>,
    },
    {
      title: <h6 className="step-title">Being delivered</h6>,
      description: <p className="step-discription">Processing</p>,
    },
    {
      title: <h6 className="step-title">Delivered</h6>,
      description: <p className="step-discription">Pending</p>,
    },
  ];

  const steps = [
    {
      content: <StepContent currentStep={currentStep} />,
    },
    {
      content: <StepContent currentStep={currentStep} />,
    },
    {
      content: <StepContent currentStep={currentStep} />,
    },
    {
      content: <StepContent currentStep={currentStep} />,
    },
  ];

  return (
    <>
      <div className="ordersContainer">
        <div className="breadcrumbBox">
          <Breadcrumb
            separator={<RightOutlined className="breadcrumb-seperator" />}
            items={[
              {
                title: <HomeOutlined size={18} style={{ cursor: "pointer" }} />,
                onClick: () => navigate(route.exploreHome),
              },
              {
                title: <span>User Account</span>,
              },
              {
                title: <span style={{ cursor: "pointer" }} >Orders</span>,
                onClick: () => navigate(route.orders),
              },
              {
                title: "Details",
              },
            ]}
          />
        </div>
        <div className="orderDetailContainer">
          <div className="wrapper">
            <div className="header">
              <p className="headingText">Order Details</p>
              <div className="rating-support-btn-parent">
                <p className="supportText">
                  <LuMessagesSquare /> Message Support
                </p>
              </div>
            </div>
            <div className="content-main">
              <div className="order-info-card">
                <div className="order-info">
                  <h3>#{singleOrder?.id.slice(-8)}</h3>
                  <p>
                    {singleOrder?.items?.length} Products • Order Placed in 17
                    Jan, 2021 at 7:32 PM
                  </p>
                </div>
                <div className="order-price-parent">
                  {currentStep === 3 && (
                    <Button className="reOrderBtn">Re-order</Button>
                  )}
                  <p class="order-price">${singleOrder?.total_amount}</p>
                </div>
              </div>
              <div className="order-arrival">
                <p>
                  Order expected arrival <span>23 Jan, 2021</span>
                </p>
              </div>
              <div className="steps-and-text-parent">
                <p>
                  Your items is on the way. Tracking information will be
                  available within 24 hours.
                </p>
                <Steps
                  labelPlacement="vertical"
                  current={currentStep}
                  onChange={(v) => setCurrentStep(v)}
                  items={items}
                />
              </div>
              <div>{steps[currentStep].content}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const StepContent = ({ currentStep }) => {
  const { singleOrder, loading } = useSelector((state) => state.orders);
  const { user } = useSelector((state) => state.user);
  const [isReivewModalVisible, setIsReivewModalVisible] = useState(false);
  const [selectedProductForReview, setSelectedProductForReview] =
    useState(null);
  const downloadContentRef = useRef();
  const handleDownloadReceipt = () => {
    const element = downloadContentRef.current;
    const options = {
      margin: 0,
      filename: "receipt.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  const orderActivityColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
  ];
  const orderActivityData = [
    {
      key: "1",
      date: "20 Nov 2023",
      time: "2:30 PM",
      description: "The sender is preparing the goods",
      location: "2715 Ash Dr. San Jose, South Dakota 83475",
    },
    {
      key: "2",
      date: "20 Nov 2023",
      time: "01:00 PM",
      description: "The order has arrived at the post office",
      location: "3517 W. Gray St. Utica, Pennsylvania 57867",
    },
    {
      key: "3",
      date: "21 Nov 2023",
      time: "03:58 AM",
      description: "The carrier is picking up the goods",
      location: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    },
    {
      key: "4",
      date: "22 Nov 2023",
      time: "06:26 PM",
      description: "The order has been shipped",
      location: "4140 Parker Rd. Allentown, New Mexico 31134",
    },
    {
      key: "5",
      date: "22 Nov 2023",
      time: "03:45 PM",
      description: "Your order will be delivered to you in 30 minutes",
      location: "8502 Preston Rd. Inglewood, Maine 98380",
    },
    {
      key: "6",
      date: "23 Nov 2023",
      time: "12:21 AM",
      description: "The order has been delivered successfully",
      location: "3891 Ranchview Dr. Richardson, California 62639",
    },
  ];
  const columns = [
    {
      title: "Product",
      dataIndex: "image_url",
      key: "image_url",
      render: (url, product) => {
        console.log(product);
        return (
          <div className="product-imgNameDis-Parent">
            <div className="product-img-parent">
              <img src={url} />
            </div>
            <div className="product-name-dis-parent">
              <h6>{product?.name}</h6>
              <p>{product?.description}</p>
            </div>
          </div>
        );
      },
      width: "40%",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => <b>{price}</b>,
      width: "15%",
    },
    ...(currentStep === 3
      ? []
      : [
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (quantity) => <b>x{quantity}</b>,
            width: "10%",
          },
        ]),
    {
      title: "Sub-Total",
      dataIndex: "item_total",
      key: "item_total",
      render: (item_total) => <b>{item_total}</b>,
      width: "15%",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: "40%",
      render: (_, obj) => (
        <div className="actions-parent">
          {currentStep === 3 ? (
            <>
              <Button
                className="download-receipt-btn"
                onClick={handleDownloadReceipt}
              >
                Download Receipt
              </Button>
              <Button
                className="leaveRatingBtn"
                onClick={() => {
                  setSelectedProductForReview(obj?.product_id);
                  setIsReivewModalVisible(true);
                }}
              >
                Leave a rating <FaPlus />
              </Button>
            </>
          ) : (
            <p className="supportText">
              <LuMessagesSquare /> Message Support
            </p>
          )}
        </div>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      product: "",
      price: "$899",
      quantity: "1",
      subTotal: "$899",
    },
    {
      key: "2",
      product: "",
      price: "$899",
      quantity: "1",
      subTotal: "$899",
    },
  ];

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="order-activity-parent">
        <h6>Order Activity</h6>
        <div className="table-parent">
          <Table
            columns={orderActivityColumns}
            dataSource={orderActivityData}
            pagination={false}
            bordered={false}
          />
        </div>
      </div>
      <div className="order-product-parent">
        <h6 className="hdng">
          Product <span>({singleOrder?.items?.length})</span>
        </h6>
        <div className="table-parent">
          <Table
            columns={columns}
            dataSource={singleOrder?.items}
            pagination={false}
            bordered={false}
          />
        </div>
      </div>
      <div className="billingDetail-and-orderNotes-parent">
        <Row className="row">
          <Col span={12} className="col1">
            <div className="billingAddress-parent">
              <h6 className="hdng">Billing Address</h6>
              <div>
                <h6>{user?.contact_name}</h6>
                <p>{singleOrder?.shipping_address}</p>
                <p>
                  <span>Phone:</span> {user?.phone_number}
                </p>
                <p>
                  <span>Email:</span> {user?.email}
                </p>
              </div>
            </div>
          </Col>
          {singleOrder?.customer_notes && (
            <Col span={12} className="col2">
              <div className="order-notes-parent">
                <h6 className="hdng">Order Notes</h6>
                <p className="note">{singleOrder?.customer_notes}</p>
              </div>
            </Col>
          )}
        </Row>
      </div>
      {/* <div style={{ display: "none" }}>
        <Invoice downloadContentRef={downloadContentRef} />
      </div> */}
      <ReviewModal
        setIsReivewModalVisible={setIsReivewModalVisible}
        isReivewModalVisible={isReivewModalVisible}
        id={selectedProductForReview}
      />
    </>
  );
};

export default OrderDetails;
