import React, { useState, memo } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Input = (props) => {
  const {
    type = "text",
    label = "",
    name = "",
    required = false,
    className = "",
    value = "",
    onChange = () => {},
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleBlur = (e) => {
    validateInput(e.target.value);
  };
  const validateInput = (value) => {
    // Custom validation logic
    if (value.trim() === "") {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };
  
  return (
    <div className="input-container">
      <input
        {...rest}
        type={type === "password" && showPassword ? "text" : type}
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        onBlur={handleBlur} // Validate on blur
        className={isValid ? "valid" : "invalid"}
      />
      {type === "password" && (
        <span
          className="toggle-password"
          onClick={toggleShowPassword}
          style={{ cursor: "pointer" }}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </span>
      )}
      <label
        className={`${value && "filled"} ${isValid && "valid"} ${className ? className : ""}`}
        htmlFor={name}
      >
        {label} {required ? <span className="required">*</span> : ""}
      </label>
    </div>
  );
};

export default memo(Input);
