import React, { useEffect } from "react";
import Routers from "./Routes";
import { useSelector } from "react-redux";
import { darkTheme, lightTheme } from "./theme";
import { getActiveSessions, getUser } from "../store/services/user";
import { getToken } from "./constants";

const App = () => {
  const themeMode = useSelector((state) => state.theme.mode);
  const { user } = useSelector((state) => state?.user);
  const isSigninWithGoogle = localStorage.getItem("isSigninWithGoogle");

  useEffect(() => {
    const setTheme = themeMode === "lightTheme" ? lightTheme : darkTheme;
    Object.entries(setTheme).forEach(([key, value]) => {
      document.body.style.setProperty(`--${key}`, value);
    });
  }, [themeMode]);

  useEffect(() => {
    if (isSigninWithGoogle && user?.uid && !getToken()) {
      const res = getActiveSessions();
      res && localStorage.removeItem("isSigninWithGoogle");
    }
  }, [user]);

  useEffect(() => {
    getUser();
  }, []);

  return <Routers />;
};

export default App;
