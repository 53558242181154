import React, { useEffect, useState } from "react";
import {
  Banner,
  Footer,
  Header,
  Loader,
  ProductCard,
  ProductsFilterBy,
} from "../../../components/index";
import { useParams } from "react-router";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { store } from "../../../store";
import { setAppliedFilters } from "../../../store/slices/filterSlice";
import { getProducts } from "../../../store/services/product";
import { IoCloseSharp } from "react-icons/io5";

function Category() {
  let { id } = useParams();
  const { products, loading } = useSelector((state) => state.product);
  const [productsCount, setProductsCount] = useState(0);
  const [category, setCategory] = useState(null);
  const { categories } = useSelector((state) => state.category);
  const { appliedFilters } = useSelector((state) => state.filters);
  const [currentPage, setCurrentPage] = useState(1);
  const { subCategories } = useSelector((state) => state.category);

  useEffect(() => {
    if (categories && categories?.length > 0) {
      const findCategory = categories?.find(
        ({ category_id }) => category_id === id
      );
      setCategory(findCategory);
    }
  }, [categories, id]);

  useEffect(() => {
    store.dispatch(
      setAppliedFilters({
        category_id: id,
      })
    );
  }, [id]);

  useEffect(() => {
    const validFilters = Object.fromEntries(
      Object.entries(appliedFilters).filter(([_, value]) => value)
    );
    const response = getProducts({
      page: currentPage,
      page_size: 10,
      ...validFilters,
    });
    if (response) {
      setProductsCount(response?.total);
    }
  }, [appliedFilters, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const reserFilters = () => {
    store.dispatch(setAppliedFilters({}));
  };

  const removeFilter = (filterName) => {
    const updatedFilters = { ...appliedFilters };
    delete updatedFilters[filterName];
    store.dispatch(setAppliedFilters(updatedFilters));
  };

  const filtersApplied = Object.entries(appliedFilters)?.filter(
    ([filterName, v]) =>
      filterName !== "search" && filterName !== "category_id" && v
  );

  return (
    <div className="category-products-main-container">
      <Header />
      <Banner
        title={category?.name}
        discription={category?.description}
        showGreetingText={false}
      />
      {filtersApplied?.length > 0 && (
        <div className="filters-tag-parent-div">
          <Button onClick={reserFilters} className="reset-button">
            Reset Filters
          </Button>

          {filtersApplied.map(([filterName, value], index) =>
            filterName === "sub_categories" ? (
              <p key={index} className="filter-tag">
                {subCategories.find(
                  ({ category_id }) => category_id === value[0]
                )?.name || ""}
                <IoCloseSharp
                  size={24}
                  style={{ marginLeft: "10px" }}
                  onClick={() => removeFilter(filterName)}
                />
              </p>
            ) : (
              <p key={index} className="filter-tag">
                {value}
                <IoCloseSharp
                  size={24}
                  style={{ marginLeft: "10px" }}
                  onClick={() => removeFilter(filterName)}
                />
              </p>
            )
          )}
        </div>
      )}
      <div
        className="filter-and-products-main-row category-filter-products-parent"
        style={{
          paddingBottom: products?.length < productsCount ? "0px" : "100px",
        }}
      >
        <ProductsFilterBy />
        <div className="products-parent">
          {loading ? (
            <Loader />
          ) : products?.length > 0 ? (
            products?.map((product) => (
              <ProductCard
                title={product?.name}
                discription={product?.description}
                weight={product?.pricing[0]?.weight}
                category="Seafood"
                stock={product?.pricing[0]?.stock_quantity}
                price={product?.pricing[0]?.price}
                productData={product}
              />
            ))
          ) : (
            <p style={{ textAlign: "center", flex: "1" }}>Coming Soon!</p>
          )}
        </div>
      </div>
      <div className="dflex-center">
        {products?.length < productsCount && (
          <Button
            className="outlined-btn load-more-btn"
            onClick={handleLoadMore}
          >
            Load More
          </Button>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Category;
