import React from 'react'
import { SimpleHeader } from '../../../../../components'
import ResetPasswordOption from '../../../../Auth/ResetPasswordOption'

const PaymentSecurity = () => {
  return (
    <div>
        <SimpleHeader />

        <div className='recurringOrderOption'>
            <ResetPasswordOption title={'Payment Security'} />
        </div>
      
    </div>
  )
}

export default PaymentSecurity
