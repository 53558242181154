import React from "react";
import { Col, Row, Table, Typography } from "antd";
import AppLogo from "../../../../components/AppLogo";

function Invoice({downloadContentRef}) {
  const columns = [
    {
      title: <p className="table-hdng">Products</p>,
      dataIndex: "product",
      key: "product",
      render: (text) => <p className="text-black">{text}</p>,
    },
    {
      title: "Qty.",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (text) => <p className="text-black">{text}</p>,
    },
  ];

  const data = [
    {
      key: "1",
      product: "Product Name",
      quantity: 1,
      price: "200.00",
      total: "200.00",
    },
    {
      key: "2",
      product: "Service Fee",
      quantity: 1,
      price: "20.00",
      total: "20.00",
    },
  ];
  return (
    <div className="invoice-main-container" ref={downloadContentRef}>
      <div className="info-section">
        <div className="right-content">
          <h1 className="heading">Invoice</h1>
          <div className="billedTo-info">
            <h6>Billed To:</h6>
            <h5>Client Name</h5>
            <p>Address / Contact Info</p>
          </div>
        </div>
        <div className="left-content">
          <div>
            <h6>Invoice No</h6>
            <h5>#000123</h5>
          </div>
          <div>
            <h6>Issued On</h6>
            <p>December 7, 2022</p>
            <h6>Supply On</h6>
            <p>December 22, 2022</p>
          </div>
        </div>
      </div>
      <div className="table-section-wrapper">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered={false}
          rowHoverable={false}
          summary={() => (
            <>
              <tr>
                <td colSpan={3} style={{ textAlign: "right" }}>
                  <p className="semi-hdng">Subtotal</p>
                </td>
                <td>
                  <p>220.00</p>
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ textAlign: "right" }}>
                  <p className="semi-hdng">VAT (7.5%)</p>
                </td>
                <td>
                  <p>17.00</p>
                </td>
              </tr>
            </>
          )}
        />
        <div className="total-price-parent">
          <h6>
            Total (<span>USD</span>)
          </h6>
          <h5>237.00</h5>
        </div>
      </div>
      <div className="footer-section">
        <Row className="row">
          <Col span={10} className="column1">
            <img
              className="logo"
              src={require("../../../../assets/images/logo.png")}
              alt={"Logo"}
            />
            <h5 className="name">Frostflow LTD</h5>
            <p className="address">
              88 Riverside Drive, Mitcham, London CR4 4BW
            </p>
            <p className="mail">support@frostflow.co.uk</p>
            <span>
              <p>Inspirited VAT</p>
              <p>447834658</p>
            </span>
          </Col>
          <Col span={14} className="column2">
            <h6>Additional Notes</h6>
            <p>Have a great day</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Invoice;
