import React, { useState } from "react";
import {
  // Button,
  Input,
  SelectInput,
  SimpleHeader,
} from "../../../../components";
import { Checkbox, Divider, Dropdown, Form, Radio, Select ,Button} from "antd";
import { GrDeliver } from "react-icons/gr";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { FaFileInvoiceDollar } from "react-icons/fa";
import creditCard from "../../../../assets/images/creditCard.png";
import paypal from "../../../../assets/images/paypal.png";
import snail from "../../../../assets/images/tempProduct01.png";
import tt from "../../../../assets/images/tt.PNG";
import { useNavigate } from "react-router";
import route from '../../../../config/route'

const TradeAssuranceOrder = () => {
  const [form] = Form.useForm();
  const [activeSection, setActiveSection] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const navigate = useNavigate();

  console.log(">>>>>",activeSection)

  const handleButtonClick = async () => {
    try {
        const formData = await form.validateFields();
        console.log("Form Data:", formData);
      navigate(route.paymentSecurity);
    } catch (error) {
      console.error("Validation Failed:", error);
    }
  };
  const handleButtonPyapal = () => {
    navigate(route.paymentSecurity);
  };
  const handleWireTransfer = () => {
    navigate(route.wireTransferInformation);
  };

  const handleSectionToggle = (section) => {
    setActiveSection((prev) => (prev === section ? null : section)); // Toggle section
  };

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const generateUpcomingYears = (numberOfYears = 10) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: numberOfYears }, (_, i) => ({
      label: currentYear + i,
      value: currentYear + i,
    }));
  };
  const { Option } = Select;

  const currencies = ["USD", "EUR", "GBP", "INR"];
  const defaultCurrency = "USD";

  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);

  const handleChange = (value) => {
    setSelectedCurrency(value);
    // Additional logic can be added here, e.g., API calls or price updates
  };
  return (
    <div>
      <SimpleHeader />
      <div className="trade_assurance_main">
        <div className="information_body">
          <h3>Trade Assurance order </h3>
          <div className="information_container">
            {/* left side container */}
            <div className="left-side left-tread-body">
              <div
                className={`payment-container ${
                  activeSection === "creditCard" ? "show" : ""
                }`}
              >
                <div className="payment-method">
                  <div className="radio">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="creditCard"
                      id="creditCard"
                      onChange={() => handleSectionToggle("creditCard")}
                      checked={activeSection === "creditCard"}
                    />
                    <label htmlFor="creditCard"> Credit / debit card </label>
                  </div>
                  <img src={creditCard} width={50} alt="" />
                </div>

                <div
                  className={`payment-body ${
                    activeSection === "creditCard" ? "show" : "hide"
                  }`}
                >
                  <p>payment should be recieved in 1-2 hours</p>
                  <h4>Card Information</h4>
                  <Form
                    form={form}
                    style={{
                      width: "100%",
                    }}
                    // initialValues={{}}
                    // onFinish={onFinish}
                    autoComplete="off"
                    className="signinForm"
                  >
                    <Form.Item
                      label={null}
                      name="cardNumber"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Input label="Card Number" required />
                    </Form.Item>

                    <div className="d-flex">
                      <Form.Item
                        label={null}
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                      >
                        <Input label="First Name" required />
                      </Form.Item>
                      <Form.Item
                        label={null}
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                      >
                        <Input label="Last Name" required />
                      </Form.Item>
                    </div>

                    <div className="d-flex">
                      <Form.Item
                        label={null}
                        name="month"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                      >
                        <SelectInput
                          label="Month"
                          name="month"
                          value={selectedCountry}
                          onChange={(value) => setSelectedCountry(value)}
                          options={months}
                          required
                        />
                      </Form.Item>

                      <Form.Item
                        label={null}
                        name="year"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                      >
                        <SelectInput
                          label="Year"
                          name="year"
                          value={selectedYear}
                          onChange={(value) => setSelectedYear(value)}
                          options={generateUpcomingYears(10)}
                          required
                        />
                      </Form.Item>
                      <Form.Item
                        label={null}
                        name="cvv/cvc"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                      >
                        <Input label="CVV / CVC" required />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      label={null}
                    >
                      <Checkbox className="remember_title">
                        Remember this card
                      </Checkbox>
                    </Form.Item>
                  </Form>
                </div>
              </div>

              <div
                className={`payment-container ${
                  activeSection === "paypal" ? "show" : ""
                }`}
              >
                <div className="payment-method">
                  <div className="radio">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="paypal"
                      id="paypal"
                      onChange={() => handleSectionToggle("paypal")}
                      checked={activeSection === "paypal"}
                    />
                    <label htmlFor="paypal"> Paypal</label>
                  </div>
                  <img src={paypal} width={90} alt="" />
                </div>

                <div
                  className={`payment-body ${
                    activeSection === "paypal" ? "show" : "hide"
                  }`}
                >
                  <p className="paypal-pay">
                    Payment should be recieved in 1-2 hours
                  </p>
                </div>
              </div>

              <div
                className={`payment-container ${
                  activeSection === "wireTransfer" ? "show" : ""
                }`}
              >
                <div className="payment-method">
                  <div className="radio">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="wireTransfer"
                      id="wireTransfer"
                      onChange={() => handleSectionToggle("wireTransfer")}
                      checked={activeSection === "wireTransfer"}
                    />
                    <label htmlFor="wireTransfer"> Wire transfer</label>
                  </div>
                  <img src={tt} width={50} alt="" />
                </div>

                <div
                  className={`payment-body ${
                    activeSection === "wireTransfer" ? "show" : "hide"
                  }`}
                >
                  <p className="wire-transfer">
                    Payment should be recieved in 3-7 business days
                  </p>
                  <div className="wire-transfer-body">
                    <div className="wire-transfer-body-left">
                      <p>Beneficiary country/region</p>
                      <h2>Singapore</h2>
                    </div>
                    <div className="wire-transfer-body-right">
                      <p>
                        Payment processing fee charged by remitting bank:
                        Depends on your bank
                      </p>
                      <p>
                        Beneficiary Bank : <span>J.P MORGAN</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right SIde Container */}
            <div className="right-side">
              <div className="right-side-container">
                {activeSection ? (
                  <div className="product-img-title">
                    <img src={snail} alt=" " />
                    <div className="product-description">
                      <p>4 item in total</p>
                      <p>Snail : lorem ipsum dolor amet </p>
                      <p>Order No : 091231231239123343</p>
                    </div>
                  </div>
                ) : (
                  <p className="summary">Order Summary (18 Items)</p>
                )}
                {activeSection ? (
                  <>
                    <div className="total-pay">
                      <p>Subtotal</p>
                      <p>USD 23.33</p>
                    </div>
                    <Divider style={{ margin: "12px 0px" }} />
                    <div className="total-pay">
                      <p>Order amount</p>
                      <p>USD 23.33</p>
                    </div>
                    <div className="total-pay" style={{ paddingTop: "25px" }}>
                      <p>Payment processing fee</p>
                      <p>USD 23.33</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="total-pay">
                      <p>Item subtotal</p>
                      <p>USD 23.33</p>
                    </div>
                    <div className="total-pay" style={{ padding: "10px 0px" }}>
                      <p>Shipping fee</p>
                      <p>USD 23.33</p>
                    </div>
                    <div className="total-pay">
                      <p>Shipping discount</p>
                      <p className="discount">-USD 23.33</p>
                    </div>
                    <Divider style={{ margin: "12px 0px" }} />
                    <div className="total-pay">
                      <p>Subtotal</p>
                      <p>USD 23.33</p>
                    </div>
                  </>
                )}
                {activeSection && (
                  <div className="total-pay pay-select">
                    <p>
                      Pay in
                      <Select
                        value={selectedCurrency}
                        onChange={handleChange}
                        popupClassName="common-dropdown"
                        bordered={false}
                        style={{
                          fontWeight: "300",
                          textDecoration: "underline",
                        }}
                        dropdownStyle={{ fontSize: "14px" }}
                      >
                        {currencies.map((currency) => (
                          <Option key={currency} value={currency}>
                            {currency}
                          </Option>
                        ))}
                      </Select>
                    </p>
                    <h4>USD 23.33</h4>
                  </div>
                )}

                <Button
                  className={
                    activeSection === "paypal"
                      ? "pi55 payment_btn paypal_btn"
                      : "pi55 payment_btn pay_btn"
                  }
                  type="primary"
                  htmlType="submit"
                  disabled={!activeSection}
                  onClick={
                    activeSection == "wireTransfer"
                      ? handleWireTransfer
                      : activeSection == "paypal"
                      ? handleButtonPyapal
                      : handleButtonClick
                  }
                  
                >
                  {activeSection === "creditCard" ? (
                    "Pay Now"
                  ) : activeSection === "paypal" ? (
                    <img src={paypal} alt="" width={80} />
                  ) : activeSection === "wireTransfer" ? (
                    "Continue"
                  ) : (
                    "Proceed to pay"
                  )}
                </Button>

                {activeSection ? (
                  <>
                    <p className="frostflow-doc">
                      By click [Pay now] you confirm the payment is for business
                      purpos only and the agree to be bound by the details of
                      this transaction and terms condition of frostflow.com
                    </p>
                  </>
                ) : (
                  <>
                    <div className="protection-order">
                      <h4>Protection for this order</h4>
                      <div className="order-cont">
                        <div className="order-cont-heading">
                          <GrDeliver />
                          <p>on-time Dispatch Gurantee</p>
                        </div>
                        <p>Dispatched within 7 days of payment</p>
                      </div>
                      <div className="order-cont">
                        <div className="order-cont-heading">
                          <IoShieldCheckmarkOutline />
                          <p>Secure Payment</p>
                        </div>
                        <p>
                          Every payment you make on Alibaba.com is secured with
                          strict SSL encryption and PCI DSS data protection
                          protocols <span>View Details</span>
                        </p>
                      </div>
                      <div className="order-cont">
                        <div className="order-cont-heading">
                          <GrDeliver />
                          <p>Standard Refund Policy</p>
                        </div>
                        <p>
                          Claim a refund if you orders dosen't ship, is missing,
                          or arrives with product issues
                        </p>
                      </div>
                    </div>

                    <div className="order-trade">
                      <p>
                        frostflow protects all your order place and paid on the
                        plateform with{" "}
                        <span>
                          {" "}
                          <FaFileInvoiceDollar
                            style={{ color: "yellow" }}
                          />{" "}
                          Trade Assurance
                        </span>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeAssuranceOrder;
