import React from "react";

const ProductKeyAttributes = ({ title, attributes }) => {
  return (
    <div className="attributes_data">
      <h3>{title}</h3>
      <table style={{width: '50%'}}>
        <tbody>
          {attributes.map((item, index) => (
            <tr
              key={index}
              style={{
                borderBottom: "1px solid #ddd",
              }}
            >
              {/* Key Column */}
              <td
                style={{
                  padding: "10px",
                  backgroundColor: "transparent",
                  fontWeight: "normal",
                  width: "40%",
                borderRight: "1px solid #ddd",

                }}
              >
                {item.key}
              </td>

              {/* Value Column */}
              <td
                style={{
                  padding: "10px",
                  backgroundColor: "transparent",
                  fontWeight: "semi-bold",
                }}
              >
                {item.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductKeyAttributes;
