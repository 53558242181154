import { Col, Row } from "antd";
import React from "react";
import { Button } from "../../index";

function ContactSupport() {
  return (
    <div className="contactSupport-main-container">
      <div>
        <Row gutter={[20, 20]}>
          <Col span={24} xl={12}>
            <img
              src={require("../../../assets/images/contactSupport-section-img.png")}
              className="img"
            />
          </Col>
          <Col span={24} xl={12}>
            <div className="right-content-parent">
              <h2 className="hdng">Exceptional Support Always</h2>
              <p className="discription">
                At FrostFlow, we believe in exceptional support. Our friendly
                helpdesk team is here to assist you every step of the way,
                ensuring a smooth and satisfying experience on our platform.
                Your satisfaction is paramount to us. Your triumphs are the
                heart and soul of FrostFlow.
              </p>
              <Button className="btn">Contact Support</Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ContactSupport;
