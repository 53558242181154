import { notification } from "antd";
import { privateAPI } from "../../config/constants";
import { setCount, setLoading, setReviews } from "../slices/reviewSlice";
import { store } from "..";

export const getProductReviews = async (payload) => {
  try {
    store.dispatch(setLoading(true));
    const { product_id, ...queryParams } = payload;
    const queryString = new URLSearchParams(queryParams).toString();
    const response = await privateAPI.get(
      `/review/get-reviews/${product_id}${queryString ? `?${queryString}` : ""}`
    );
    if (response) {
      store.dispatch(setReviews(response?.data?.reviews));
      store.dispatch(setCount(response?.data?.total))
    }
    return response.data;
  } catch (error) {
    notification.error({
      message:
        typeof error?.response?.data?.detail === "string"
          ? error?.response?.data?.detail
          : "Server Error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const uploadReviewImage = async (formData) => {
  try {
    const response = await privateAPI.post(
      "/review/upload-review-image",
      formData
    );
    return response?.data;
  } catch (error) {
    notification.error({
      message:
        typeof error?.response?.data?.detail === "string"
          ? error?.response?.data?.detail
          : "Server Error",
    });
  }
};

export const addReview = async (payload) => {
  try {
    const response = await privateAPI.post("/review/add-review", payload);
    if (response) {
      getProductReviews({
        product_id: payload?.product_id,
        page: 1,
        page_size: 10,
      });
    }
    return response.data;
  } catch (error) {
    notification.error({
      message:
        typeof error?.response?.data?.detail === "string"
          ? error?.response?.data?.detail
          : "Server Error",
    });
  }
};
