import React, { useEffect } from "react";

import { Button } from "antd";
import { useNavigate } from "react-router";
import route from "../../../config/route";
import { useSelector } from "react-redux";

const WelcomeBack = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleProceed = () => {
    navigate(route.exploreHome);
  };

  return (
    <div className="welcomeBackContainer">
      <h2 className="authHeading">Welcome Back</h2>
      <p className="welcomeText">
      We're thrilled to have you here. Explore our latest products and enjoy seamless ordering!
      </p>
      <Button className="pi55" type="primary" onClick={handleProceed}>
        Proceed to Dashboard
      </Button>
    </div>
  );
};

export default WelcomeBack;
