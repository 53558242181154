import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form } from "antd";
import { useNavigate } from "react-router";

import route from "../../../config/route";
import {
  AuthLayout,
  SigninWith,
  TextWithDivider,
  Input,
} from "../../../components";
import {
  getActiveSessions,
  getUser,
  googleLogin,
  login,
  sendVerificationCode,
} from "../../../store/services/user";
import { isUserEmailVerified, isUserPhoneVerified } from "../../../utils/index";

const SignIn = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const payload = {
        email: values?.email,
        password: values?.password,
      };
      const response = await login(payload);
      if (response) {
        const user = await getUser();
        if (user?.uid) {
          if (isUserEmailVerified(user) && isUserPhoneVerified(user)) {
            const resActiveSession = await getActiveSessions();
            resActiveSession && navigate(route.exploreHome);
          } else if (isUserEmailVerified(user) && !isUserPhoneVerified(user)) {
            const payload = {
              contact_method: "sms",
              contact_value: user?.phone_number,
            };
            const responseSendCode = await sendVerificationCode(payload);
            if (responseSendCode) {
              navigate(route.verification);
            }
          } else {
            const payload = {
              contact_method: "email",
              contact_value: user?.email,
            };
            const responseSendCode = await sendVerificationCode(payload);
            if (responseSendCode) {
              navigate(route.verification);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => navigate(route.resetPasswordOption);
  const handleGoogleSignin = () => {
    googleLogin();
  };

  return (
    <div className="signinContainer">
      <section className="section1">
        <h2 className="authHeading">Sign in to your account</h2>
        <p className="authDescription">
          Don't have an account?{" "}
          <span onClick={() => navigate(route.createAccount)}>Sign up</span>
        </p>

        <Form
          form={form}
          name="Signin"
          initialValues={{
            email: "dicake2383@owlny.com",
            password: "Admin@123",
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          className="signinForm"
        >
          <Form.Item
            label={null}
            name="email"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Input label="Email" type="email" required />
          </Form.Item>

          <Form.Item
            label={null}
            name="password"
            validateFirst
            rules={[
              {
                required: true,
                message: "Required!",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long!",
              },
              {
                pattern: /(?=.*[A-Z])/,
                message: "Password must contain at least 1 uppercase letter!",
              },
              {
                pattern: /(?=.*\d)/,
                message: "Password must contain at least 1 number!",
              },
            ]}
          >
            <Input label="Password" type="password" />
          </Form.Item>

          <div className="rememberAndForgot">
            <Form.Item name="remember" valuePropName="checked" label={null}>
              <Checkbox>Keep me logged in</Checkbox>
            </Form.Item>

            <Button type="text" onClick={handleForgotPassword}>
              Forgot password?
            </Button>
          </div>

          <Form.Item label={null}>
            <Button
              className="pi55"
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </section>

      <TextWithDivider text="Or Sign in with" />

      <section className="section2">
        <SigninWith signinWith="Google" onClick={handleGoogleSignin} />
      </section>
    </div>
  );
};

export default SignIn;
