import React, { useState } from "react";
import { Modal } from "antd";
import VerifyCode from "../../VerifyCode";
import route from "../../../config/route";
import { useNavigate } from "react-router";
import {
  getActiveSessions,
  getUser,
  sendVerificationCode,
  verifyCode,
} from "../../../store/services/user";

const VerifyCodeModal = (props) => {
  const [value, setValue] = useState(undefined);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    open,
    setOpen,
    type,
    setType,
    setEmailOrPhoneModalOpen,
    contactValue,
  } = props;

  const isEmail = type === "email";

  const handleCancel = () => {
    setOpen(false);
    setValue("");
  };

  const handleChangeNumberOrEmail = () => {
    setType(type);
    setEmailOrPhoneModalOpen(true);
    handleCancel();
  };

  const handleResendCode = async () => {
    try {
      const payload = {
        contact_method: type,
        contact_value: contactValue,
      };
      await sendVerificationCode(payload);
    } catch (error) {}
  };

  const heading = isEmail ? "Email Address" : "Phone Number";
  const description = isEmail ? "email" : "number";

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        code: value,
        contact_method: isEmail ? "email" : "sms",
        contact_value: contactValue,
      };
      const response = await verifyCode(payload);
      if (response) {
        handleCancel();
        navigate(route.accountCreationSuccessful);
      }
      getUser();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    if (!isEmail) {
      handleCancel();
    }
  };

  return (
    <Modal
      centered
      title={false}
      footer={false}
      open={open}
      onCancel={handleCancel}
      className="verifyCodeModal"
    >
      <VerifyCode
        heading={heading}
        description={description}
        value={value}
        type={type}
        handleChangeNumberOrEmail={handleChangeNumberOrEmail}
        resendCode={handleResendCode}
        setValue={setValue}
        handleSubmit={handleSubmit}
        contactValue={contactValue}
        loading={loading}
      />
    </Modal>
  );
};

export default VerifyCodeModal;
