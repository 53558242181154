import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  cards: [],
  checkoutItems: []
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setCheckoutItems: (state, action) => {
      state.checkoutItems = action.payload;
    },
  },
});

export const { setLoading, setCards, setCheckoutItems } = paymentSlice.actions;

export default paymentSlice.reducer;
