import React, { useEffect, useState } from "react";
import { Button, Radio } from "antd";
import { Input } from "../../../components";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router";
import route from "../../../config/route";
import { forgotPassword } from "../../../store/services/user";

const ResetPasswordOption = ({ title }) => {
  const [active, setActive] = useState("email");
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const isEmailActive = active === "email";
  const getVerificationInfo = localStorage.getItem("verificationInfo");
  const verificationInfo =
    getVerificationInfo && JSON.parse(getVerificationInfo);
  const phoneNumber =
    !isEmailActive && value?.charAt(0) === "+" ? `${value}` : `+${value}`;
  const navigate = useNavigate();

  const handleSend = async () => {
    try {
      setLoading(true);
      const payload = {
        contact_method: active,
        contact_value: isEmailActive ? value : phoneNumber,
      };
      const response = await forgotPassword(payload);
      if (response) {
        localStorage.setItem(
          "verificationInfo",
          JSON.stringify({
            contactType: active,
            contactValue: isEmailActive ? value : phoneNumber,
          })
        );
        if (isEmailActive) {
          navigate(route.checkEmail);
        } else {
          navigate(route.verifyCode);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      verificationInfo &&
      verificationInfo?.contactType &&
      verificationInfo?.contactValue
    ) {
      setActive(verificationInfo?.contactType);
      setValue(verificationInfo?.contactValue);
    }
  }, []);

  return (
    <div className="resetPassOptContainer">
      <h2 className="authHeading">{title ? title : "Reset your password"}</h2>
      <p className="authSubText">Would you like us to send you ...</p>

      <div className="radioGroup">
        <Radio.Group
          onChange={(e) => {
            setValue("");
            setActive(e.target.value);
          }}
          value={active}
        >
          <Radio value={"email"}>an email</Radio>
          <Radio value={"sms"}>a text message</Radio>
        </Radio.Group>
      </div>

      {isEmailActive ? (
        <Input
          type="email"
          label="Email"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
        />
      ) : (
        <PhoneInput
          country={"us"}
          value={value}
          onChange={(phone) => setValue(phone)}
          className={value ? "phoneInputValid" : ""}
        />
      )}

      <Button
        className="pi55"
        type="primary"
        loading={loading}
        disabled={loading}
        onClick={handleSend}
      >
        Send
      </Button>
    </div>
  );
};

export default ResetPasswordOption;
