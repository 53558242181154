import React, { useEffect } from "react";
import {
  Button,
  Categories,
  ClaimCatch,
  ContactSupport,
  Footer,
  Header,
  HeroSection,
  Testimonials,
  WhyFrostFlow,
} from "../../../components";
import { getCategories } from "../../../store/services/category";
import { getProducts } from "../../../store/services/product";

const Home = () => {
  useEffect(() => {
    getCategories();
    getProducts();
  }, []);
  return (
    <div>
      <Header />
      <HeroSection>
        <h1 className="hdng">Frostflow: Where Flavor Meets Freshness</h1>
        <h5 className="sub-hdng">
          Sustainably sourced, expertly frozen, and ready for your kitchen
        </h5>
        <Button className={"carousel-btn"}>Shop Now</Button>
      </HeroSection>
      <Categories />
      <WhyFrostFlow />
      <Testimonials />
      <ContactSupport />
      <ClaimCatch />
      <Footer />
    </div>
  );
};

export default Home;
