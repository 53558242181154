import React, { useState } from "react";
import SimpleHeader from "../../../../components/SimpleHeader";
import { SlLocationPin } from "react-icons/sl";
import { CiLock, CiDollar } from "react-icons/ci";
import { BsBoxSeam } from "react-icons/bs";
import { GrDeliver } from "react-icons/gr";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { Checkbox, Divider, Form } from "antd";
import Input from "../../../../components/Input";
import { Button, SelectInput } from "../../../../components";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router";
import route from "../../../../config/route";

const PersonalInformation = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  // const { Option } = Select;
  const onFinish = (values) => {
    console.log("Success:", values);
    navigate(route.paymentMethod);
  };

  const [selectedCountry, setSelectedCountry] = useState("");

  const countries = [
    { value: "us", label: "United States" },
    { value: "uk", label: "United Kingdom" },
    { value: "in", label: "India" },
    { value: "ca", label: "Canada" },
    { value: "au", label: "Australia" },
  ];

  return (
    <div className="checkout_info_main">
      <SimpleHeader />
      <div className="information_body">
        <h3>Checkout (18 items) </h3>
        <div className="information_container">
          {/* left side container */}
          <div className="left-side">
            <Divider className="divider" />
            <p className="title">
              {" "}
              <SlLocationPin size={20} /> Shipping address
            </p>
            <div className="left-side-container">
              <p className="personal-info">
                {" "}
                <CiLock /> your personal information is encrypted and will only
                use for delivery purposes.
              </p>
              <Form
                form={form}
                name="information"
                style={{
                  width: "100%",
                }}
                // initialValues={{}}
                onFinish={onFinish}
                autoComplete="off"
                className="signinForm"
              >
                <Form.Item
                  label={null}
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <SelectInput
                    label="Country"
                    name="country"
                    value={selectedCountry}
                    onChange={(value) => setSelectedCountry(value)}
                    options={countries}
                    required
                  />
                </Form.Item>
                <div className="d-flex">
                  <Form.Item
                    label={null}
                    name="fullname"
                    rules={[
                      {
                        required: false,
                        message: "Required!",
                      },
                    ]}
                  >
                    <Input label="Full Name" required />
                  </Form.Item>
                  <Form.Item
                    label={null}
                    name="phoneNumber"
                    rules={[
                      {
                        required: false,
                        message: "Required!",
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={(phone) => setPhone({ phone })}
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  label={null}
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input label="Street Address or P.O box " required />
                </Form.Item>

                <Form.Item
                  label={null}
                  name="building"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input
                    label="Apartment, suite,vunit,vbuilding, floor (optional)"
                    required
                  />
                </Form.Item>

                <div className="d-flex">
                  <Form.Item
                    label={null}
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    <SelectInput
                      label="Select / Provance"
                      name="select provance"
                      value={selectedCountry}
                      onChange={(value) => setSelectedCountry(value)}
                      options={countries}
                      required
                    />
                  </Form.Item>
                  <Form.Item
                    label={null}
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    <SelectInput
                      label="City"
                      name="City"
                      value={selectedCountry}
                      onChange={(value) => setSelectedCountry(value)}
                      options={countries}
                      required
                    />
                  </Form.Item>
                  <Form.Item
                    label={null}
                    name="postalcode"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  >
                    <Input label="Postal Code" required />
                  </Form.Item>
                </div>

                <Form.Item name="remember" valuePropName="checked" label={null}>
                  <Checkbox className="remember_title">
                    Set a default shipping address
                  </Checkbox>
                </Form.Item>

                <Form.Item label={null}>
                  <Button
                    className="pi55 payment_btn"
                    type="primary"
                    htmlType="submit"
                  >
                    Continue to payment
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Divider />
            <div className="payment_method">
              <CiDollar size={20} className="icons" />
              <p>Payment method</p>
            </div>
            <Divider />
            <div className="payment_method">
              <BsBoxSeam className="icons" />
              <p>Items and delivery options</p>
            </div>
          </div>

          {/* Right SIde Container */}
          <div className="right-side">
            <div className="right-side-container">
              <p className="summary">Order summary (18 Items)</p>
              <div className="total-pay">
                <p>Item subtotal</p>
                <p>USD 23.33</p>
              </div>
              <Divider />
              <div className="total-pay">
                <p>Subtotal</p>
                <p>USD 23.33</p>
              </div>

              <div className="protection-order">
                <h4>Protections for this order</h4>
                <div className="order-cont">
                  <div className="order-cont-heading">
                    <GrDeliver />
                    <p>On-time Dispatch Gurantee</p>
                  </div>
                  <p>Dispatched within 7 days of payment</p>
                </div>
                <div className="order-cont">
                  <div className="order-cont-heading">
                    <IoShieldCheckmarkOutline />
                    <p>Secure payments</p>
                  </div>
                  <p>
                   Every payment you make on Alibaba.com is secured with strict SSL encryption and PCI DSS data protection protocols <span>View Details</span>
                  </p>
                </div>
                <div className="order-cont">
                  <div className="order-cont-heading">
                    <GrDeliver />
                    <p>Standard refund policy</p>
                  </div>
                  <p>Claim a refund if you orders dosen't ship, is missing, or arrives with product issues</p>
                </div>
              </div>

              <div className="order-trade">
                <p>frostflow protects all your order place and paid on the plateform with <span> <FaFileInvoiceDollar  style={{color:'yellow'}}/> Trade Assurance</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
