import { store } from "..";
import { privateAPI } from "../../config/constants";
import { setFilters, setLoading } from "../slices/filterSlice";

export const getFilters = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/products/filters");
    if (response) {
      store.dispatch(setFilters(response?.data));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};
