import { store } from "..";
import { privateAPI } from "../../config/constants";
import { setCities, setCountries, setLoading, setStates } from "../slices/locationSlice";

export const getCountries = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/locations/countries");
    if (response) {
      store.dispatch(setCountries(response?.data));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getStates = async (Payload) => {
  try {
    store.dispatch(setLoading(true));
    const { country } = Payload;
    const response = await privateAPI.get(`/locations/states/${country}`);
    if (response) {
      store.dispatch(setStates(response?.data));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getCities = async (Payload) => {
  try {
    store.dispatch(setLoading(true));
    const { country, state } = Payload;
    const response = await privateAPI.get(
      `/locations/cities/${country}/${state}`
    );
    if (response) {
      store.dispatch(setCities(response?.data));
    }
    return response?.data;
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};
